import React, { useState } from "react";
import { Link } from "react-router-dom";
import dateFormat from "dateformat";
import baseIcon from "../images/base.png";
import bnbIcon from "../images/bnb.png";
import lineaIcon from "../images/linea.png";
import templateImg from "../images/template.jpg";
import bannerTemplate from "../images/template_banner.png";
import discordIcon from "../images/discord.svg";
import telegramIcon from "../images/telegram.svg";
import websiteIcon from "../images/website.svg";
import twitterIcon from "../images/x.svg";
import defaultIcon from "../images/DiamondDefaultIcon.svg";
import selectedIcon from "../images/DiamondSelectedIcon.svg";
import approvedIcon from "../images/approved-icon-1.png";
import notApprovedIcon from "../images/not-approved-icon-3.png";
import useMediaQuery from '@material-ui/core/useMediaQuery';

export default function ProjectCard({ chainId, rowdata, index, cs }) {
  const isMobile = useMediaQuery('(max-width:768px)');

  let status = rowdata.status;
  if (rowdata.startTime - rowdata.endTime === 1000) { // Meaning Bonding
    status = "Bonding";
  }
  let details = rowdata.poolDetails.toString().split("$#$");
  const social = {
    logourl: details[0],
    bannerurl: details[1],
    website: details[2],
    blockstar: details[3],
    facebook: details[4],
    twitter: details[5],
    github: details[6],
    telegram: details[7],
    instagram: details[8],
    discord: details[9],
    reddit: details[10],
    youtube: details[11],
    brief: details[12],
  };
  if (cs !== undefined) status = cs;

  const [imgageURL, setImageURL] = React.useState(social.logourl);
  const [bannerURL, setBannerURL] = React.useState(social.bannerurl);

  // >>> OVERRIDE CONSTANTS <<<
  const FIXED_POOL_ADDRESS = "0x9650bce0df003f5D4C227F2B692acF70c8AED639".toLowerCase();
  const FIXED_LOGO = "https://i.postimg.cc/bGTQvMN2/photo-2025-01-16-05-49-47.jpg";
  const FIXED_BANNER = "https://i.postimg.cc/DJw14g8p/photo-2025-01-16-05-49-52.jpg";

  // >>> OVERRIDE LOGIC <<<
  React.useEffect(() => {
    if (
      rowdata.poolAddress &&
      rowdata.poolAddress.toLowerCase() === FIXED_POOL_ADDRESS
    ) {
      setImageURL(FIXED_LOGO);
      setBannerURL(FIXED_BANNER);
    }
  }, [rowdata.poolAddress]);

  const [isFavorited, setIsFavorited] = React.useState(() => {
    const favoriteAddresses = JSON.parse(localStorage.getItem('favorites')) || [];
    return favoriteAddresses.includes(rowdata.poolAddress);
  });


  const startTime = new Date(rowdata.startTime * 1000);
  const endTime = new Date(rowdata.endTime * 1000);
  const setimage = () => {
    setImageURL(templateImg);
  }
  const setBannerImage = () => {
    setBannerURL(bannerTemplate);
  }

  const getNetworkIcon = () => {
    // First convert the values to strings before processing
    const networkValue = rowdata.network ? String(rowdata.network).toLowerCase() : '';
    const chainIdValue = chainId ? String(chainId).toLowerCase() : '';
  
    // Check Linea first
    if (networkValue === 'linea' || chainIdValue === '59144') {
      return lineaIcon;
    }
    
    // Then check BNB/BSC
    if (networkValue === 'bnb' || networkValue === 'bsc' || chainIdValue === '56') {
      return bnbIcon;
    }
    
    // Base is default, also check explicit Base conditions
    if (networkValue === 'base' || chainIdValue === '8453' || !networkValue) {
      return baseIcon;
    }
    
    // Final fallback to Base
    return baseIcon;
  };

  const toggleFavorite = () => {
    const favoriteAddresses = JSON.parse(localStorage.getItem('favorites')) || [];
    if (isFavorited) {
      const updatedFavorites = favoriteAddresses.filter(address => address !== rowdata.poolAddress);
      localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
    } else {
      favoriteAddresses.push(rowdata.poolAddress);
      localStorage.setItem('favorites', JSON.stringify(favoriteAddresses));
    }
    setIsFavorited(!isFavorited);
  };

  const iconStyle = { width: '18px', height: '18px' };

  const projectNameStyle = rowdata.name && rowdata.name.length > 20 ? { display: 'none' } : { paddingTop: "2px", paddingLeft: "10px", paddingBottom: "2px", fontSize: "18px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" };
  const projectSymbolStyle = rowdata.name && rowdata.name.length > 20 ? { paddingTop: "2px", paddingLeft: "10px", paddingBottom: "2px", fontSize: "18px" } : { display: 'none' };

  const desktopCardClass = "col-12 col-sx-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 item explore-item mt-2 project-card-item";
  const mobileCardClass = "col-12 item explore-item mb-2 mt-2 project-card-item";

  return (
    <div
      className={isMobile ? mobileCardClass : desktopCardClass}
      key={rowdata.poolAddress + rowdata.token}
      style={isMobile ? mobileCardStyle : desktopCardStyle}
    >
      <div style={{ padding: "1px", borderRadius: "8px", background: "linear-gradient(90deg, #6604FD 0%, #01F4A4 100%)", backgroundColor: "var(--bcgame-sk-woodsmoke, #111415)" }}>
        <div className="card project-card" style={{ borderRadius: "8px", backgroundColor: "var(--bcgame-sk-woodsmoke, #111415)", overflow: "hidden", width: "100%" }}>
          <div className="card-header" style={{ height: isMobile ? "120px" : "94px", borderRadius: "8px 8px 0 0", background: "", display: "flex", flexDirection: "column", overflow: "hidden", position: "relative", borderBottom: "none" }}>
            <div style={{ position: "absolute", top: "8px", left: "8px", backgroundColor: "var(--bcgame-sk-woodsmoke, #111415)", borderRadius: "13.3px", display: "flex", alignItems: "center", padding: "5px 10px" }}>
              <div style={{ width: "10px", height: "10px", borderRadius: "50%", backgroundColor: "#1cbc23", boxShadow: "0 0 8px #1cbc23", marginRight: "5px" }}></div>
              <span style={{ color: "#FFFFFF", fontSize: "10px", fontWeight: "normal" }}>{status.toUpperCase()}</span>
            </div>
            <img
  src={bannerURL}
  alt="Banner"
  style={{
    borderRadius: "8px 8px 0 0",
    objectFit: "cover",
    width: "100%",
    height: isMobile ? "140px" : "120px",
    borderBottom: "none",
    marginBottom: isMobile ? "10px" : "0px" // Add margin-bottom only for mobile
  }}
  onError={() => setBannerImage()}
/>            <img
              src={isFavorited ? selectedIcon : defaultIcon}
              alt="Favorite"
              style={{ position: "absolute", top: "8px", right: "8px", cursor: "pointer", width: "20px", height: "20px",     marginBottom: isMobile ? "10px" : "0px" // Add margin-bottom only for mobile
 }}
              onClick={toggleFavorite}
            />
          </div>
          <div
  className="card-body d-flex flex-column"
  style={{
    padding: isMobile ? "15px" : "5px", // 15px padding on mobile, 5px on desktop
    width: "100%",
    borderRadius: "8px",
    flexGrow: 1,
  }}
>            <div className="d-flex justify-content-between pb-2" style={{ marginTop: "-8px" }}>
              <div className="d-flex" style={{ flexGrow: 1 }}>
                <div
                  className="project-logo"
                  style={{
                    paddingRight: "10px",
                    marginTop: "10px",
                    padding: "3px",
                    display: "inline-block",
                    borderRadius: "50%",
                    background: "linear-gradient(180deg, #6604FD 0%, #01F4A4 100%)",
                    width: "56px",
                    height: "56px",
                    boxSizing: "border-box"
                  }}
                >
                  <div
                    style={{
                      borderRadius: "50%",
                      overflow: "hidden",
                      width: "50px",
                      height: "50px",
                      backgroundColor: "var(--bcgame-sk-woodsmoke, #111415)",
                    }}
                  >
                    <img
                      src={imgageURL}
                      onError={() => setimage()}
                      alt="logo"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <Link to={`/fairlaunch-details/${rowdata.poolAddress?.toString()}`}>
                      <h5 className="mx-0 mt-2 mb-0" style={projectNameStyle}>{rowdata.name ? rowdata.name : ""}</h5>
                      <h5 className="mx-0 mt-2 mb-0" style={projectSymbolStyle}>{rowdata.symbol ? `$${rowdata.symbol}` : ""}</h5>
                    </Link>
                  </div>
                  <div className="social-icons" style={{ marginTop: "2px", paddingLeft: "10px", paddingRight: "5px", flexGrow: 1 }}>
                    {social.website && (
                      <a target="_blank" rel="noreferrer" href={social.website}>
                        <img src={websiteIcon} alt="Website" style={{ width: "24px", marginRight: "5px" }} />
                      </a>
                    )}
                    {social.twitter && (
                      <a target="_blank" rel="noreferrer" href={social.twitter}>
                        <img src={twitterIcon} alt="Twitter" style={{ width: "24px", marginRight: "5px" }} />
                      </a>
                    )}
                    {social.discord && (
                      <a target="_blank" rel="noreferrer" href={social.discord}>
                        <img src={discordIcon} alt="Discord" style={{ width: "24px", marginRight: "5px" }} />
                      </a>
                    )}
                    {social.telegram && (
                      <a target="_blank" rel="noreferrer" href={social.telegram}>
                        <img src={telegramIcon} alt="Telegram" style={{ width: "24px", marginRight: "0px" }} />
                      </a>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ backgroundColor: "#26283C", borderRadius: "8px", padding: "0px", display: "flex", alignItems: "center", justifyContent: "center", width: "30px", height: "30px", marginTop: "34px", marginRight: "5px", flexShrink: 0, flexGrow: 0 }}>
                <img src={getNetworkIcon()} alt="Network" style={{ width: "22px" }} />
              </div>
            </div>

            {/* Conditional Rendering for Mobile Only */}
            {isMobile && (
              <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center", backgroundColor: "#0B0D21", borderRadius: "8px", padding: "8px 15px", marginBottom: "8px", border: "1.66px solid rgba(255, 255, 255, 0.1)" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={notApprovedIcon} alt="Not Approved" style={{ width: "16px", height: "18px", marginRight: "8px" }} />
                  <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "11px", fontWeight: "400", color: "#FFFFFF" }}>AUDIT</span>
                </div>
                <div className="divider" style={{ height: "21px", borderLeft: "1px solid rgba(255, 255, 255, 0.2)" }}></div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={approvedIcon} alt="SAFU" style={{ width: "18px", height: "18px", marginRight: "8px" }} />
                  <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "11px", fontWeight: "400", color: "#1cbc23" }}>SAFU</span>
                </div>
                <div className="divider" style={{ height: "21px", borderLeft: "1px solid rgba(255, 255, 255, 0.2)" }}></div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img src={notApprovedIcon} alt="Not Approved" style={{ width: "16px", height: "18px", marginRight: "8px" }} />
                  <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "11px", fontWeight: "400", color: "#FFFFFF" }}>KYC</span>
                </div>
              </div>
            )}

            <div className="mt-auto" style={{ backgroundColor: "#0B0D21", borderRadius: "8px", padding: "4px 10px", color: "#FFFFFF", border: "1.66px solid rgba(255, 255, 255, 0.1)", marginBottom: "5px", marginTop: "4px" }}>
              <div style={{ marginBottom: "10px" }}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px" }}>
                  <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "11px", fontWeight: "400", color: "#FFFFFF" }}>PROGRESS:</span>
                  <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "11px", fontWeight: "400", color: "#FFFFFF" }}>{parseFloat(rowdata.percentageRaise).toFixed(2)}%</span>
                </div>
                <div style={{ height: "10px", borderRadius: "5px", backgroundColor: "#621CBC" }}>
                  <div style={{ height: "100%", borderRadius: "5px", backgroundColor: "#1CBC23", width: `${Math.min(parseFloat(rowdata.percentageRaise), 100).toFixed(2)}%` }}></div>
                </div>
              </div>
            {/*   <div style={{ marginBottom: "0px" }}>
                <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "11px", fontWeight: "400", color: "#FFFFFF" }}>SOFTCAP:</span>
                <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "11px", fontWeight: "400", color: "#FFFFFF", float: "right" }}>{rowdata.softCap} {rowdata.currencySymbol}</span>
              </div>*/}
              <div>
                <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "11px", fontWeight: "400", color: "#FFFFFF" }}>START TIME:</span>
                <span style={{ fontFamily: "Quicksand, Helvetica", fontSize: "11px", fontWeight: "400", color: "#FFFFFF", float: "right" }}>{dateFormat(startTime, "yy-mm-dd HH:MM")}</span>
              </div>
            </div>
            <div style={{ marginTop: "0px", marginBottom: "0", marginLeft: "2px", marginRight: "2px" }}>
              <Link to={`/fairlaunch-details/${rowdata.poolAddress?.toString()}`} className="btn btn-smaller w-100" style={{ background: "linear-gradient(180deg, #6600FF 0%, #20A595 100%)", borderRadius: "8px", color: "#FFFFFF", textAlign: "center", padding: "10px 0", fontFamily: "Quicksand, Helvetica", fontSize: "14px", fontWeight: "400" }}>
                VIEW SALE
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const desktopCardStyle = {
  padding: '0px 3px', // Add padding to left and right
  margin: '0px',
  marginTop: '0px',
};

const mobileCardStyle = {
  padding: '10px 10px 0px', // Add padding to left, right, and bottom
  margin: '0px',
  maxWidth: '100%',
};
