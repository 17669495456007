// firebaseConfig.js

import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyD...",
  authDomain: "degen-chat-f8c91.firebaseapp.com",
  databaseURL: "https://degen-chat-ffdc1-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "degen-chat-f8c91",
  storageBucket: "degen-chat-f8c91.appspot.com",
  messagingSenderId: "674848651699",
  appId: "1:674848651699:web:b26b5e931587516274",
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
const storage = getStorage(app);

export { database, storage };
