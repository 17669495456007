// --------------------------
// BaseTokenTracker.js
// --------------------------

import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DefiTrackerLogo from '../../images/DefiTracker.png'; 



// Your RPC endpoint for Base
const BASE_RPC_URL = "https://mainnet.base.org";

// The DeFi token contract address on Base
const TOKEN_ADDRESS = "0x473995e934c5e871af24d6a1dabcbb0c7e89a896";

// The V2 Pool address for DexScreener
const V2_POOL_ADDRESS = "0x3FABa4AdB18a363CE356C0fD32919E642570A42D";

// We minted 1 trillion tokens at deployment, and 80% (800B) was burned at launch:
const BURNED_AT_LAUNCH = "800000000000";

// The "dead" address for fees burn:
const DEAD_ADDRESS = "0x000000000000000000000000000000000000dEaD";


// --------------------------
// FULL ABI DEFINITION BELOW
// --------------------------
const CONTRACT_ABI = [
  {
    "inputs": [
      {
        "components": [
          { "internalType": "string", "name": "name", "type": "string" },
          { "internalType": "string", "name": "symbol", "type": "string" },
          { "internalType": "uint8", "name": "decimals", "type": "uint8" },
          { "internalType": "uint256", "name": "totalSupply", "type": "uint256" },
          { "internalType": "address", "name": "serviceFeeReceiver", "type": "address" },
          { "internalType": "address", "name": "taxReceiver", "type": "address" },
          { "internalType": "uint256", "name": "maxTransaction", "type": "uint256" },
          { "internalType": "uint256", "name": "maxWallet", "type": "uint256" },
          { "internalType": "uint256", "name": "buyFee", "type": "uint256" },
          { "internalType": "uint256", "name": "sellFee", "type": "uint256" },
          { "internalType": "address", "name": "nonfungiblePositionManager", "type": "address" },
          { "internalType": "address", "name": "swapRouter", "type": "address" },
          { "internalType": "uint256", "name": "rewardToken", "type": "uint256" },
          { "internalType": "uint256", "name": "buyReward", "type": "uint256" },
          { "internalType": "uint256", "name": "sellReward", "type": "uint256" },
          { "internalType": "uint256", "name": "lpBuyFee", "type": "uint256" },
          { "internalType": "uint256", "name": "lpSellFee", "type": "uint256" },
          { "internalType": "uint256", "name": "buyBurnPercent", "type": "uint256" },
          { "internalType": "uint256", "name": "sellBurnPercent", "type": "uint256" },
          { "internalType": "uint256", "name": "serviceFee", "type": "uint256" }
        ],
        "internalType": "struct DEFILaunchOFT.Args",
        "name": "args",
        "type": "tuple"
      },
      { "internalType": "address", "name": "_lzEndpoint", "type": "address" }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  { "inputs": [], "name": "InvalidDelegate", "type": "error" },
  { "inputs": [], "name": "InvalidEndpointCall", "type": "error" },
  { "inputs": [], "name": "InvalidLocalDecimals", "type": "error" },
  {
    "inputs": [
      { "internalType": "bytes", "name": "options", "type": "bytes" }
    ],
    "name": "InvalidOptions",
    "type": "error"
  },
  { "inputs": [], "name": "InvalidShortString", "type": "error" },
  { "inputs": [], "name": "LzTokenUnavailable", "type": "error" },
  {
    "inputs": [
      { "internalType": "uint32", "name": "eid", "type": "uint32" }
    ],
    "name": "NoPeer",
    "type": "error"
  },
  {
    "inputs": [
      { "internalType": "uint256", "name": "msgValue", "type": "uint256" }
    ],
    "name": "NotEnoughNative",
    "type": "error"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "addr", "type": "address" }
    ],
    "name": "OnlyEndpoint",
    "type": "error"
  },
  {
    "inputs": [
      { "internalType": "uint32", "name": "eid", "type": "uint32" },
      { "internalType": "bytes32", "name": "sender", "type": "bytes32" }
    ],
    "name": "OnlyPeer",
    "type": "error"
  },
  { "inputs": [], "name": "OnlySelf", "type": "error" },
  {
    "inputs": [
      { "internalType": "bytes", "name": "result", "type": "bytes" }
    ],
    "name": "SimulationResult",
    "type": "error"
  },
  {
    "inputs": [
      { "internalType": "uint256", "name": "amountLD", "type": "uint256" },
      { "internalType": "uint256", "name": "minAmountLD", "type": "uint256" }
    ],
    "name": "SlippageExceeded",
    "type": "error"
  },
  {
    "inputs": [
      { "internalType": "string", "name": "str", "type": "string" }
    ],
    "name": "StringTooLong",
    "type": "error"
  },
  {
    "anonymous": false,
    "inputs": [
      { "indexed": true, "internalType": "address", "name": "owner", "type": "address" },
      { "indexed": true, "internalType": "address", "name": "spender", "type": "address" },
      { "indexed": false, "internalType": "uint256", "name": "value", "type": "uint256" }
    ],
    "name": "Approval",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      { "indexed": true, "internalType": "address", "name": "account", "type": "address" },
      { "indexed": false, "internalType": "bool", "name": "status", "type": "bool" }
    ],
    "name": "BlacklistUpdated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [],
    "name": "EIP712DomainChanged",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      { "indexed": true, "internalType": "address", "name": "account", "type": "address" },
      { "indexed": false, "internalType": "uint256", "name": "amount", "type": "uint256" }
    ],
    "name": "ETHClaimed",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "components": [
          { "internalType": "uint32", "name": "eid", "type": "uint32" },
          { "internalType": "uint16", "name": "msgType", "type": "uint16" },
          { "internalType": "bytes", "name": "options", "type": "bytes" }
        ],
        "indexed": false,
        "internalType": "struct EnforcedOptionParam[]",
        "name": "_enforcedOptions",
        "type": "tuple[]"
      }
    ],
    "name": "EnforcedOptionSet",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      { "indexed": false, "internalType": "address", "name": "inspector", "type": "address" }
    ],
    "name": "MsgInspectorSet",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      { "indexed": true, "internalType": "bytes32", "name": "guid", "type": "bytes32" },
      { "indexed": false, "internalType": "uint32", "name": "srcEid", "type": "uint32" },
      { "indexed": true, "internalType": "address", "name": "toAddress", "type": "address" },
      { "indexed": false, "internalType": "uint256", "name": "amountReceivedLD", "type": "uint256" }
    ],
    "name": "OFTReceived",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      { "indexed": true, "internalType": "bytes32", "name": "guid", "type": "bytes32" },
      { "indexed": false, "internalType": "uint32", "name": "dstEid", "type": "uint32" },
      { "indexed": true, "internalType": "address", "name": "fromAddress", "type": "address" },
      { "indexed": false, "internalType": "uint256", "name": "amountSentLD", "type": "uint256" },
      { "indexed": false, "internalType": "uint256", "name": "amountReceivedLD", "type": "uint256" }
    ],
    "name": "OFTSent",
    "type": "event"
  },
  {
    "inputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "name": "automatedMarketMakerPairs",
    "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "address", "name": "account", "type": "address" }],
    "name": "balanceOf",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "name": "blackList",
    "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "stateMutability": "view",
    "type": "function"
  },
  { "inputs": [], "name": "burnFee", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
  { "inputs": [], "name": "buyRewardFee", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
  { "inputs": [], "name": "buyTaxFee", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
  {
    "inputs": [
      { "internalType": "uint32", "name": "_eid", "type": "uint32" },
      { "internalType": "uint16", "name": "_msgType", "type": "uint16" },
      { "internalType": "bytes", "name": "_extraOptions", "type": "bytes" }
    ],
    "name": "combineOptions",
    "outputs": [{ "internalType": "bytes", "name": "", "type": "bytes" }],
    "stateMutability": "view",
    "type": "function"
  },
  { "inputs": [], "name": "decimalConversionRate", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
  { "inputs": [], "name": "decimals", "outputs": [{ "internalType": "uint8", "name": "", "type": "uint8" }], "stateMutability": "pure", "type": "function" },
  {
    "inputs": [
      { "internalType": "address", "name": "spender", "type": "address" },
      { "internalType": "uint256", "name": "subtractedValue", "type": "uint256" }
    ],
    "name": "decreaseAllowance",
    "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "eip712Domain",
    "outputs": [
      { "internalType": "bytes1", "name": "fields", "type": "bytes1" },
      { "internalType": "string", "name": "name", "type": "string" },
      { "internalType": "string", "name": "version", "type": "string" },
      { "internalType": "uint256", "name": "chainId", "type": "uint256" },
      { "internalType": "address", "name": "verifyingContract", "type": "address" },
      { "internalType": "bytes32", "name": "salt", "type": "bytes32" },
      { "internalType": "uint256[]", "name": "extensions", "type": "uint256[]" }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  { "inputs": [], "name": "enableTrading", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
  {
    "inputs": [],
    "name": "endpoint",
    "outputs": [{ "internalType": "contract ILayerZeroEndpointV2", "name": "", "type": "address" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "uint32", "name": "_eid", "type": "uint32" },
      { "internalType": "uint16", "name": "_msgType", "type": "uint16" }
    ],
    "name": "enforcedOptions",
    "outputs": [{ "internalType": "bytes", "name": "enforcedOption", "type": "bytes" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "name": "feeWhitelist",
    "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "name": "holderExists",
    "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "name": "holders",
    "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "holdersProcessLimit",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },

  // ----- CONTINUATION OF ABI -----
  {
    "inputs": [
      { "internalType": "address", "name": "spender", "type": "address" },
      { "internalType": "uint256", "name": "addedValue", "type": "uint256" }
    ],
    "name": "increaseAllowance",
    "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          { "internalType": "uint32", "name": "srcEid", "type": "uint32" },
          { "internalType": "bytes32", "name": "sender", "type": "bytes32" },
          { "internalType": "uint64", "name": "nonce", "type": "uint64" }
        ],
        "internalType": "struct Origin",
        "name": "origin",
        "type": "tuple"
      },
      { "internalType": "bytes", "name": "", "type": "bytes" },
      { "internalType": "address", "name": "_sender", "type": "address" }
    ],
    "name": "isComposeMsgSender",
    "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "uint32", "name": "_eid", "type": "uint32" },
      { "internalType": "bytes32", "name": "_peer", "type": "bytes32" }
    ],
    "name": "isPeer",
    "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "lastProcessedIndex",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          {
            "components": [
              { "internalType": "uint32", "name": "srcEid", "type": "uint32" },
              { "internalType": "bytes32", "name": "sender", "type": "bytes32" },
              { "internalType": "uint64", "name": "nonce", "type": "uint64" }
            ],
            "internalType": "struct Origin",
            "name": "origin",
            "type": "tuple"
          },
          { "internalType": "uint32", "name": "dstEid", "type": "uint32" },
          { "internalType": "address", "name": "receiver", "type": "address" },
          { "internalType": "bytes32", "name": "guid", "type": "bytes32" },
          { "internalType": "uint256", "name": "value", "type": "uint256" },
          { "internalType": "address", "name": "executor", "type": "address" },
          { "internalType": "bytes", "name": "message", "type": "bytes" },
          { "internalType": "bytes", "name": "extraData", "type": "bytes" }
        ],
        "internalType": "struct InboundPacket[]",
        "name": "_packets",
        "type": "tuple[]"
      }
    ],
    "name": "lzReceiveAndRevert",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          { "internalType": "uint32", "name": "srcEid", "type": "uint32" },
          { "internalType": "bytes32", "name": "sender", "type": "bytes32" },
          { "internalType": "uint64", "name": "nonce", "type": "uint64" }
        ],
        "internalType": "struct Origin",
        "name": "_origin",
        "type": "tuple"
      },
      { "internalType": "bytes32", "name": "_guid", "type": "bytes32" },
      { "internalType": "bytes", "name": "_message", "type": "bytes" },
      { "internalType": "address", "name": "_executor", "type": "address" },
      { "internalType": "bytes", "name": "_extraData", "type": "bytes" }
    ],
    "name": "lzReceiveSimulate",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "name": "magnifiedCorrections",
    "outputs": [{ "internalType": "int256", "name": "", "type": "int256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "magnifiedEthPerShare",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "mainPair",
    "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "maxTransaction",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "maxWallet",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "minimumTokenBalanceForDividends",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "msgInspector",
    "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "name",
    "outputs": [{ "internalType": "string", "name": "", "type": "string" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "uint32", "name": "", "type": "uint32" },
      { "internalType": "bytes32", "name": "", "type": "bytes32" }
    ],
    "name": "nextNonce",
    "outputs": [{ "internalType": "uint64", "name": "nonce", "type": "uint64" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "address", "name": "owner", "type": "address" }],
    "name": "nonces",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "oApp",
    "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "oAppVersion",
    "outputs": [
      { "internalType": "uint64", "name": "senderVersion", "type": "uint64" },
      { "internalType": "uint64", "name": "receiverVersion", "type": "uint64" }
    ],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "oftVersion",
    "outputs": [
      { "internalType": "bytes4", "name": "interfaceId", "type": "bytes4" },
      { "internalType": "uint64", "name": "version", "type": "uint64" }
    ],
    "stateMutability": "pure",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "stateMutability": "view",
    "type": "function"
  },
  { "inputs": [], "name": "paused", "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }], "stateMutability": "view", "type": "function" },
  {
    "inputs": [{ "internalType": "uint32", "name": "_eid", "type": "uint32" }],
    "name": "peers",
    "outputs": [{ "internalType": "bytes32", "name": "peer", "type": "bytes32" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "owner", "type": "address" },
      { "internalType": "address", "name": "spender", "type": "address" },
      { "internalType": "uint256", "name": "value", "type": "uint256" },
      { "internalType": "uint256", "name": "deadline", "type": "uint256" },
      { "internalType": "uint8", "name": "v", "type": "uint8" },
      { "internalType": "bytes32", "name": "r", "type": "bytes32" },
      { "internalType": "bytes32", "name": "s", "type": "bytes32" }
    ],
    "name": "permit",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "preCrime",
    "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "stateMutability": "view",
    "type": "function"
  },
  { "inputs": [], "name": "processRewards", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
  {
    "inputs": [{ "internalType": "uint256", "name": "gasLimit", "type": "uint256" }],
    "name": "processRewardsWithGasLimit",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          { "internalType": "uint32", "name": "dstEid", "type": "uint32" },
          { "internalType": "bytes32", "name": "to", "type": "bytes32" },
          { "internalType": "uint256", "name": "amountLD", "type": "uint256" },
          { "internalType": "uint256", "name": "minAmountLD", "type": "uint256" },
          { "internalType": "bytes", "name": "extraOptions", "type": "bytes" },
          { "internalType": "bytes", "name": "composeMsg", "type": "bytes" },
          { "internalType": "bytes", "name": "oftCmd", "type": "bytes" }
        ],
        "internalType": "struct SendParam",
        "name": "_sendParam",
        "type": "tuple"
      }
    ],
    "name": "quoteOFT",
    "outputs": [
      {
        "components": [
          { "internalType": "uint256", "name": "minAmountLD", "type": "uint256" },
          { "internalType": "uint256", "name": "maxAmountLD", "type": "uint256" }
        ],
        "internalType": "struct OFTLimit",
        "name": "oftLimit",
        "type": "tuple"
      },
      {
        "components": [
          { "internalType": "int256", "name": "feeAmountLD", "type": "int256" },
          { "internalType": "string", "name": "description", "type": "string" }
        ],
        "internalType": "struct OFTFeeDetail[]",
        "name": "oftFeeDetails",
        "type": "tuple[]"
      },
      {
        "components": [
          { "internalType": "uint256", "name": "amountSentLD", "type": "uint256" },
          { "internalType": "uint256", "name": "amountReceivedLD", "type": "uint256" }
        ],
        "internalType": "struct OFTReceipt",
        "name": "oftReceipt",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          { "internalType": "uint32", "name": "dstEid", "type": "uint32" },
          { "internalType": "bytes32", "name": "to", "type": "bytes32" },
          { "internalType": "uint256", "name": "amountLD", "type": "uint256" },
          { "internalType": "uint256", "name": "minAmountLD", "type": "uint256" },
          { "internalType": "bytes", "name": "extraOptions", "type": "bytes" },
          { "internalType": "bytes", "name": "composeMsg", "type": "bytes" },
          { "internalType": "bytes", "name": "oftCmd", "type": "bytes" }
        ],
        "internalType": "struct SendParam",
        "name": "_sendParam",
        "type": "tuple"
      },
      { "internalType": "bool", "name": "_payInLzToken", "type": "bool" }
    ],
    "name": "quoteSend",
    "outputs": [
      {
        "components": [
          { "internalType": "uint256", "name": "nativeFee", "type": "uint256" },
          { "internalType": "uint256", "name": "lzTokenFee", "type": "uint256" }
        ],
        "internalType": "struct MessagingFee",
        "name": "msgFee",
        "type": "tuple"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "address", "name": "token", "type": "address" }],
    "name": "recoverToken",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  { "inputs": [], "name": "renounceOwnership", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
  { "inputs": [], "name": "sellRewardFee", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
  { "inputs": [], "name": "sellTaxFee", "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }], "stateMutability": "view", "type": "function" },
  {
    "inputs": [
      {
        "components": [
          { "internalType": "uint32", "name": "dstEid", "type": "uint32" },
          { "internalType": "bytes32", "name": "to", "type": "bytes32" },
          { "internalType": "uint256", "name": "amountLD", "type": "uint256" },
          { "internalType": "uint256", "name": "minAmountLD", "type": "uint256" },
          { "internalType": "bytes", "name": "extraOptions", "type": "bytes" },
          { "internalType": "bytes", "name": "composeMsg", "type": "bytes" },
          { "internalType": "bytes", "name": "oftCmd", "type": "bytes" }
        ],
        "internalType": "struct SendParam",
        "name": "_sendParam",
        "type": "tuple"
      },
      {
        "components": [
          { "internalType": "uint256", "name": "nativeFee", "type": "uint256" },
          { "internalType": "uint256", "name": "lzTokenFee", "type": "uint256" }
        ],
        "internalType": "struct MessagingFee",
        "name": "_fee",
        "type": "tuple"
      },
      { "internalType": "address", "name": "_refundAddress", "type": "address" }
    ],
    "name": "send",
    "outputs": [
      {
        "components": [
          { "internalType": "bytes32", "name": "guid", "type": "bytes32" },
          { "internalType": "uint64", "name": "nonce", "type": "uint64" },
          {
            "components": [
              { "internalType": "uint256", "name": "nativeFee", "type": "uint256" },
              { "internalType": "uint256", "name": "lzTokenFee", "type": "uint256" }
            ],
            "internalType": "struct MessagingFee",
            "name": "fee",
            "type": "tuple"
          }
        ],
        "internalType": "struct MessagingReceipt",
        "name": "msgReceipt",
        "type": "tuple"
      },
      {
        "components": [
          { "internalType": "uint256", "name": "amountSentLD", "type": "uint256" },
          { "internalType": "uint256", "name": "amountReceivedLD", "type": "uint256" }
        ],
        "internalType": "struct OFTReceipt",
        "name": "oftReceipt",
        "type": "tuple"
      }
    ],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "serviceFeeReceiver",
    "outputs": [{ "internalType": "address payable", "name": "", "type": "address" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "pair", "type": "address" },
      { "internalType": "bool", "name": "value", "type": "bool" }
    ],
    "name": "setAutomatedMarketMakerPair",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "_addr", "type": "address" },
      { "internalType": "bool", "name": "_value", "type": "bool" }
    ],
    "name": "setBlacklist",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "_delegate", "type": "address" }
    ],
    "name": "setDelegate",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "components": [
          { "internalType": "uint32", "name": "eid", "type": "uint32" },
          { "internalType": "uint16", "name": "msgType", "type": "uint16" },
          { "internalType": "bytes", "name": "options", "type": "bytes" }
        ],
        "internalType": "struct EnforcedOptionParam[]",
        "name": "_enforcedOptions",
        "type": "tuple[]"
      }
    ],
    "name": "setEnforcedOptions",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "addr", "type": "address" },
      { "internalType": "bool", "name": "value", "type": "bool" }
    ],
    "name": "setFeeWhitelist",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "uint256", "name": "_buyRewardFee", "type": "uint256" },
      { "internalType": "uint256", "name": "_sellRewardFee", "type": "uint256" },
      { "internalType": "uint256", "name": "_buyTaxFee", "type": "uint256" },
      { "internalType": "uint256", "name": "_sellTaxFee", "type": "uint256" },
      { "internalType": "uint256", "name": "_burnFee", "type": "uint256" }
    ],
    "name": "setFees",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "uint256", "name": "limit", "type": "uint256" }],
    "name": "setHoldersProcessLimit",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "address", "name": "_pair", "type": "address" }],
    "name": "setMainPair",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "uint256", "name": "_maxTransaction", "type": "uint256" },
      { "internalType": "uint256", "name": "_maxWallet", "type": "uint256" }
    ],
    "name": "setMaxTransactionAndMaxWallet",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "uint256", "name": "amount", "type": "uint256" }],
    "name": "setMinimumTokenBalanceForDividends",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "_msgInspector", "type": "address" }
    ],
    "name": "setMsgInspector",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "bool", "name": "_paused", "type": "bool" }],
    "name": "setPause",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "uint32", "name": "_eid", "type": "uint32" },
      { "internalType": "bytes32", "name": "_peer", "type": "bytes32" }
    ],
    "name": "setPeer",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "_preCrime", "type": "address" }
    ],
    "name": "setPreCrime",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  { "inputs": [], "name": "sharedDecimals", "outputs": [{ "internalType": "uint8", "name": "", "type": "uint8" }], "stateMutability": "view", "type": "function" },
  {
    "inputs": [{ "internalType": "uint256", "name": "tokenAmount", "type": "uint256" }],
    "name": "swapRewardsForETH",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "swapRouter",
    "outputs": [{ "internalType": "contract IUniswapV2Router02", "name": "", "type": "address" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "symbol",
    "outputs": [{ "internalType": "string", "name": "", "type": "string" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "taxReceiver",
    "outputs": [{ "internalType": "address payable", "name": "", "type": "address" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "token",
    "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalHolders",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalRewardTokens",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "totalSupply",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "tradingEnabled",
    "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "to", "type": "address" },
      { "internalType": "uint256", "name": "amount", "type": "uint256" }
    ],
    "name": "transfer",
    "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [
      { "internalType": "address", "name": "from", "type": "address" },
      { "internalType": "address", "name": "to", "type": "address" },
      { "internalType": "uint256", "name": "amount", "type": "uint256" }
    ],
    "name": "transferFrom",
    "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "address", "name": "newOwner", "type": "address" }],
    "name": "transferOwnership",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },
  { "inputs": [], "name": "withdrawETH", "outputs": [], "stateMutability": "nonpayable", "type": "function" },
  {
    "inputs": [{ "internalType": "address", "name": "account", "type": "address" }],
    "name": "withdrawableETHOf",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "name": "withdrawnEth",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  { "stateMutability": "payable", "type": "receive" }
];

// --------------------------
// END of ABI
// --------------------------



// Outer gradient container
const outerContainerStyle = (isMobile) => ({
    width: isMobile ? '95%' : '70%',
    margin: '40px auto',
    background: 'linear-gradient(90deg, #6604FD 0%, #01F4A4 100%)',
    borderRadius: '12px',
    padding: '2px',
  });
  
  // The inner dark card
  const cardStyle = {
    backgroundColor: 'var(--bcgame-sk-woodsmoke, #111415)',
    borderRadius: '12px',
    padding: '20px',
    color: '#FFFFFF',
    fontFamily: 'Quicksand, Helvetica, sans-serif',
  };
  
  // Main heading
  const headingStyle = {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '24px',
    background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 'bold',
  };
  
  // Subheading for each section
  const sectionHeadingStyle = {
    fontSize: '18px',
    marginBottom: '8px',
    fontWeight: '600',
    color: '#21A595',
  };
  
  // Label + input
  const inputContainerStyle = {
    marginBottom: '16px',
  };
  
  const labelStyle = {
    display: 'block',
    color: '#98A7B5',
    marginBottom: '6px',
    fontSize: '14px',
    fontWeight: 400,
  };
  
  const inputStyle = {
    width: '100%',
    padding: '12px',
    borderRadius: '8px',
    border: '1px solid rgba(255,255,255,0.1)',
    backgroundColor: '#1A1B1E',
    color: '#FFFFFF',
    outline: 'none',
    fontSize: '14px',
  };
  
  const buttonStyle = {
    width: '100%',
    padding: '12px',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
    color: '#FFFFFF',
    fontSize: '16px',
    fontWeight: '500',
    marginTop: '8px',
  };
  
  // Results block
  const resultsContainerStyle = {
    marginTop: '24px',
    backgroundColor: '#0B0D21',
    borderRadius: '12px',
    border: '1.66px solid rgba(255, 255, 255, 0.1)',
    padding: '16px',
  };
  
  // Data row
  const dataRowStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: '6px',
    fontSize: '14px',
  };
  
  // Label vs. value
  const labelSpanStyle = {
    color: '#98A7B5',
    fontWeight: 400,
  };
  
  const valueSpanStyle = {
    color: '#98A7B5',
    fontWeight: 400,
  };
  
  // Divider
  const dividerStyle = {
    height: '1px',
    backgroundColor: 'rgba(255,255,255,0.1)',
    margin: '10px 0',
  };
  
  // Error text
  const errorStyle = {
    color: 'red',
    marginTop: '8px',
    fontSize: '14px',
  };

  const addressSpanStyle = {
    background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 400,
  };
  
  const copyButtonStyle = {
    marginLeft: '6px',
    background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '6px',
    padding: '6px 10px',
    cursor: 'pointer',
  };
  
  
  export default function BaseTokenTracker() {
    const [typedAddress, setTypedAddress] = React.useState("");
    const [errorMessage, setErrorMessage] = React.useState("");
  
    // How many tokens are in the dead address (burned from fees)
    const [burnedFees, setBurnedFees] = useState(0);
  
    // State for the at-launch burn as a float
    const [burnedLaunchFloat, setBurnedLaunchFloat] = useState(0);
    // State for total burned (launch + fees)
    const [totalBurnFloat, setTotalBurnFloat] = useState(0);
    const [supplyAfterBurn, setSupplyAfterBurn] = useState(0);
  
    // Contract data
    const [decimals, setDecimals] = React.useState(18);
    const [tokenName, setTokenName] = React.useState("");
    const [tokenSymbol, setTokenSymbol] = React.useState("");
    const [totalSupply, setTotalSupply] = React.useState("0");
    const [burnedAdditional, setBurnedAdditional] = React.useState("0");
    const [ethRewards, setEthRewards] = React.useState("0");
    const [userTokenBalance, setUserTokenBalance] = React.useState("0");
    const [userBalanceUsd, setUserBalanceUsd] = React.useState("0");
  
    // Dex data
    const [priceUsd, setPriceUsd] = React.useState("0");
    const [priceChange24, setPriceChange24] = React.useState("0");
    const [volume24, setVolume24] = React.useState("0");
  
    // NEW: For comma formatting the numeric values
    const [burnedLaunchFormatted, setBurnedLaunchFormatted] = useState("0");
    const [totalBurnFormatted, setTotalBurnFormatted] = useState("0");
    const [userBalanceFormatted, setUserBalanceFormatted] = useState("0");
  
    // Computed
    const [marketCap, setMarketCap] = React.useState("0");
  
    // Original minted supply => 1T tokens in 18 decimals
    const originalMintBN = ethers.utils.parseUnits("1000000000000", 18);
    // The 80% burned at launch, as BN
    const burnedAtLaunchBN = ethers.utils.parseUnits(BURNED_AT_LAUNCH, 18);
  
    // Ethers setup
    const provider = new ethers.providers.JsonRpcProvider(BASE_RPC_URL);
    const tokenContract = new ethers.Contract(TOKEN_ADDRESS, CONTRACT_ABI, provider);
  
    // Simple copy function
    const copyToClipboard = async (text) => {
      try {
        await navigator.clipboard.writeText(text);
        alert("Copied: " + text);
      } catch (err) {
        console.error("Failed to copy!", err);
      }
    };
  
    // Fetch data from DexScreener
    const fetchDexData = async () => {
      try {
        const url = `https://api.dexscreener.io/latest/dex/tokens/${TOKEN_ADDRESS}`;
        const res = await fetch(url);
        const data = await res.json();
        
        if (!data || !data.pairs) {
          console.error("No pairs found");
          return;
        }
  
        // Attempt to find the specific V2 pool
        const pair = data.pairs.find(
          (p) => p?.poolAddress?.toLowerCase() === V2_POOL_ADDRESS.toLowerCase()
        ) || data.pairs[0];
  
        if (!pair) {
          console.error("Could not find a valid pair for that pool");
          return;
        }
  
        // Extract needed data
        setPriceUsd(pair.priceUsd || "0");
        setPriceChange24(pair?.priceChange?.h24 ? pair.priceChange.h24.toFixed(2) : "0");
        setVolume24(pair?.volume?.h24 ? pair.volume.h24.toFixed(2) : "0");



    // NEW: If DexScreener returns a property called `fdv` or `fullyDilutedValuation`:
    if (pair.fdv) {
       
        const mc = parseFloat(pair.fdv).toFixed(2);
        setMarketCap(mc);
      }
      else {
        // fallback to "0"
        setMarketCap("0");
      }



      } catch (err) {
        console.error("Error fetching DexScreener data:", err);
      }
    };
  
    // MAIN fetch data (contract + Dex) on button click
    const handleFetchData = async () => {
      setErrorMessage("");
      const addressToQuery = typedAddress.trim();
  
      if (!addressToQuery) {
        setErrorMessage("No wallet address provided.");
        return;
      }
      if (!ethers.utils.isAddress(addressToQuery)) {
        setErrorMessage("Invalid wallet address.");
        return;
      }
  
      try {
        // 1) Dex data
        await fetchDexData();
  
        // 2) Contract calls
        const [supplyBN, decimalsBN, nameStr, symbolStr] = await Promise.all([
          tokenContract.totalSupply(),
          tokenContract.decimals(),
          tokenContract.name(),
          tokenContract.symbol(),
        ]);
  
        setDecimals(decimalsBN);
        setTokenName(nameStr);
        setTokenSymbol(symbolStr);
  
        // -- Format total supply with commas, no decimals --
        const rawSupply = ethers.utils.formatUnits(supplyBN, decimalsBN);
        const supplyNum = parseFloat(rawSupply); // convert to float
        const supplyFormatted = supplyNum.toLocaleString("en-US", {
          maximumFractionDigits: 0,
        });
        setTotalSupply(supplyFormatted);
  
        // Additional burned = originalMint - burnedAtLaunch - supplyBN
        const additionalBurnBN = originalMintBN.sub(burnedAtLaunchBN).sub(supplyBN);
        if (additionalBurnBN.lt(0)) {
          setBurnedAdditional("0");
        } else {
          // Convert to float, format with commas, no decimals
          const additionalBurnNum = parseFloat(
            ethers.utils.formatUnits(additionalBurnBN, decimalsBN)
          );
          const additionalBurnStr = additionalBurnNum.toLocaleString("en-US", {
            maximumFractionDigits: 0,
          });
          setBurnedAdditional(additionalBurnStr);
        }
  
        // 1) Get total in the dead address
        const deadBalBN = await tokenContract.balanceOf(DEAD_ADDRESS);
        const totalInDeadFloat = parseFloat(
          ethers.utils.formatUnits(deadBalBN, decimalsBN)
        );
  
        // 2) Subtract 800B to get fees burned since launch
        let feesOnly = totalInDeadFloat - 800000000000;
        if (feesOnly < 0) feesOnly = 0;
  
        // 3) Floor (remove decimals)
        feesOnly = Math.floor(feesOnly);
  
        // 4) Comma-format fees
        const burnedFeesStr = feesOnly.toLocaleString("en-US", {
          maximumFractionDigits: 0,
        });
        setBurnedFees(burnedFeesStr);
  
        // 5) Convert launch burn to float
        const burnedLaunchFloatLocal = parseFloat(BURNED_AT_LAUNCH);
  
        // Also comma-format "Burned at Launch"
        const burnedLaunchFormattedLocal = burnedLaunchFloatLocal.toLocaleString("en-US", {
          maximumFractionDigits: 0,
        });
        setBurnedLaunchFloat(burnedLaunchFloatLocal);
        setBurnedLaunchFormatted(burnedLaunchFormattedLocal);
  
        // 6) Grand total: launch burn + fee burn
        const totalBurnFloatLocal = burnedLaunchFloatLocal + feesOnly;
  
        // Also comma-format "Total Burned"
        const totalBurnFormattedLocal = totalBurnFloatLocal.toLocaleString("en-US", {
          maximumFractionDigits: 0,
        });
        setTotalBurnFloat(totalBurnFloatLocal);
        setTotalBurnFormatted(totalBurnFormattedLocal);
  
        // 7) supplyAfterBurn = 1T minus totalBurnFloatLocal
        let supplyAfter = 1000000000000 - totalBurnFloatLocal;
        if (supplyAfter < 0) supplyAfter = 0;
        const supplyAfterStr = Math.floor(supplyAfter).toLocaleString("en-US", {
          maximumFractionDigits: 0,
        });
        setSupplyAfterBurn(supplyAfterStr);
  
        // 8) Market Cap
       // const pUsd = parseFloat(priceUsd) || 0;
       // const mc = supplyNum * pUsd;
       // setMarketCap(mc.toFixed(2));
  
        // Rewards
        const rewardsBN = await tokenContract.withdrawableETHOf(addressToQuery);
        const rawRewards = ethers.utils.formatEther(rewardsBN);
        setEthRewards(rawRewards);
  
        // User token balance
        const balBN = await tokenContract.balanceOf(addressToQuery);
        const rawBal = ethers.utils.formatUnits(balBN, decimalsBN);
        const userBalNum = parseFloat(rawBal);
  
        // Format with commas + 2 decimals
        const userBalComma = userBalNum.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
        setUserTokenBalance(`${userBalComma} DEFI`); // updated
  
        // For the Balance (USD), multiply full balance by priceUsd
        const priceNum = parseFloat(priceUsd);
        const userBalUsd = (userBalNum * priceNum).toFixed(2);
        setUserBalanceUsd(userBalUsd);
  
      } catch (err) {
        console.error("handleFetchData error:", err);
        setErrorMessage("Error fetching data. Check console/logs.");
      }
    };
  
  
  
    // Summaries
    const totalBurned = parseFloat(BURNED_AT_LAUNCH) + parseFloat(burnedAdditional);
    const isMobile = useMediaQuery('(max-width:768px)');
  
    return (
      <div style={outerContainerStyle(isMobile)}>
        <div style={cardStyle}>


        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
  <img
    src={DefiTrackerLogo}
    alt="DeFi Tracker Logo"
    style={{
      maxWidth: '100%',    // fill up to container's width
      height: 'auto',      // keep aspect ratio
      display: 'block',    // ensure no inline spacing issues
      margin: '0 auto',    // center horizontally
    }}
  />
</div>




          {/* Input row */}
          <div style={inputContainerStyle}>
            <label htmlFor="walletAddress" style={labelStyle}>
              Paste Wallet Address:
            </label>
            <input
              id="walletAddress"
              type="text"
              style={inputStyle}
              value={typedAddress}
              onChange={(e) => setTypedAddress(e.target.value)}
              placeholder="0x..."
            />
            <button style={buttonStyle} onClick={handleFetchData}>
              Fetch Data
            </button>
          </div>
  
          {errorMessage && <p style={errorStyle}>{errorMessage}</p>}
  
          {/* Results container */}
          <div style={resultsContainerStyle}>
            {/* Token Info */}
            <h2 style={sectionHeadingStyle}>Token Info</h2>
            <div style={dataRowStyle}>
              <span style={labelSpanStyle}>Name:</span>
              <span style={valueSpanStyle}>{tokenName}</span>
            </div>
            <div style={dataRowStyle}>
              <span style={labelSpanStyle}>Symbol:</span>
              <span style={valueSpanStyle}>{tokenSymbol}</span>
            </div>
            <div style={dataRowStyle}>
              <span style={labelSpanStyle}>Decimals:</span>
              <span style={valueSpanStyle}>{decimals}</span>
            </div>
            
            <div style={dataRowStyle}>
  <span style={labelSpanStyle}>Token Address:</span>
  <span style={valueSpanStyle}>
    <span style={addressSpanStyle}>
      {TOKEN_ADDRESS.slice(0, 6)}...{TOKEN_ADDRESS.slice(-4)}
    </span>{" "}
    <button
      onClick={() => copyToClipboard(TOKEN_ADDRESS)}
      style={{
        marginLeft: '4px',
        background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
        color: '#FFFFFF',
        border: 'none',
        borderRadius: '4px',
        padding: '3px 5px',
        fontSize: '12px',
        cursor: 'pointer',
      }}
    >
      📋
    </button>
  </span>
</div>

<div style={dataRowStyle}>
  <span style={labelSpanStyle}>Pool Address:</span>
  <span style={valueSpanStyle}>
    <span style={addressSpanStyle}>
      {V2_POOL_ADDRESS.slice(0, 6)}...{V2_POOL_ADDRESS.slice(-4)}
    </span>{" "}
    <button
      onClick={() => copyToClipboard(V2_POOL_ADDRESS)}
      style={{
        marginLeft: '4px',
        background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
        color: '#FFFFFF',
        border: 'none',
        borderRadius: '4px',
        padding: '3px 5px',
        fontSize: '12px',
        cursor: 'pointer',
      }}
    >
      📋
    </button>
  </span>
</div>


            <div style={dataRowStyle}>
              <span style={labelSpanStyle}>Total Supply:</span>
              <span style={valueSpanStyle}>{totalSupply} DEFI</span>
            </div>

            <div style={dataRowStyle}>
  <span style={labelSpanStyle}>After Burn:</span>
  <span style={valueSpanStyle}>{supplyAfterBurn} DEFI</span>
</div>
          
  
            <div style={dividerStyle} />
  
            {/* Burns */}
            <h2 style={sectionHeadingStyle}>Burns</h2>
            <div style={dataRowStyle}>
  <span style={labelSpanStyle}>Burned at Launch:</span>
  <span style={valueSpanStyle}>
    {burnedLaunchFormatted} DEFI
  </span>
</div>
<div style={dataRowStyle}>
  <span style={labelSpanStyle}>Burned from Fees:</span>
  <span style={valueSpanStyle}>
    {burnedFees} DEFI
  </span>
</div>
<div style={dataRowStyle}>
  <span style={labelSpanStyle}>Total Burned:</span>
  <span style={valueSpanStyle}>
    {totalBurnFormatted} DEFI
  </span>
</div>




  
            <div style={dividerStyle} />
  
            {/* User Stats */}
            <h2 style={sectionHeadingStyle}>Your Holdings</h2>
{/* DEFI token balance */}
<div style={dataRowStyle}>
  <span style={labelSpanStyle}>Balance (DEFI):</span>
  <span style={valueSpanStyle}>{userTokenBalance}</span>
</div>

{/* USD value of those tokens */}
<div style={dataRowStyle}>
  <span style={labelSpanStyle}>Value (USD):</span>
  <span style={valueSpanStyle}>${userBalanceUsd}</span>
</div>

{/* ETH rewards */}
<div style={dataRowStyle}>
  <span style={labelSpanStyle}>Rewards (Applied once token stable):</span>
  <span style={valueSpanStyle}>{ethRewards} ETH</span>
</div>
  
            <div style={dividerStyle} />
  
            {/* Dex & Market Info */}
            <h2 style={sectionHeadingStyle}>Dex &amp; Market Info</h2>
            <div style={dataRowStyle}>
              <span style={labelSpanStyle}>Price (USD):</span>
              <span style={valueSpanStyle}>${priceUsd}</span>
            </div>
            <div style={dataRowStyle}>
              <span style={labelSpanStyle}>24h %:</span>
              <span style={valueSpanStyle}>{priceChange24}%</span>
            </div>

            <div style={dataRowStyle}>
              <span style={labelSpanStyle}>Market Cap:</span>
              <span style={valueSpanStyle}>${marketCap}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
  