import React, { useState, useEffect } from 'react';

export default function StakingPoolCard({ pool, onJoinPool, onManageRewards, onEdit }) {
  const [showTooltipStaked, setShowTooltipStaked] = useState(false);
  const [showTooltipApy, setShowTooltipApy] = useState(false);
  const [showTooltipRewards, setShowTooltipRewards] = useState(false);

  let autoCloseTimerStaked;
  let autoCloseTimerApy;
  let autoCloseTimerRewards;

  // ---------- TOTAL STAKED ----------
  const handleStakedEnter = () => {
    openTooltipStaked();
  };
  const handleStakedLeave = () => {
    closeTooltipStaked();
  };
  const handleStakedClick = () => {
    showTooltipStaked ? closeTooltipStaked() : openTooltipStaked();
  };
  function openTooltipStaked() {
    setShowTooltipStaked(true);
    if (autoCloseTimerStaked) clearTimeout(autoCloseTimerStaked);
    autoCloseTimerStaked = setTimeout(() => {
      setShowTooltipStaked(false);
    }, 5000);
  }
  function closeTooltipStaked() {
    setShowTooltipStaked(false);
    if (autoCloseTimerStaked) clearTimeout(autoCloseTimerStaked);
  }

  // ---------- APY ----------
  const handleApyEnter = () => {
    openTooltipApy();
  };
  const handleApyLeave = () => {
    closeTooltipApy();
  };
  const handleApyClick = () => {
    showTooltipApy ? closeTooltipApy() : openTooltipApy();
  };
  function openTooltipApy() {
    setShowTooltipApy(true);
    if (autoCloseTimerApy) clearTimeout(autoCloseTimerApy);
    autoCloseTimerApy = setTimeout(() => {
      setShowTooltipApy(false);
    }, 5000);
  }
  function closeTooltipApy() {
    setShowTooltipApy(false);
    if (autoCloseTimerApy) clearTimeout(autoCloseTimerApy);
  }

  // ---------- REWARDS ----------
  const handleRewardsEnter = () => {
    openTooltipRewards();
  };
  const handleRewardsLeave = () => {
    closeTooltipRewards();
  };
  const handleRewardsClick = () => {
    showTooltipRewards ? closeTooltipRewards() : openTooltipRewards();
  };
  function openTooltipRewards() {
    setShowTooltipRewards(true);
    if (autoCloseTimerRewards) clearTimeout(autoCloseTimerRewards);
    autoCloseTimerRewards = setTimeout(() => {
      setShowTooltipRewards(false);
    }, 5000);
  }
  function closeTooltipRewards() {
    setShowTooltipRewards(false);
    if (autoCloseTimerRewards) clearTimeout(autoCloseTimerRewards);
  }

  // ---------- Main Button ----------
  const handleMainButton = () => {
    if (onJoinPool) {
      onJoinPool(pool.id);
    }
  };

  // ---------- Styles ----------
  const tooltipStyle = {
    position: 'absolute',
    top: '24px',
    left: '0',
    zIndex: 99,
    backgroundColor: '#1f1f1f',
    color: '#fff',
    padding: '8px 12px',
    borderRadius: '6px',
    fontSize: '0.8rem',
    boxShadow: '0 2px 8px rgba(0,0,0,0.5)',
    maxWidth: '220px',
  };
  const closeBtnStyle = {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#fff',
    cursor: 'pointer',
    fontWeight: 'bold',
  };
  const gradientButtonStyle = {
    background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
    border: 'none',
    borderRadius: '6px',
    color: '#FFF',
    padding: '10px',
    cursor: 'pointer',
    fontWeight: '500',
  };
  const statusDotStyle = (live) => ({
    display: 'inline-block',
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    marginLeft: '6px',
    marginRight: '4px',
    backgroundColor: live ? '#22C55E' : '#FF4D4D',
    animation: live ? 'glow 2s infinite' : 'none',
  });

  useEffect(() => {
    return () => {
      if (autoCloseTimerStaked) clearTimeout(autoCloseTimerStaked);
      if (autoCloseTimerApy) clearTimeout(autoCloseTimerApy);
      if (autoCloseTimerRewards) clearTimeout(autoCloseTimerRewards);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div
      style={{
        backgroundColor: '#161A42',
        borderRadius: '12px',
        color: '#FFF',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Banner */}
      <div
        style={{
          width: '100%',
          height: '100px',
          borderTopLeftRadius: '12px',
          borderTopRightRadius: '12px',
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <img
          src={pool.bannerUrl}
          alt="banner"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        {pool.isOwner && onEdit && (
          <button
            style={{
              position: 'absolute',
              bottom: '8px',
              right: '8px',
              backgroundColor: 'rgba(0,0,0,0.6)',
              border: 'none',
              borderRadius: '50%',
              width: '26px',
              height: '26px',
              color: '#FFF',
              cursor: 'pointer',
              fontSize: '1rem',
              textAlign: 'center',
            }}
            onClick={() => onEdit(pool)}
            title="Edit Banner/Logo, etc."
          >
            ✎
          </button>
        )}
      </div>

      {/* Logo Overlap */}
      <div
        style={{
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          border: '2px solid #FFF',
          position: 'absolute',
          top: '70px',
          left: '16px',
          overflow: 'hidden',
        }}
      >
        <img
          src={pool.logoUrl}
          alt="logo"
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
      </div>

      {/* Content */}
      <div style={{ padding: '16px', paddingTop: '28px' }}>
        {/* Status line */}
        <div style={{ marginBottom: '6px' }}>
          <span style={{ color: '#7B91B0', fontSize: '0.9rem' }}>
            <b>Status:</b>
          </span>
          <span style={statusDotStyle(pool.liveStatus)} />
          <span
            style={{
              color: pool.liveStatus ? '#22C55E' : '#FF4D4D',
              marginLeft: '3px',
              fontSize: '0.9rem',
            }}
          >
            {pool.liveStatus ? 'Live' : 'Ended'}
          </span>
        </div>

        {/* Pool Name */}
        <h2 style={{ fontSize: '1.2rem', fontWeight: 'bold', marginBottom: '0px' }}>
          {pool.name}
        </h2>

        {/* Gradient text for Stake/Earn */}
        <p
          style={{
            fontSize: '0.9rem',
            marginBottom: '10px',
            background: '#8454EB',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          Stake {pool.tokenSymbol} to Earn {pool.tokenSymbol}
        </p>

        {/* Separator */}
        <div
          style={{
            borderBottom: '1px solid #7B91B0',
            opacity: 0.4,
            margin: '8px 0',
          }}
        />

        {/* TOTAL STAKED */}
        <div
          style={{
            fontSize: '0.9rem',
            marginBottom: '8px',
            position: 'relative',
            cursor: 'pointer',
          }}
          onMouseEnter={handleStakedEnter}
          onMouseLeave={handleStakedLeave}
          onClick={handleStakedClick}
        >
          <span style={{ color: '#7B91B0' }}>Total Staked: </span>
          <span style={{ color: '#FFFFFF' }}>{pool.totalStaked}</span>

          {showTooltipStaked && (
            <div style={tooltipStyle}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <strong>Total Staked</strong>
                <button onClick={closeTooltipStaked} style={closeBtnStyle}>X</button>
              </div>
              <p style={{ marginTop: '6px' }}>
                The total amount of tokens currently deposited by all users in this pool.
              </p>
            </div>
          )}
        </div>

        {/* APY */}
        <div
          style={{
            fontSize: '0.9rem',
            marginBottom: '8px',
            position: 'relative',
            cursor: 'pointer',
          }}
          onMouseEnter={handleApyEnter}
          onMouseLeave={handleApyLeave}
          onClick={handleApyClick}
        >
          <span style={{ color: '#7B91B0' }}>APY: </span>
          <span style={{ color: '#22C55E' }}>
            {pool.apy ? `${pool.apy}%` : '0.00%'}
          </span>

          {showTooltipApy && (
            <div style={tooltipStyle}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <strong>APY</strong>
                <button onClick={closeTooltipApy} style={closeBtnStyle}>X</button>
              </div>
              <p style={{ marginTop: '6px' }}>
                Annual Percentage Yield, based on rewards, time left, and total staked.
              </p>
            </div>
          )}
        </div>

        {/* REWARDS */}
        <div
          style={{
            fontSize: '0.9rem',
            marginBottom: '8px',
            position: 'relative',
            cursor: 'pointer',
          }}
          onMouseEnter={handleRewardsEnter}
          onMouseLeave={handleRewardsLeave}
          onClick={handleRewardsClick}
        >
          <span style={{ color: '#7B91B0' }}>Rewards: </span>
          <span style={{ color: '#FFFFFF' }}>{pool.rewardBalance || '0'}</span>

          {showTooltipRewards && (
            <div style={tooltipStyle}>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <strong>Rewards</strong>
                <button onClick={closeTooltipRewards} style={closeBtnStyle}>X</button>
              </div>
              <p style={{ marginTop: '6px' }}>
                The total tokens left in the pool to be paid out as staking rewards.
              </p>
            </div>
          )}
        </div>

        {/* Separator */}
        <div
          style={{
            borderBottom: '1px solid #7B91B0',
            opacity: 0.4,
            margin: '8px 0',
          }}
        />

        {/* Ends in (only if live) */}
        {pool.liveStatus && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: '16px',
              fontSize: '0.9rem',
            }}
          >
            <span style={{ color: '#7B91B0' }}>Ends in:</span>
            <span style={{ color: '#FFF' }}>{pool.timeRemaining}</span>
          </div>
        )}

        {/* Buttons */}
        {typeof pool.id === 'string' && pool.id.startsWith('0x') && (
          <div>
            {pool.isOwner ? (
              <div style={{ display: 'flex', gap: '8px' }}>
                <button
                  style={{
                    ...gradientButtonStyle,
                    flex: 1,
                  }}
                  onClick={handleMainButton}
                >
                  View Pool
                </button>
                <button
                  style={{
                    ...gradientButtonStyle,
                    flex: 1,
                  }}
                  onClick={() => {
                    if (onManageRewards) onManageRewards(pool.id);
                  }}
                >
                  Rewards
                </button>
              </div>
            ) : (
              <button
                style={{
                  ...gradientButtonStyle,
                  width: '100%',
                }}
                onClick={handleMainButton}
              >
                Join Pool
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
