// PriceWidget.js
import React, { useEffect, useState } from "react";
import { ethers } from "ethers";
import TickerLogo from "../images/DEFI-logo.png"; // Adjust path if needed

////////////////////////////////////////////////
//  Configuration
////////////////////////////////////////////////

// Base RPC endpoint
const BASE_RPC_URL = "https://mainnet.base.org";

// The pair contract (Uniswap V2–style) on Base
const PAIR_ADDRESS  = "0x3FABa4AdB18a363CE356C0fD32919E642570A42D";
// Your Token
const TOKEN_ADDRESS = "0x473995e934C5E871aF24D6A1daBcBb0c7e89a896";
// WETH on Base
const WETH_ADDRESS  = "0x4200000000000000000000000000000000000006";

// **Hard-coded** current supply of 200,000,000,000 tokens
const CURRENT_SUPPLY = 200_000_000_000;

// Minimal ABIs
const pairAbi = [
  "function getReserves() view returns (uint112, uint112, uint32)",
  "function token0() view returns (address)",
  "function token1() view returns (address)"
];
const erc20Abi = [
  "function decimals() view returns (uint8)"
];

// Coingecko ID for WETH’s price
const WETH_COINGECKO_ID = "weth";

// Time (ms) between fetches
const REFRESH_INTERVAL = 30_000; // 30 seconds

function PriceWidget() {
  const [price, setPrice] = useState(null);       // token price in USD
  const [marketCap, setMarketCap] = useState(null);
  const [isLive, setIsLive] = useState(false);    // green if success, red if error
  const [flashIndex, setFlashIndex] = useState(0);

  // On mount, load from localStorage. If no data, default to 0 (instead of "No Data")
  useEffect(() => {
    const cachedPrice = localStorage.getItem("myTokenPrice");
    const cachedMC    = localStorage.getItem("myTokenMcap");

    setPrice(cachedPrice ? Number(cachedPrice) : 0);
    setMarketCap(cachedMC ? Number(cachedMC) : 0);

    // Function to actually fetch on-chain data
    const fetchOnChainData = async () => {
      try {
        // Use ethers to read pair reserves
        const provider = new ethers.providers.JsonRpcProvider(BASE_RPC_URL);
        const pairContract = new ethers.Contract(PAIR_ADDRESS, pairAbi, provider);

        // Identify token0 / token1
        const token0 = await pairContract.token0();
        const token1 = await pairContract.token1();

        // getReserves
        const [reserve0, reserve1] = await pairContract.getReserves();

        // read decimals for your token & WETH
        const tokenContract = new ethers.Contract(TOKEN_ADDRESS, erc20Abi, provider);
        const wethContract  = new ethers.Contract(WETH_ADDRESS, erc20Abi, provider);

        const [tokenDecimals, wethDecimals] = await Promise.all([
          tokenContract.decimals(),
          wethContract.decimals()
        ]);

        // Decide which side is yourToken vs WETH
        let yourTokenReserve, wethReserve;
        if (token0.toLowerCase() === TOKEN_ADDRESS.toLowerCase()) {
          yourTokenReserve = reserve0;
          wethReserve      = reserve1;
        } else {
          yourTokenReserve = reserve1;
          wethReserve      = reserve0;
        }

        // Convert BN to floats
        const yourTokenFloat = Number(yourTokenReserve.toString()) / 10 ** tokenDecimals;
        const wethFloat      = Number(wethReserve.toString())      / 10 ** wethDecimals;

        // Price in WETH => (wethFloat / yourTokenFloat)
        const tokenPriceWeth = wethFloat / yourTokenFloat;

        // Coingecko to get WETH -> USD
        const coingeckoUrl = `https://api.coingecko.com/api/v3/simple/price?ids=${WETH_COINGECKO_ID}&vs_currencies=usd`;
        const cgResp = await fetch(coingeckoUrl);
        if (!cgResp.ok) {
          throw new Error(`Coingecko error: ${cgResp.status}`);
        }
        const cgData = await cgResp.json();
        const wethPriceUsd = cgData?.[WETH_COINGECKO_ID]?.usd;
        if (!wethPriceUsd) {
          throw new Error("No WETH price from Coingecko");
        }

        // final token price in USD
        const yourTokenPriceUsd = tokenPriceWeth * wethPriceUsd;

        // Hard-coded current supply => 200,000,000,000 tokens
        const mcUsd = CURRENT_SUPPLY * yourTokenPriceUsd;

        // success => update states, set isLive = true
        setPrice(yourTokenPriceUsd);
        setMarketCap(mcUsd);
        setIsLive(true);

        // store in localStorage
        localStorage.setItem("myTokenPrice",  String(yourTokenPriceUsd));
        localStorage.setItem("myTokenMcap",   String(mcUsd));

      } catch (err) {
        console.error("Error loading on-chain data =>", err.message);
        // if fail, keep old data, set isLive = false
        setIsLive(false);
      }
    };

    // Immediately fetch once on mount
    fetchOnChainData();

    // Then fetch every 30 seconds
    const intervalId = setInterval(fetchOnChainData, REFRESH_INTERVAL);
    return () => clearInterval(intervalId);

  }, []);

  // Flash PRICE & MARKET CAP every 2s
  useEffect(() => {
    const interval = setInterval(() => {
      setFlashIndex(prev => (prev + 1) % 2);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  //////////////////////////////////////////////////
  // Format price w/ decimals, remove decimals on MC
  //////////////////////////////////////////////////
  const formatPrice = (val) => {
    const num = Number(val) || 0;
    if (num >= 1) return `$${num.toFixed(2)}`;
    if (num >= 0.01) return `$${num.toFixed(4)}`;
    if (num >= 0.0001) return `$${num.toFixed(6)}`;
    return `$${num.toFixed(8)}`;
  };

  // MC => integer
  const formatMarketCap = (val) => {
    const num = Number(val) || 0;
    return "$" + Math.round(num).toLocaleString();
  };

  const priceMsg = (
    <>
      PRICE&nbsp;
      <span style={{ color: "#00ffad" }}>{formatPrice(price)}</span>
    </>
  );

  const mcMsg = (
    <>
      MARKET CAP&nbsp;
      <span style={{ color: "#00ffad" }}>{formatMarketCap(marketCap)}</span>
    </>
  );

  const flashedContent = flashIndex === 0 ? priceMsg : mcMsg;

  return (
    <div className="price-widget-container">
      <div className="price-widget-button" style={{ position: "relative" }}>
        {/* green if isLive, else red */}
        {isLive ? <div style={styles.dotGreen} /> : <div style={styles.dotRed} />}

        <div className="logo-box">
          <img src={TickerLogo} alt="DEFI Logo" />
        </div>

        <div className="widget-text">{flashedContent}</div>
      </div>

      <style jsx>{cssStyles}</style>
    </div>
  );
}

export default PriceWidget;

///////////////////////////////////////////////////////
// Dot + CSS styling
///////////////////////////////////////////////////////
const styles = {
  dotGreen: {
    position: "absolute",
    top: 6,
    right: 8,
    width: 4,
    height: 4,
    borderRadius: "50%",
    backgroundColor: "#57d873",
    boxShadow: "0 0 6px #57d873",
    zIndex: 2,
  },
  dotRed: {
    position: "absolute",
    top: 6,
    right: 8,
    width: 4,
    height: 4,
    borderRadius: "50%",
    backgroundColor: "#ff7676",
    boxShadow: "0 0 6px #ff7676",
    zIndex: 2,
  },
};

const cssStyles = `
.price-widget-container {
  display: inline-block;
}

.price-widget-button {
  width: 176px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #4DFFFFFF;
  background: linear-gradient(180deg, #161A42 0%, #161A42 0%);
  color: #FFFFFF;
  font-size: 12px;
  font-weight: normal;
  font-family: 'Quicksand', sans-serif; 
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
  margin-left: 14px;
  margin-right: 6px;
  margin-top: 0px;
  cursor: default;
}

.price-widget-button:hover {
  box-shadow: 0 0 2px #6366f1, 0 0 5px #8b5cf6, 0 0 10px #8b5cf6;
}

.logo-box {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 1px solid #4DFFFFFF;
  background: #161A42;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -2px;
  margin-right: -8px;
}

.logo-box img {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.widget-text {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center; 
  text-align: center;
  color: #cccccc;
  font-family: 'Quicksand', sans-serif;
}
`;

