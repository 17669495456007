// NoticeModal.js
import React, { useState, useEffect } from "react";
import BackgroundImage from "../images/trenches.png";

function NoticeModal({ onClose }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hasSeenNotice = localStorage.getItem("hasSeenNotice");
    if (!hasSeenNotice) {
      setIsVisible(true);
    }
    window.addEventListener("resize", () => {});
    return () => window.removeEventListener("resize", () => {});
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  const handleDoNotShowAgain = () => {
    localStorage.setItem("hasSeenNotice", "true");
    setIsVisible(false);
    onClose();
  };

  if (!isVisible) return null;

  // Main container
  const modalStyle = {
    backgroundColor: "rgba(20, 20, 48, 0.9)",
    backgroundImage: `url(${BackgroundImage})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundBlendMode: "multiply",

    // Enough padding so text doesn't hug edges
    padding: "16px",

    marginTop: "30px",
    borderRadius: "12px",
    border: "1px solid #4379FF",
    boxShadow: "0 0 24px #4379FF",
    width: "90%",
    maxWidth: "500px",
    minHeight: "50vh",
    maxHeight: "85vh",
    overflowY: "auto",
    textAlign: "center",
    position: "fixed",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    paddingBottom: "20px",
    zIndex: 10000,
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        backdropFilter: "blur(5px)",
        WebkitBackdropFilter: "blur(5px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        zIndex: 9999,
      }}
    >
      <div style={modalStyle}>
        {/* HEADER with margin above and below */}
        <h4 style={headerTextStyle}>WELCOME TO THE TRENCHES</h4>

        {/* FIRST PARAGRAPHS */}
        <div style={topParagraphsContainer}>

          <p style={paragraphStyle}>
           Find the next 100x Gem</p>
            
          <p style={paragraphStyle}>
            on <span style={whiteText}>Base</span>,{" "}
            <span style={whiteText}>BNB</span>,{" "}
            <span style={whiteText}>Linea</span> or{" "}
            <span style={whiteText}>Sol</span>.
          </p>
          <p style={paragraphStyle}>

          <p style={paragraphStyle}>Fair Launch & Pump Sales.</p>

Buy &amp; Sell at any time!</p>


        </div>

        {/* DIVIDER - margin above & below */}
        <div style={dividerStyle} />

        {/* SECOND PARAGRAPHS */}
        <div style={bottomParagraphsContainer}>
          <p style={paragraphStyle}>
            Hold <span style={whiteText}>$DEFI</span> for{" "}
            <span style={whiteText}>ETH Rewards</span>
          </p>
          <p style={paragraphStyle}>
          <span style={whiteText}>Platform Fees</span> Buyback <span style={whiteText}>$DEFI</span>
          </p>
        </div>

        {/* BUTTONS - margin below for breathing room */}
        <div style={buttonContainerStyle}>
          <button style={closeButtonStyle} onClick={handleClose}>
            Close
          </button>
          <button style={dontShowButtonStyle} onClick={handleDoNotShowAgain}>
            Don't Show Again
          </button>
        </div>
      </div>
    </div>
  );
}

export default NoticeModal;

/* =========================================
   STYLES
   ========================================= */

// HEADER
const headerTextStyle = {
  fontSize: "22px",
  fontWeight: "bold",
  fontFamily: "'Orbitron', sans-serif",
  color: "#3cb6ff",
  textTransform: "uppercase",

  // Spacing above & below header
  marginTop: "30px",
  marginBottom: "30px",

  lineHeight: "1.2",
};

/**
 * Common paragraph style with pink→blue gradient.
 */
const paragraphStyle = {
  margin: 0,
  padding: 0,
  marginBottom: "10px", // space below each paragraph
  fontSize: "16px",
  fontWeight: 600,
  fontFamily: "'Orbitron', sans-serif",
  letterSpacing: "0.5px",
  lineHeight: "1.4",
  background: "linear-gradient(135deg, #ff0099 0%, #0055ff 100%)",
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};

/**
 * White style for $DEFI, Base, etc.
 */
const whiteText = {
  color: "#fff",
  background: "none",
  WebkitBackgroundClip: "initial",
  WebkitTextFillColor: "initial",
};

/**
 * Top paragraphs container - margin below
 */
const topParagraphsContainer = {
  marginTop: "0px",      // no extra top margin (header handles it)
  marginBottom: "30px",  // some space between last paragraph & divider
  padding: 0,
};

/**
 * Bottom paragraphs container - margin above
 */
const bottomParagraphsContainer = {
  marginTop: "30px",     // space above (divider below)
  marginBottom: "30px",  // some space below paragraphs
  padding: 0,
};

/**
 * Divider with margin top & bottom
 */
const dividerStyle = {
  width: "80%",
  height: "1px",
  margin: "0 auto 10px auto", // 10px below, 0 top
  background: "linear-gradient(to right, #4379FF, rgba(255,255,255,0.1))",
};

/**
 * Button container with margin below for breathing room
 */
const buttonContainerStyle = {
  display: "flex",
  justifyContent: "center",
  gap: "10px",
  marginTop: "10px",
  marginBottom: "10px",
};

const baseButtonStyle = {
  border: "none",
  borderRadius: "6px",
  cursor: "pointer",
  fontSize: "14px",
  fontWeight: 500,
  padding: "8px 20px",
  backgroundColor: "#4379FF",
  color: "#fff",
  transition: "background-color 0.2s",
  // You can reduce or increase for more/less bottom gap
  marginBottom: "0px",
};

const closeButtonStyle = {
  ...baseButtonStyle,
};

const dontShowButtonStyle = {
  ...baseButtonStyle,
};
