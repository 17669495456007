import React, { useContext, useState } from "react";
import LockInput from "../../component/LockInput"; 
// ^ Make sure the path is correct based on your folder structure
import Context from "./context/Context";
import { getWeb3 } from "../../hooks/connectors";
import { toast } from "react-toastify";
import { contract } from "../../hooks/constant";
import { mulDecimal } from "../../hooks/contractHelper";
import tokenAbi from "../../json/token.json";
import lockAbi from "../../json/lockabi.json";
import { parseEther } from "@ethersproject/units";
import Button from "react-bootstrap-button-loader";
import DatePicker from "react-datepicker";
import { CopyToClipboard } from "react-copy-to-clipboard";
import copyIcon from "../../images/icon.png";
import { useAccount, useChainId } from "wagmi";
import { config } from "../../config";
import { writeContract, waitForTransactionReceipt } from "@wagmi/core";

export default function Lock() {
  const chainId = useChainId();
  const { address: account } = useAccount();
  const { value, setValue } = useContext(Context);

  const [loading, setLoading] = useState(false);
  const [lockloading, setLockLoading] = useState(false);

  // Basic field-level errors
  const [error, setError] = useState({
    owner: "",
    title: "",
    amount: "",
    TGEDate: "",
    tokenAddress: "",
  });

  // Minimal validation for addresses
  const validateAddress = (addr) => {
    const web3 = getWeb3(chainId);
    return web3.utils.isAddress(addr);
  };

  // Check if we have everything needed to proceed
  const checkAllValidation = () => {
    let hasError = false;

    // tokenAddress required
    if (!value.tokenAddress) {
      setError((prev) => ({ ...prev, tokenAddress: "Please enter a token address!" }));
      hasError = true;
    } else if (!validateAddress(value.tokenAddress)) {
      setError((prev) => ({ ...prev, tokenAddress: "Invalid token address format!" }));
      hasError = true;
    } else {
      setError((prev) => ({ ...prev, tokenAddress: "" }));
    }

    // Title
    if (!value.title) {
      setError((prev) => ({ ...prev, title: "Please enter a lock title!" }));
      hasError = true;
    } else {
      setError((prev) => ({ ...prev, title: "" }));
    }

    // Amount
    if (!value.amount || parseFloat(value.amount) <= 0) {
      setError((prev) => ({ ...prev, amount: "Please enter a valid amount!" }));
      hasError = true;
    } else {
      setError((prev) => ({ ...prev, amount: "" }));
    }

    // Lock date
    if (!value.TGEDate) {
      setError((prev) => ({ ...prev, TGEDate: "Please select a lock end date!" }));
      hasError = true;
    } else {
      setError((prev) => ({ ...prev, TGEDate: "" }));
    }

    return !hasError;
  };

  // If user toggles "Use different owner address"
  const handleDiffOwner = (e) => {
    setValue({ ...value, isDiffOwner: e.target.checked });
  };

  // If user changes the different owner address
  const handleOwnerChange = (e) => {
    if (!validateAddress(e.target.value)) {
      setError((prev) => ({ ...prev, owner: "Invalid address" }));
    } else {
      setError((prev) => ({ ...prev, owner: "" }));
    }
    setValue({ ...value, owner: e.target.value });
  };

  // Lock until date
  const handleEndTimeChange = (date) => {
    setValue({ ...value, TGEDate: date });
    setError((prev) => ({ ...prev, TGEDate: "" }));
  };

  /**
   * handleApprove
   * - Approves the lock contract to spend tokens
   */
  const handleApprove = async (e) => {
    e.preventDefault();
    if (!account) {
      toast.error("Please Connect Wallet!");
      return;
    }
    if (!chainId) {
      toast.error("Please select the correct Network!");
      return;
    }

    // Only check if we have a valid token address
    if (!value.tokenAddress || error.tokenAddress) {
      toast.error(error.tokenAddress || "Please enter a valid token address!");
      return;
    }

    try {
      setLoading(true);
      const web3 = getWeb3(chainId);
      let lockAddress = contract[chainId]?.lockAddress || contract["default"].lockAddress;
      let maxAmount = parseEther("1000000000000000000000000000").toString(); // a large approval

      const result = await writeContract(config, {
        abi: tokenAbi,
        address: value.tokenAddress,
        functionName: "approve",
        args: [lockAddress, maxAmount],
      });

      toast.promise(new Promise((resolve) => setTimeout(resolve, 1500)), {
        pending: "Waiting for approval confirmation...",
      });

      const interval = setInterval(async () => {
        const response = await waitForTransactionReceipt(config, { hash: result });
        if (response && response.status) {
          clearInterval(interval);
          if (response.status === "success") {
            toast.success("Approve transaction confirmed!");
            setValue({ ...value, isApprove: true });
          } else {
            toast.error("Transaction failed. Please try again.");
          }
          setLoading(false);
        }
      }, 3000);
    } catch (err) {
      console.error(err);
      toast.error(err.reason || err.message);
      setLoading(false);
    }
  };

  /**
   * handleLockToken
   * - Actually locks the tokens in the contract
   */
  const handleLockToken = async (e) => {
    e.preventDefault();
    if (!account) {
      toast.error("Please Connect Wallet!");
      return;
    }
    if (!checkAllValidation()) {
      toast.error("Please fix errors before locking!");
      return;
    }

    try {
      setLockLoading(true);
      const web3 = getWeb3(chainId);
      const lockAddress = contract[chainId]?.lockAddress || contract["default"].lockAddress;

      // Example for a normal lock (non-vesting).
      const result = await writeContract(config, {
        abi: lockAbi,
        address: lockAddress,
        functionName: "lock",
        args: [
          value.isDiffOwner ? value.owner : account,
          value.tokenAddress,
          value.islp === 1, // if it's an LP token
          mulDecimal(value.amount, value.tokenDecimal || 18), 
          Math.floor(new Date(value.TGEDate).getTime() / 1000.0),
          value.title || "Untitled Lock",
        ],
      });

      toast.promise(new Promise((resolve) => setTimeout(resolve, 1500)), {
        pending: "Locking tokens...",
      });

      const interval = setInterval(async () => {
        const response = await waitForTransactionReceipt(config, { hash: result });
        if (response && response.status) {
          clearInterval(interval);
          if (response.status === "success") {
            toast.success("Tokens locked successfully!");
          } else {
            toast.error("Lock transaction failed. Please try again.");
          }
          setLockLoading(false);
        }
      }, 3000);
    } catch (err) {
      console.error(err);
      toast.error(err.reason || err.message);
      setLockLoading(false);
    }
  };

  // Basic styling
  const styles = {
    container: {
      padding: '32px',
      background: '#0B0E1F',
      borderRadius: '16px',
      border: '1px solid rgba(255, 255, 255, 0.1)',
    },
    inputContainer: {
      marginBottom: '24px',
    },
    label: {
      color: '#34D399',
      fontSize: '14px',
      display: 'block',
      marginBottom: '8px',
    },
    input: {
      width: '100%',
      height: '50px',
      background: '#161A42',
      borderRadius: '8px',
      border: 'none',
      padding: '0 16px',
      color: '#F1F8FD',
      fontSize: '14px',
      outline: 'none',
    },
    errorMsg: {
      color: '#EF4444',
      fontSize: '12px',
      marginTop: '4px',
    },
    button: {
      height: '50px',
      padding: '0 32px',
      borderRadius: '8px',
      fontSize: '16px',
      fontWeight: '600',
      border: 'none',
      cursor: 'pointer',
      background: '#2F6EFF',
      color: '#fff',
      margin: '0 auto',
      display: 'block',
    },
  };

  return (
    <div style={styles.container}>
      {/* 1) Token Address Input (LockInput) */}
      <LockInput value={value} setValue={setValue} />
      {error.tokenAddress && <div style={styles.errorMsg}>{error.tokenAddress}</div>}

      {/* 2) Different Owner? */}
      <div style={{ marginBottom: '24px' }}>
        <label style={styles.label}>
          <input
            type="checkbox"
            checked={value.isDiffOwner}
            onChange={handleDiffOwner}
            style={{ marginRight: '8px' }}
          />
          Use different owner address?
        </label>
        {value.isDiffOwner && (
          <>
            <input
              style={styles.input}
              type="text"
              placeholder="Enter owner address"
              value={value.owner || ""}
              onChange={handleOwnerChange}
            />
            {error.owner && <div style={styles.errorMsg}>{error.owner}</div>}
          </>
        )}
      </div>

      {/* 3) Title */}
      <div style={styles.inputContainer}>
        <label style={styles.label}>Title</label>
        <input
          style={styles.input}
          type="text"
          placeholder="Enter lock title"
          value={value.title || ""}
          onChange={(e) => setValue({ ...value, title: e.target.value })}
        />
        {error.title && <div style={styles.errorMsg}>{error.title}</div>}
      </div>

      {/* 4) Amount */}
      <div style={styles.inputContainer}>
        <label style={styles.label}>Amount</label>
        <input
          style={styles.input}
          type="text"
          placeholder="Enter amount to lock"
          value={value.amount || ""}
          onChange={(e) => setValue({ ...value, amount: e.target.value })}
        />
        {error.amount && <div style={styles.errorMsg}>{error.amount}</div>}
      </div>

      {/* 5) Lock End Time (non-vesting) */}
      <div style={styles.inputContainer}>
        <label style={styles.label}>Lock until</label>
        <DatePicker
          selected={value.TGEDate}
          onChange={handleEndTimeChange}
          placeholderText="Select lock end date"
          dateFormat="yyyy-MM-dd HH:mm"
          showTimeSelect
          isClearable
          className="custom-datepicker"
        />
        {error.TGEDate && <div style={styles.errorMsg}>{error.TGEDate}</div>}
      </div>

      {/* Approve OR Lock */}
      <Button
        loading={value.isApprove ? lockloading : loading}
        variant="none"
        type="button"
        style={styles.button}
        onClick={value.isApprove ? handleLockToken : handleApprove}
      >
        {value.isApprove ? 'Lock Tokens' : 'Approve'}
      </Button>

      {/* Info note */}
      <div style={{ marginTop: '24px', padding: '16px', background: '#1f2f3f' }}>
        IMPORTANT: Please exclude our Contract address{" "}
        <span style={{ color: '#fff' }}>
          {contract[chainId]?.lockAddress || contract["default"].lockAddress}
        </span>{" "}
        from fees, rewards & max tx amount.
        <CopyToClipboard
          text={contract[chainId]?.lockAddress || contract["default"].lockAddress}
          onCopy={() => toast.success("Contract address copied!")}
        >
          <img 
            src={copyIcon}
            alt="Copy"
            style={{ marginLeft: 8, cursor: 'pointer', width: '18px' }}
          />
        </CopyToClipboard>
      </div>

      <style>
        {`
          .custom-datepicker {
            width: 100%;
            height: 50px;
            background: #161A42;
            border-radius: 8px;
            border: none;
            padding: 0 16px;
            color: #F1F8FD;
            font-size: 14px;
            outline: none;
          }
        `}
      </style>
    </div>
  );
}
