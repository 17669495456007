// Filename: src/component/DefiHolders.js
import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";

import headerImage from "../images/DefiHolders.png";
import chartBg from "../images/blockchainBg.png";

// 1 trillion total supply
const TOTAL_SUPPLY = 1_000_000_000_000;

// All-lowercase contract addresses
const specialAddresses = {
  "0x000000000000000000000000000000000000dead": {
    label: "Burn/Dead",
    hover: "80% burned + 1% burn Tax.",
    isContract: true
  },
  "0x0fc55b6a9b6467d1248e83611e09f72084d09225": {
    label: "LayerZero",
    hover: "Contract for LayerZero Transfer",
    link: "https://basescan.org/address/0x0FC55b6a9B6467D1248e83611e09f72084D09225#code",
    isContract: true
  },
  "0xc74068ee5579e7bc41474e9f1b1862d6b6f9a3de": {
    label: "Staking",
    hover: "Contract for Staking",
    link: "https://basescan.org/address/0xC74068ee5579e7bc41474E9F1b1862D6B6F9a3DE#code",
    isContract: true
  },
  "0x3faba4adb18a363ce356c0fd32919e642570a42d": {
    label: "UNI v2",
    hover: "Contract for Uniswap V2",
    link: "https://basescan.org/address/0x3faba4adb18a363ce356c0fd32919e642570a42d#code",
    isContract: true
  },
  "0xe2a923c6f4e10bcfd72873a80ea4baa13c95b18b": {
    label: "DEV",
    hover: "Locked Contract for Developer",
    isContract: true
  }
};

// Purple → turquoise gradient for chart bars
function getNeonGradient() {
  return {
    type: "linear",
    x: 0,
    y: 0,
    x2: 1,
    y2: 0,
    colorStops: [
      { offset: 0, color: "#7A36FF" },
      { offset: 1, color: "#00E5FF" }
    ]
  };
}

// Minimal copy icon
const copyIconSVG = `
<svg viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
<path d="M16 1H4C2.9 1 2 1.9 2 3V17H4V3H16V1ZM19 5H8C6.9 5 6 5.9 6 7V21C6 22.1 6.9 23 8 23H19C20.1 23 21 22.1 21 21V7C21 5.9 20.1 5 19 5ZM19 21H8V7H19V21Z"/>
</svg>
`;

/**
 * A custom hook to detect if viewport width < some breakpoint (mobile).
 */
function useIsMobile(breakpoint = 576) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < breakpoint);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < breakpoint);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [breakpoint]);

  return isMobile;
}

/** Shorten address to 0x123..abc form */
function superShortAddress(addr = "") {
  if (!addr.startsWith("0x") || addr.length < 10) return addr;
  return addr.slice(0, 5) + ".." + addr.slice(-3);
}

function DefiHolders() {
  const [holders, setHolders] = useState([]);
  const [chartTop10, setChartTop10] = useState([]);
  const [top50, setTop50] = useState([]);
  // default => false => exclude contracts
  const [showContracts, setShowContracts] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Detect if mobile
  const isMobile = useIsMobile(576);

  useEffect(() => {
    fetchHolders();
  }, []);

  async function fetchHolders() {
    try {
      const resp = await fetch("/holders.json");
      if (!resp.ok) {
        throw new Error(`HTTP error: ${resp.status}`);
      }
      let data = await resp.json();

      // convert addresses to lowercase
      data.forEach(item => {
        item.address = item.address.toLowerCase();
      });

      // sort descending
      data.sort((a, b) => parseFloat(b.balance) - parseFloat(a.balance));

      setHolders(data);
      setLoading(false);

      // default => showContracts=false
      updateData(data, false);
    } catch (err) {
      console.error("Fetch error:", err);
      setError(err.message);
      setLoading(false);
    }
  }

  // filter & compute top10 + top50
  function updateData(all, withContracts) {
    let filtered = all;
    if (!withContracts) {
      filtered = filtered.filter(h => {
        const sp = specialAddresses[h.address];
        return !(sp && sp.isContract);
      });
    }
    setChartTop10(filtered.slice(0, 10));
    setTop50(filtered.slice(0, 50));
  }

  function toggleContracts(newVal) {
    setShowContracts(newVal);
    updateData(holders, newVal);
  }

  // Build ECharts config
  function getChartOption() {
    // y-axis data
    const yAxisData = chartTop10.map(h => superShortAddress(h.address));
    // bars
    const barData = chartTop10.map(h => {
      const bal = parseFloat(h.balance) || 0;
      return ((bal / TOTAL_SUPPLY) * 100).toFixed(2);
    });

    // More left grid on mobile => "30%"
    const leftGrid = isMobile ? "30%" : "10%";
    // smaller y-axis font if mobile
    const labelFontSize = isMobile ? 12 : 14;

    return {
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "shadow" },
        confine: true, // keep tooltip within chart
        formatter: (params) => {
          const item = params[0];
          const idx = item.dataIndex;
          const holder = chartTop10[idx];
          const sp = specialAddresses[holder.address];

          let labelExtras = "";
          if (sp && sp.isContract) {
            labelExtras = `<br/><em style="color:#0bf;">${sp.label}</em><br/>${sp.hover || ""}`;
          }

          const copyIcon = `<img src="data:image/svg+xml;base64,${btoa(copyIconSVG)}"
             style="width:14px;height:14px;cursor:pointer;margin-left:4px;"
             onclick="navigator.clipboard.writeText('${holder.address}')"
             />`;

          return `
            <strong>${superShortAddress(holder.address)}</strong>${copyIcon}
            ${labelExtras}
            <br/>
            Balance: ${(+holder.balance).toLocaleString(undefined,{maximumFractionDigits:2})}
            <br/>
            Percentage: ${item.value}%
          `;
        }
      },
      grid: {
        left: leftGrid,
        right: "10%",
        top: 30,
        bottom: 30
      },
      xAxis: {
        type: "value",
        axisLabel: { color: "#fff" },
        splitLine: { show: false }
      },
      yAxis: {
        type: "category",
        inverse: true,
        data: yAxisData,
        axisLabel: {
          color: "#fff",
          fontSize: labelFontSize,
          overflow: "truncate"
        },
        axisLine: { show: false },
        axisTick: { show: false }
      },
      series: [
        {
          type: "bar",
          data: barData,
          itemStyle: {
            color: getNeonGradient(),
            borderRadius: [6, 6, 6, 6]
          },
          label: {
            show: true,
            position: "right",
            formatter: "{c}%",
            color: "#fff",
            fontSize: labelFontSize
          }
        }
      ]
    };
  }

  if (loading) {
    return (
      <div style={styles.page}>
        <h3 style={{ color: "#fff" }}>Loading holders...</h3>
      </div>
    );
  }
  if (error) {
    return (
      <div style={styles.page}>
        <h3 style={{ color: "red" }}>{error}</h3>
      </div>
    );
  }

  return (
    <div style={styles.page}>
      {/* Banner */}
      <div style={styles.bannerWrapper}>
        <img src={headerImage} alt="DefiHolders Banner" style={styles.banner} />
      </div>

      {/* Chart container */}
      <div style={styles.chartContainer}>
        <div style={styles.topRow}>
          <h3 style={styles.top10Title}>Top 10</h3>
          <div style={styles.buttonsRow}>
            <button
              style={{
                ...styles.btnStyle,
                ...(isMobile ? styles.btnMobile : {}),
                background: showContracts ? "#7A36FF" : "#444"
              }}
              onClick={() => toggleContracts(true)}
            >
              Contracts
            </button>
            <button
              style={{
                ...styles.btnStyle,
                ...(isMobile ? styles.btnMobile : {}),
                background: !showContracts ? "#7A36FF" : "#444"
              }}
              onClick={() => toggleContracts(false)}
            >
              Holders
            </button>
          </div>
        </div>
        <div style={styles.chartBg}>
          <ReactECharts
            option={getChartOption()}
            style={{ width: "100%", height: 400 }}
          />
        </div>
      </div>

      {/* Top 50 Table */}
      <div style={styles.tableContainer}>
        <h3 style={{ ...styles.sectionTitle, color: "#fff" }}>Top 50</h3>
        {/* Force horizontal scrollbar */}
        <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
          <table style={styles.table}>
            <thead>
              <tr style={styles.tableHeaderRow}>
                <th style={{ ...styles.tableHeaderCell, color: "#ff66cc" }}>Rank</th>
                <th style={{ ...styles.tableHeaderCell, color: "#ff66cc" }}>Address</th>
                <th style={{ ...styles.tableHeaderCell, color: "#ff66cc" }}>Balance</th>
                <th style={{ ...styles.tableHeaderCell, color: "#ff66cc" }}>% Tokens</th>
              </tr>
            </thead>
            <tbody>
              {top50.map((h, idx) => {
                const rowBg = idx % 2 === 0 ? "#151929" : "#0B0D21";
                const bal = (+h.balance).toLocaleString(undefined, { maximumFractionDigits: 2 });
                const pct = ((+h.balance / TOTAL_SUPPLY) * 100).toFixed(2);

                const sp = specialAddresses[h.address];
                const isContract = sp && sp.isContract;
                const showLabel = isContract && showContracts;

                const displayedText = showLabel
                  ? sp.label
                  : superShortAddress(h.address);

                // gradient for addresses
                const gradientAddrStyle = {
                  background: "linear-gradient(90deg, #7A36FF, #00E5FF)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  cursor: "pointer",
                  fontWeight: "bold",
                  whiteSpace: "nowrap"
                };
                // gradient for %
                const gradientPctStyle = {
                  background: "linear-gradient(90deg, #7A36FF, #00E5FF)",
                  WebkitBackgroundClip: "text",
                  color: "transparent",
                  fontWeight: "bold",
                  whiteSpace: "nowrap"
                };

                // We'll define separate styles for mobile columns to add extra spacing:
                const tdRankStyles = isMobile ? { ...styles.td, ...styles.tdMobileRank } : styles.td;
                const tdAddrStyles = isMobile ? { ...styles.td, ...styles.tdMobileAddr } : styles.td;
                const tdBalStyles = isMobile ? { ...styles.td, ...styles.tdMobileBal } : styles.td;
                const tdPctStyles = isMobile ? { ...styles.td, ...styles.tdMobilePct } : styles.td;

                return (
                  <tr key={idx} style={{ backgroundColor: rowBg, color: "#fff" }}>
                    <td style={tdRankStyles}>{idx + 1}</td>
                    <td style={tdAddrStyles}>
                      {/* inline-flex so icon stays on same line */}
                      <span style={{ display: "inline-flex", alignItems: "center", whiteSpace: "nowrap" }}>
                        <span
                          style={gradientAddrStyle}
                          title={h.address}
                          onClick={() => navigator.clipboard.writeText(h.address)}
                        >
                          {displayedText}
                        </span>
                        <img
                          src={`data:image/svg+xml;base64,${btoa(copyIconSVG)}`}
                          alt="Copy"
                          style={{
                            width: "14px",
                            height: "14px",
                            cursor: "pointer",
                            marginLeft: "5px"
                          }}
                          onClick={() => navigator.clipboard.writeText(h.address)}
                        />
                      </span>
                    </td>
                    <td style={tdBalStyles}>{bal}</td>
                    <td style={tdPctStyles}>
                      <span style={gradientPctStyle}>{pct}%</span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

// CSS styles
const styles = {
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#0B0D21",
    minHeight: "100vh",
    padding: "20px"
  },
  bannerWrapper: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px"
  },
  banner: {
    maxWidth: "100%",
    borderRadius: "8px",
    boxShadow: "0 4px 12px rgba(0,0,0,0.6)"
  },
  chartContainer: {
    backgroundColor: "#111415",
    borderRadius: "8px",
    padding: "16px",
    marginBottom: "20px",
    border: "1px solid #2A2E5A"
  },
  topRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10px"
  },
  top10Title: {
    margin: 0,
    color: "#fff",
    fontSize: "18px",
    fontWeight: "bold"
  },
  buttonsRow: {
    display: "flex",
    gap: "10px"
  },
  btnStyle: {
    border: "none",
    color: "#fff",
    padding: "8px 16px",
    borderRadius: "6px",
    cursor: "pointer",
    fontWeight: "bold"
  },
  btnMobile: {
    padding: "4px 8px",
    fontSize: "12px"
  },
  chartBg: {
    width: "100%",
    height: "400px",
    backgroundImage: `linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0.2)), url(${chartBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center"
  },
  tableContainer: {
    backgroundColor: "#111415",
    borderRadius: "8px",
    padding: "16px",
    border: "1px solid #2A2E5A"
  },
  sectionTitle: {
    margin: "0 0 10px 0",
    fontSize: "18px"
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    // Make sure table doesn't wrap text (we already do whiteSpace=nowrap in cells if needed)
  },
  tableHeaderRow: {
    backgroundColor: "#1E2240"
  },
  tableHeaderCell: {
    textAlign: "left",
    padding: "8px",
    fontSize: "14px",
    borderBottom: "1px solid #333",
    whiteSpace: "nowrap"
  },
  td: {
    padding: "8px",
    fontSize: "13px",
    borderBottom: "1px solid #333"
  },

  // Extra spacing on mobile for each column
  tdMobileRank: {
    minWidth: "40px"
  },
  tdMobileAddr: {
    minWidth: "120px"
  },
  tdMobileBal: {
    minWidth: "90px"
  },
  tdMobilePct: {
    minWidth: "60px"
  }
};

export default DefiHolders;
