import React, { useState, useContext, useEffect } from "react";
import "./index.css";
import Step1 from "./fairsale/Step1";
import Step2 from "./fairsale/Step2";
import Step3 from "./fairsale/Step3";
import Step4 from "./fairsale/Step4";
import Step5 from "./fairsale/Step5";
import Context from "./fairsale/context/Context";
import { useWeb3React } from "@web3-react/core";
import { defaultValue } from "./fairsale/context/defaults";
import { useNavigate } from "react-router-dom";
import FairLaunchIcon from '../../images/FairLaunchIcon.svg';
import { useAccount, useChainId } from "wagmi";

export default function Fairsale() {
  const appContext = useContext(Context);
  const [context, setContext] = useState(appContext);
  const chainId = useChainId();
  const { address: account } = useAccount();
  const navigate = useNavigate();
  const [wobble, setWobble] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [fadeIn, setFadeIn] = useState(true);

  const setValue = (value) => {
    setContext({ ...context, value });
  };

  useEffect(() => {
    setContext({ ...context, value: { ...defaultValue } });
  }, [account]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setWobble(true);
      setTimeout(() => setWobble(false), 500);
    }, 3500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setFadeIn(true); // Trigger fade-in animation when step changes
  }, [context.value.step]);

  const btnNextStep = (e) => {
    if (context.value.step < context.value.maxStep) {
      setFadeIn(false);
      setTimeout(() => {
        setValue({ ...context.value, step: parseInt(context.value.step + 1) });
      }, 300);
    }
  };

  const btnPrevStep = (e) => {
    if (context.value.step > 1) {
      setFadeIn(false);
      setTimeout(() => {
        setValue({ ...context.value, step: parseInt(context.value.step - 1) });
      }, 300);
    }
  };

  const state = {
    ...context,
    setValue: setValue,
    btnNextStep: btnNextStep,
    btnPrevStep: btnPrevStep,
  };

  const containerStyle = {
    width: '100%',
    maxWidth: '1300px',
    margin: '0 auto',
    padding: '20px',
  };

  const headerContainerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '24px',
    marginTop: '24px',
  };

  const leftHeaderStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  };

  const logoStyle = {
    width: '35px',
    height: '35px',
  };

  const headerStyle = {
    color: '#fff',
    fontSize: '24px',
    fontFamily: 'Inter, sans-serif',
    fontWeight: '600',
    margin: '0',
  };

  const subHeaderStyle = {
    color: '#7B91B0',
    fontSize: '14px',
    fontFamily: 'Quicksand, sans-serif',
    marginTop: '4px',
    margin: '0',
  };

  const pumpButtonStyle = {
    background: 'linear-gradient(90deg, #6700FF 0%, #21A595 100%)', 
    padding: '10px 20px',
    borderRadius: '8px',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    fontFamily: 'Quicksand, sans-serif',
    fontSize: 11.83,
    fontWeight: '700',
    whiteSpace: 'nowrap',
  };

  const contentStyle = {
    background: '#111432',
    border: '1px solid #075985',
    borderRadius: '8px',
    overflow: 'hidden',
  };

  const stepContentStyle = {
    opacity: fadeIn ? 1 : 0,
    transition: 'opacity 0.3s ease-in-out',
  };

  // Mobile styles
  const mobileContainerStyle = {
    width: '100%',
    padding: '16px',
  };

  const mobileHeaderContainerStyle = {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    marginBottom: '20px',
  };

  const mobileHeaderStyle = {
    fontSize: '20px',
  };

  const mobileSubHeaderStyle = {
    fontSize: '12px',
    marginTop: '4px',
  };

  const mobileContentStyle = {
    padding: 0,
  };

  // Function to render current step with animation
  const renderCurrentStep = () => {
    const steps = {
      1: <Step1 />,
      2: <Step2 />,
      3: <Step3 />,
      4: <Step4 />,
      5: <Step5 />,
    };

    return (
      <div style={stepContentStyle}>
        {steps[context.value.step]}
      </div>
    );
  };

  return (
    <Context.Provider value={state}>


      <div style={isMobile ? mobileContainerStyle : containerStyle}>
        <div style={isMobile ? mobileHeaderContainerStyle : headerContainerStyle}>
          <div style={leftHeaderStyle}>
            <img src={FairLaunchIcon} alt="Logo" style={logoStyle} />
            <div>
              <h1 style={isMobile ? { ...headerStyle, ...mobileHeaderStyle } : headerStyle}>
                Create a Fair Sale
              </h1>
              <p style={isMobile ? { ...subHeaderStyle, ...mobileSubHeaderStyle } : subHeaderStyle}>
                Empower your community with transparent crowdfunding, automated token distribution, 
                LP locking and DEX listing.
              </p>
            </div>
          </div>
          {!isMobile && (
            <button 
              className={wobble ? "wobble-animation" : ""} 
              style={pumpButtonStyle}
              onClick={() => navigate('/bonding-token-sale')}
            >
              CREATE A PUMP SALE HERE
            </button>
          )}
        </div>



        <div style={isMobile ? { ...contentStyle, ...mobileContentStyle } : contentStyle}>
          <div className="tab-content" id="main_form">
            {renderCurrentStep()}
          </div>
        </div>
      </div>

                    {/* CONTINUOUS SCROLLING MARQUEE WITH TINY LOOP GAP */}
                    <div
        style={{
          width: "95%",
          backgroundColor: "transparent",
          color: "#C21E56",
          padding: "10px",
          borderRadius: "8px",
          marginLeft: "6px",
          marginRight: "6px",

          overflow: "hidden",
          marginBottom: "16px",
        }}
      >
        <marquee
          behavior="scroll"
          direction="left"
          scrollAmount="6"
          scrolldelay="0"
          loop="infinite"
          style={{
            whiteSpace: "nowrap",
            margin: 0,
            padding: 0,
          }}
        >
          Use Metamask for the best user experience. Coinbase Wallet currently gives Gas Estimate errors but still works.
          &nbsp;&nbsp;&nbsp;
          Use Metamask for the best user experience. Coinbase Wallet currently gives Gas Estimate errors but still works.
          &nbsp;&nbsp;
        </marquee>
      </div>
    </Context.Provider>
  );
}
