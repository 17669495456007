// BuyDefiButton.js
import React from 'react';

const BuyDefiButton = ({ isMobile }) => {
  const handleClick = () => {
    window.open(
      'https://app.uniswap.org/swap?chain=base&inputCurrency=ETH&outputCurrency=0x473995e934C5E871aF24D6A1daBcBb0c7e89a896',
      '_blank'
    );
  };

  // If isMobile is true, show "BUY". Otherwise, "BUY $DEFI".
  const buttonLabel = isMobile ? "BUY" : "BUY $DEFI";

  return (
    <div className="buy-defi-container" style={{ display: 'inline-block' }}>
      <button 
        onClick={handleClick}
        className="buy-defi-button"
      >
        {buttonLabel}
      </button>
      <style jsx>{`
        @keyframes glowing {
          0% {
            box-shadow: 0 0 5px #6366f1, 0 0 10px #6366f1, 0 0 12px #8b5cf6;
          }
          50% {
            box-shadow: 0 0 7px #6366f1, 0 0 15px #6366f1, 0 0 15px #8b5cf6;
          }
          100% {
            box-shadow: 0 0 5px #6366f1, 0 0 10px #6366f1, 0 0 12px #8b5cf6;
          }
        }

        .buy-defi-button {
          background: #3461FF;
          color: #FFFFFF;
          padding: 6px 12px;
          font-size: 12px;
          font-weight: normal;
          border-radius: 8px;
          height: 32px;
          border: 0px solid transparent;
          cursor: pointer;
          margin-left: 15px; /* smaller left margin to reduce spacing */
          transition: all 0.3s ease;
          font-family: 'Quicksand', sans-serif; 
        }

        /* Glow ONLY on hover */
        .buy-defi-button:hover {
          animation: glowing 2s infinite;
        }
      `}</style>
    </div>
  );
};

export default BuyDefiButton;
