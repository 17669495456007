// BnbWidget.js
import React, { useEffect, useState } from "react";
import BnbLogo from "../images/bnb.png"; // Adjust path if needed

function BnbWidget() {
  const [price, setPrice] = useState(null);    // The displayed price (cached or fresh)
  const [isLive, setIsLive] = useState(false); // True if we got a fresh fetch
  const [error, setError] = useState(null);

  useEffect(() => {
    // 1) On mount, read last known price from localStorage
    const cachedPrice = localStorage.getItem("bnbPriceCache");
    if (cachedPrice) {
      // parse & show the last known price
      setPrice(Number(cachedPrice));
      setIsLive(false); // we haven't fetched fresh data yet
    }

    // 2) Attempt a fresh fetch
    const fetchBnbData = async () => {
      try {
        const url = "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd";
        const resp = await fetch(url);
        if (!resp.ok) throw new Error(`BNB fetch error: ${resp.status}`);

        const data = await resp.json();
        if (!data.binancecoin) throw new Error("No BNB data from CoinGecko");

        const { usd } = data.binancecoin;
        // If success, store fresh price
        setPrice(usd);
        setIsLive(true);
        setError(null);

        // Save the new price to localStorage
        localStorage.setItem("bnbPriceCache", String(usd));
      } catch (err) {
        // If error, we keep the old price (if any).
        // We'll show a red dot to indicate the data isn't live
        setIsLive(false);
        setError(err.message || "BNB fetch error");
      }
    };

    fetchBnbData();
  }, []);

  // 3) If there's truly no price (no cache, no fetch), show a minimal fallback
  if (price === null) {
    return (
      <div style={styles.container}>
        <div style={styles.dotRed} />
        <div style={styles.widget}>
          <span style={{ fontSize: 12, color: "#ff4d4f" }}>No Data</span>
        </div>
      </div>
    );
  }

  // 4) We have either a cached or fresh price
  // Round to no decimals
  const displayPrice = Math.round(price);

  return (
    <div style={styles.container}>
      {/* Dot: green if we have a live fetch, red if using a cached price or error */}
      <div style={isLive ? styles.dotGreen : styles.dotRed} />

      <div style={styles.widget}>
        <img
          src={BnbLogo}
          alt="BNB"
          style={{ width: 16, height: 16, marginRight: 5,  marginLeft: -3, }}
        />
        <span style={{ fontSize: 12, marginRight: 5, color: "#cccccc" }}>
          ${displayPrice}
        </span>
      </div>
    </div>
  );
}

export default BnbWidget;

const styles = {
  container: {
    position: "relative",
  },
  widget: {
    width: 70,
    height: 32,
    borderRadius: 8,
    border: "1px solid #4DFFFFFF",
    background: "linear-gradient(180deg, #161A42 0%, #161A42 0%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Quicksand, sans-serif",
    color: "#FFFFFF",
    marginRight: "7px",
  },
  // The green dot if data is live
  dotGreen: {
    position: "absolute",
    top: 5,
    right: 12,
    width: 4,
    height: 4,
    borderRadius: "50%",
    backgroundColor: "#57d873",         // calmer green shade
    boxShadow: "0 0 6px #57d873", 
  },
  // The red dot if using cached data or error
  dotRed: {
    position: "absolute",
    top: 5,
    right: 12,
    width: 4,
    height: 4,
    borderRadius: "50%",
    backgroundColor: "#00ff00",
  },
};
