import React, { useState, useMemo, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';
import queryString from 'query-string';

import logo from '../images/logo_plain.png';
import logoIcon from '../images/DEFI-logo.png';
import LaunchpadIcon from '../images/LaunchpadIcon.png';
import SwapIcon from '../images/SwapIcon.svg';
import BridgeIcon from '../images/BridgeIcon.svg';
import PortfolioIcon from '../images/PortfolioIcon.png';
import SocialsIcon from '../images/SocialsIcon.svg';
import CreateIcon from '../images/CreateIcon.svg';
import CreateTokenIcon from '../images/CreateTokenIcon.png';
import FairLaunchIcon from '../images/FairLaunchIcon.svg';
import BondingCurveIcon from '../images/BondingCurveIcon.svg';
import AirdropIcon from '../images/AirdropIcon.svg';
import LockIcon from '../images/LockIcon.svg';
import StakingIcon from '../images/StakingIcon.png';
import HelpVideosIcon from '../images/HelpVideosIcon.svg';
import ViewIcon from '../images/ViewIcon.svg';
import HelpIcon from '../images/HelpIcon.svg';
import SolanaIcon from '../images/solanaicon.png';
import DefiMenuIcon from '../images/DefiMenuIcon.png';

/* 
  Same "useRouter" helper:
*/
export function useRouter() {
  const params = useParams();
  const location = useLocation();
  return useMemo(() => {
    return {
      push: location.push,
      replace: location.replace,
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params,
      },
      location,
    };
  }, [params, location]);
}

/* 
  If needed, still can include the GoogleFonts.
*/
function GoogleFontsLink() {
  return (
    <link
      href="https://fonts.googleapis.com/css2?family=Quicksand:wght@600&display=swap"
      rel="stylesheet"
    />
  );
}

/* 
  All inline style objects:
*/
const styles = {
  // The main sidebar container (desktop)
  sidebarWrapper: (collapsed) => ({
    fontFamily: "'Quicksand', sans-serif",
    fontWeight: 600,
    backgroundColor: '#161A42',
    color: '#808080',
    height: '100vh',
    position: 'relative',
    // For desktop: 72px or 220px
    width: collapsed ? '72px' : '220px',
    transition: 'width 0.2s ease',
    zIndex: 1,
    overflow: 'hidden',
    overflowY: 'auto',
  }),
  // The interior container
  sidebarInner: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  // The header area
  sidebarHeader: (collapsed) => ({
    display: 'flex',
    alignItems: 'center',
    padding: '12px 16px',
    justifyContent: collapsed ? 'center' : 'flex-start',
    backgroundColor: '#161A42',
    borderBottom: '1px solid rgba(86, 32, 217, 0.2)',
  }),
  logoImage: {
    height: 'auto',
    width: 'auto',
    maxHeight: '40px',
    objectFit: 'contain',
    transition: 'all 0.2s ease',
  },

  // The main UL for menu
  menuList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },

  // A header item (like "VIEW", "CREATE"):
  menuHeader: {
    textTransform: 'uppercase',
    fontSize: '16px',
    fontWeight: 400,
    color: '#ffffff',
    margin: '23px 0',
    padding: '6px 20px',
    display: 'flex',
    alignItems: 'center',
    gap: '18px',
    cursor: 'default',
    background: 'rgba(86, 32, 217, 0.1)',
    borderRadius: '5px',
  },

  // A single menu item (li)
  menuItem: (active, disabled) => ({
    fontSize: '16px',
    color: disabled ? '#999999' : '#cccccc',
    cursor: disabled ? 'default' : 'pointer',
    margin: '0 0 10px 0',
    transition: 'background-color 0.2s ease',
    borderRadius: '5px',
    display: 'block',
    backgroundColor: active ? '#36384D' : 'transparent',
  }),
  // The link inside the item
  menuItemLink: (disabled, active) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '6px 20px',
    color: disabled ? '#999999' : '#cccccc',
    textDecoration: 'none',
    ...(active && { color: '#fff' }),
  }),

  // Submenu container
  subMenuItem: {
    margin: '0 0 10px 0',
    borderRadius: '5px',
    overflow: 'hidden',
  },
  subMenuTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '6px 20px',
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'background-color 0.2s ease',
  },
  subMenuLabel: {
    fontSize: '16px',
    color: '#cccccc',
  },
  subMenuList: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    backgroundColor: '#161A42',
  },
  subMenuListItemLink: {
    display: 'block',
    padding: '6px 40px',
    textDecoration: 'none',
    color: '#cccccc',
    fontSize: '15px',
    transition: 'background-color 0.2s ease',
  },

  // Divider
  divider: {
    margin: '5px 0',
    borderColor: 'rgba(86, 32, 217, 0.2)',
    borderWidth: '1px 0 0 0',
  },

  // Mobile overlay (gray backdrop)
  mobileOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },

  // The actual mobile drawer.
  // KEY: we use 100vw instead of 320px to never exceed device width.
  mobileMenuWrapper: (open) => ({
    width: '100vw', // fill entire screen width
    maxWidth: '320px', // but won't exceed 320px on bigger devices
    height: '100vh',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1000,
    backgroundColor: '#161A42',
    overflowY: 'auto',
    overflowX: 'hidden',
    transform: open ? 'translateX(0)' : 'translateX(-110%)',
    transition: 'transform 0.3s ease',
  }),
  mobileCloseButton: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    background: 'none',
    border: 'none',
    color: '#ffffff',
    cursor: 'pointer',
    zIndex: 1001,
    padding: '8px',
  },

  // Desktop toggle button (the circle with arrow)
  toggleButton: (collapsed) => ({
    position: 'fixed',
    top: '90px',
    left: collapsed ? '65px' : '220px',
    cursor: 'pointer',
    zIndex: 99999999,
    background: '#252638',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'left 0.2s ease',
  }),
};

/**
 * The inline-style Sidebar
 */
export default function SidebarInline({ open, setOpen }) {
  const router = useRouter();
  const [width, setWidth] = useState(window.innerWidth);
  const [collapsed, setCollapsed] = useState(true);

  const isMobile = width <= 575;
  const [defiOpen, setDefiOpen] = useState(false);
  const [lockOpen, setLockOpen] = useState(false);
  const [socialsOpen, setSocialsOpen] = useState(false);
  const [helpOpen, setHelpOpen] = useState(false);

  // track window size
  useEffect(() => {
    const handleWindowSizeChange = () => setWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  // auto-collapse after 19s if desktop
  useEffect(() => {
    if (!isMobile && !collapsed) {
      const t = setTimeout(() => setCollapsed(true), 19000);
      return () => clearTimeout(t);
    }
  }, [collapsed, isMobile]);

  // if mobile + open => uncollapse
  useEffect(() => {
    if (isMobile && open) {
      setCollapsed(false);
    }
  }, [isMobile, open]);

  const onClickMenuItem = () => {
    // close the menu on mobile
    if (isMobile) {
      setOpen(false);
    }
  };

  // Submenu toggles
  const toggleDefi = () => {
    if (collapsed) {
      setCollapsed(false);
      setDefiOpen(true);
    } else {
      setDefiOpen((prev) => !prev);
    }
  };
  const toggleLock = () => {
    if (collapsed) {
      setCollapsed(false);
      setLockOpen(true);
    } else {
      setLockOpen((prev) => !prev);
    }
  };
  const toggleSocials = () => {
    if (collapsed) {
      setCollapsed(false);
      setSocialsOpen(true);
    } else {
      setSocialsOpen((prev) => !prev);
    }
  };
  const toggleHelp = () => {
    if (collapsed) {
      setCollapsed(false);
      setHelpOpen(true);
    } else {
      setHelpOpen((prev) => !prev);
    }
  };

  const renderContent = (
    <div style={styles.sidebarWrapper(collapsed)}>
      <GoogleFontsLink />
      <div style={styles.sidebarInner}>
        {/* Header */}
        <div style={styles.sidebarHeader(collapsed)}>
          <img
            src={collapsed ? logoIcon : logo}
            alt="Brand Logo"
            style={styles.logoImage}
          />
        </div>

        <ul style={styles.menuList}>
          {/* VIEW HEADER */}
          <li style={styles.menuHeader}>
            <img src={ViewIcon} alt="" style={{ width: '27px', height: '27px' }} />
            {!collapsed && 'VIEW'}
          </li>

          {/* Launchpad */}
          <li
            style={styles.menuItem(router.pathname === '/', false)}
            className={router.pathname === '/' ? 'active' : ''}
          >
            <Link
              to="/"
              style={styles.menuItemLink(false, router.pathname === '/')}
              onClick={onClickMenuItem}
            >
              <img src={LaunchpadIcon} alt="" style={{ width: '1.5em', height: '1.5em' }} />
              {!collapsed && 'Launchpad'}
            </Link>
          </li>

          {/* Sol Launch */}
          <li style={styles.menuItem(false, false)}>
            <a
              href="https://solana.defilaunch.app/"
              target="_blank"
              rel="noopener noreferrer"
              style={styles.menuItemLink(false, false)}
              onClick={onClickMenuItem}
            >
              <img src={SolanaIcon} alt="" style={{ width: '1.5em', height: '1.5em' }} />
              {!collapsed && 'Sol Launch'}
            </a>
          </li>

          {/* $DEFI 🔥 submenu */}
          <li style={styles.subMenuItem}>
            <div style={styles.subMenuTitle} onClick={toggleDefi}>
              <img src={DefiMenuIcon} alt="" style={{ width: '1.5em', height: '1.5em' }} />
              {!collapsed && <span style={styles.subMenuLabel}>$DEFI 🔥</span>}
            </div>
            {!collapsed && defiOpen && (
              <ul style={styles.subMenuList}>
                <li onClick={onClickMenuItem}>
                  <a
                    href="https://app.uniswap.org/swap?chain=base&inputCurrency=ETH&outputCurrency=0x473995e934C5E871aF24D6A1daBcBb0c7e89a896"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.subMenuListItemLink}
                  >
                    Buy
                  </a>
                </li>
                <li onClick={onClickMenuItem}>
                  <a
                    href="https://dexscreener.com/base/0x3faba4adb18a363ce356c0fd32919e642570a42d"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.subMenuListItemLink}
                  >
                    Chart
                  </a>
                </li>
                <li onClick={onClickMenuItem}>
                  <Link to="/defi-holders" style={styles.subMenuListItemLink}>
                    Holders
                  </Link>
                </li>
                <li onClick={onClickMenuItem}>
                  <Link to="/base-token-tracker" style={styles.subMenuListItemLink}>
                    Tracker
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* Staking */}
          <li
            style={styles.menuItem(router.pathname === '/staking', false)}
            className={router.pathname === '/staking' ? 'active' : ''}
          >
            <Link
              to="/staking"
              style={styles.menuItemLink(false, router.pathname === '/staking')}
              onClick={onClickMenuItem}
            >
              <img src={StakingIcon} alt="" style={{ width: '1.5em', height: '1.5em' }} />
              {!collapsed && 'Staking'}
            </Link>
          </li>

          {/* Portfolio */}
          <li
            style={styles.menuItem(router.pathname === '/wallet', false)}
            className={router.pathname === '/wallet' ? 'active' : ''}
          >
            <Link
              to="/wallet"
              style={styles.menuItemLink(false, router.pathname === '/wallet')}
              onClick={onClickMenuItem}
            >
              <img src={PortfolioIcon} alt="" style={{ width: '21px', height: '21px' }} />
              {!collapsed && 'Portfolio'}
            </Link>
          </li>

          <hr style={styles.divider} />

          {/* CREATE HEADER */}
          <li style={styles.menuHeader}>
            <img src={CreateIcon} alt="" style={{ width: '27px', height: '27px' }} />
            {!collapsed && 'CREATE'}
          </li>

          <li
            style={styles.menuItem(router.pathname === '/token', false)}
            className={router.pathname === '/token' ? 'active' : ''}
          >
            <Link
              to="/token/Tax"
              style={styles.menuItemLink(false, router.pathname === '/token')}
              onClick={onClickMenuItem}
            >
              <img src={CreateTokenIcon} alt="" style={{ width: '1.5em', height: '1.5em' }} />
              {!collapsed && 'Meme Coin 🔥'}
            </Link>
          </li>

          <li
            style={styles.menuItem(router.pathname === '/fairlaunch', false)}
            className={router.pathname === '/fairlaunch' ? 'active' : ''}
          >
            <Link
              to="/fairlaunch"
              style={styles.menuItemLink(false, router.pathname === '/fairlaunch')}
              onClick={onClickMenuItem}
            >
              <img src={FairLaunchIcon} alt="" style={{ width: '1.5em', height: '1.5em' }} />
              {!collapsed && 'Fair Launch'}
            </Link>
          </li>

          <li
            style={styles.menuItem(router.pathname === '/bonding-token-sale', false)}
            className={router.pathname === '/bonding-token-sale' ? 'active' : ''}
          >
            <Link
              to="/bonding-token-sale"
              style={styles.menuItemLink(false, router.pathname === '/bonding-token-sale')}
              onClick={onClickMenuItem}
            >
              <img src={BondingCurveIcon} alt="" style={{ width: '1.5em', height: '1.5em' }} />
              {!collapsed && 'Pump Launch'}
            </Link>
          </li>

          <li
            style={styles.menuItem(router.pathname === '/airdrop-tokens', false)}
            className={router.pathname === '/airdrop-tokens' ? 'active' : ''}
          >
            <Link
              to="/airdrop-tokens"
              style={styles.menuItemLink(false, router.pathname === '/airdrop-tokens')}
              onClick={onClickMenuItem}
            >
              <img src={AirdropIcon} alt="" style={{ width: '1.5em', height: '1.5em' }} />
              {!collapsed && 'Airdrop'}
            </Link>
          </li>

          {/* LOCK submenu */}
          <li style={styles.subMenuItem}>
            <div style={styles.subMenuTitle} onClick={toggleLock}>
              <img src={LockIcon} alt="" style={{ width: '1.5em', height: '1.5em' }} />
              {!collapsed && <span style={styles.subMenuLabel}>Lock</span>}
            </div>
            {!collapsed && lockOpen && (
              <ul style={styles.subMenuList}>
                <li onClick={onClickMenuItem}>
                  <Link to="/lock" style={styles.subMenuListItemLink}>
                    Create
                  </Link>
                </li>
                <li onClick={onClickMenuItem}>
                  <Link to="/token-locked" style={styles.subMenuListItemLink}>
                    Token List
                  </Link>
                </li>
                <li onClick={onClickMenuItem}>
                  <Link to="/liquidity-locked" style={styles.subMenuListItemLink}>
                    LP List
                  </Link>
                </li>
              </ul>
            )}
          </li>

          <hr style={styles.divider} />

          {/* HELP HEADER */}
          <li style={styles.menuHeader}>
            <img src={HelpIcon} alt="" style={{ width: '22px', height: '22px' }} />
            {!collapsed && 'HELP'}
          </li>

          {/* Socials => submenu */}
          <li style={styles.subMenuItem}>
            <div style={styles.subMenuTitle} onClick={toggleSocials}>
              <img src={SocialsIcon} alt="" style={{ width: '1.5em', height: '1.5em' }} />
              {!collapsed && <span style={styles.subMenuLabel}>Socials</span>}
            </div>
            {!collapsed && socialsOpen && (
              <ul style={styles.subMenuList}>
                <li onClick={onClickMenuItem}>
                  <a
                    href="https://discord.com/invite/TsvvJUS58m"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.subMenuListItemLink}
                  >
                    Discord
                  </a>
                </li>
                <li onClick={onClickMenuItem}>
                  <a
                    href="https://twitter.com/defilaunchapp"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.subMenuListItemLink}
                  >
                    Twitter
                  </a>
                </li>
                <li onClick={onClickMenuItem}>
                  <a
                    href="https://t.me/defilaunchapp"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.subMenuListItemLink}
                  >
                    Telegram
                  </a>
                </li>
                <li onClick={onClickMenuItem}>
                  <a
                    href="https://warpcast.com/~/invite-page/398484?id=e17eaba6"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.subMenuListItemLink}
                  >
                    Warpcast
                  </a>
                </li>
              </ul>
            )}
          </li>

          {/* Help => submenu */}
          <li style={styles.subMenuItem}>
            <div style={styles.subMenuTitle} onClick={toggleHelp}>
              <img src={HelpVideosIcon} alt="" style={{ width: '1.5em', height: '1.5em' }} />
              {!collapsed && <span style={styles.subMenuLabel}>Help</span>}
            </div>
            {!collapsed && helpOpen && (
              <ul style={styles.subMenuList}>
                <li onClick={onClickMenuItem}>
                  <a
                    href="https://www.youtube.com/channel/UC6khIxCjTEAQc_YqYpMxTVg"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.subMenuListItemLink}
                  >
                    Videos
                  </a>
                </li>
                <li onClick={onClickMenuItem}>
                  <a
                    href="https://defilaunchapp.gitbook.io/docs"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={styles.subMenuListItemLink}
                  >
                    Docs
                  </a>
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <>
      {/* Desktop toggle button */}
      {!isMobile && (
        <div
          style={styles.toggleButton(collapsed)}
          onClick={() => setCollapsed(!collapsed)}
        >
          {collapsed ? (
            <MdKeyboardArrowRight size={16} color="#ccc" />
          ) : (
            <MdKeyboardArrowLeft size={16} color="#ccc" />
          )}
        </div>
      )}

      {/* Mobile overlay */}
      {isMobile && open && (
        <div
          style={styles.mobileOverlay}
          onClick={() => setOpen(false)}
        />
      )}

      {/* Mobile drawer (width=100vw, maxWidth=320px) */}
      {isMobile && (
        <div style={styles.mobileMenuWrapper(open)}>
          {open && (
            <button
              style={styles.mobileCloseButton}
              onClick={() => setOpen(false)}
            >
              <FaTimes size={24} />
            </button>
          )}
          {renderContent}
        </div>
      )}

      {/* Desktop view */}
      {!isMobile && renderContent}
    </>
  );
}
