import React, { useState, useEffect, useRef } from 'react';
import EmojiPicker from 'emoji-picker-react';
import { useAccount } from 'wagmi';
import { database } from '../firebaseConfig';
import {
  ref as dbRef,
  onValue,
  set,
  remove,
  query,
  limitToLast
} from 'firebase/database';

// Firebase storage for image uploads
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL
} from 'firebase/storage';

import {
  FaTrash,
  FaEdit,
  FaEraser,
  FaBan,
  FaTelegram,
  FaDiscord,
  FaVideo,
  FaRegImage,
  FaLink,
  FaPlayCircle
} from 'react-icons/fa';

import AIAgents from './AIAgents';

// Example Degen avatars
import Degen1 from '../images/Degen1.png';
import Degen2 from '../images/Degen2.png';
import Degen3 from '../images/Degen3.png';
import Degen4 from '../images/Degen4.png';
import Degen5 from '../images/Degen5.png';
import Degen6 from '../images/Degen6.png';
import Degen7 from '../images/Degen7.png';
import Degen8 from '../images/Degen8.png';
import Degen9 from '../images/Degen9.png';
import Degen10 from '../images/Degen10.png';
import Degen11 from '../images/Degen11.png';
import Degen12 from '../images/Degen12.png';
import Degen13 from '../images/Degen13.png';
import Degen14 from '../images/Degen14.png';
import Degen15 from '../images/Degen15.png';
import Degen16 from '../images/Degen16.png';
import Degen17 from '../images/Degen17.png';
import Degen18 from '../images/Degen18.png';
import Degen19 from '../images/Degen19.png';
import Degen20 from '../images/Degen20.png';

// Hardcode your GIPHY API KEY here
const GIPHY_API_KEY = 'HCNl7FhvTzYMGa2jjvJB5dDHL8js2qJs';

// Utility: checks if snippet is an image link (ends with .png, .jpg, .gif, etc.)
function isImageUrl(text) {
  let url = text.trim();
  url = url.split('?')[0].split('#')[0];
  return /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(url);
}

// 20 possible Degen avatars
const degenImages = [
  Degen1, Degen2, Degen3, Degen4, Degen5,
  Degen6, Degen7, Degen8, Degen9, Degen10,
  Degen11, Degen12, Degen13, Degen14, Degen15,
  Degen16, Degen17, Degen18, Degen19, Degen20
];

// Admin addresses (lowercased)
const adminAddresses = [
  '0x7308bd2ea2bc85b44e44c64b30c14c189f75531d',
  '0x7384e9d49283d1195d1e8fd6515d72d622e65a70',
  '0xd2d78e6e11387c223b4cfb6347c515c92bc9617d'
];

// Avatar
function getAvatarForAddress(address) {
  if (!address) return '';
  const index = Math.abs(
    address.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)
  ) % degenImages.length;
  return degenImages[index];
}

// Trim address => 0x1234...abcd
function trimAddress(address) {
  if (!address) return '0x0000...0000';
  return `${address.slice(0, 6)}...${address.slice(-4)}`;
}

// Assign color via hashing
function getColorForAddress(address) {
  if (!address) return '#000';
  const hash = Math.abs(
    address.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0)
  );
  return `hsl(${hash % 360}, 70%, 50%)`;
}

// DGN ID
function getDgnNumberForAddress(address) {
  if (!address) return 'DGN000';
  if (adminAddresses.includes(address.toLowerCase())) return 'DGN-ADM';
  const hash = Math.abs(
    address
      .split('')
      .reduce((a, b) => ((a << 5) - a) + b.charCodeAt(0), 0)
  );
  return `DGN${(hash % 1000).toString().padStart(3, '0')}`;
}

// Reaction emojis
const REACTIONS = {
  GORILLA: '🦍',
  WHALE: '🐳',
  ROCKET: '🚀',
  DIAMOND: '💎',
  FIRE: '🔥',
  BLUE_CIRCLE: '🔵',
  YELLOW_CIRCLE: '🟡',
  BLACK_CIRCLE: '⚫',
  THUMBS_UP: '👍',
  THUMBS_DOWN: '👎',
  LAUGHING: '😂',
  POO: '💩',
  SALUTE: '🫡',
  HUNDRED: '💯',
  HANDSHAKE: '🤝'
};

// parseMessage => break text into [normal text, markdown links, images]
function parseMessage(msgText) {
  const parts = [];
  let remainingText = msgText;

  // Regex that matches [title](url)
  const linkRegex = /\[([^\]]+)\]\(([^)]+)\)/;

  while (remainingText) {
    const linkMatch = remainingText.match(linkRegex);
    if (!linkMatch) {
      parts.push({ type: 'text', content: remainingText });
      break;
    }
    const linkStartIndex = linkMatch.index;
    const linkFull = linkMatch[0];
    const linkTitle = linkMatch[1];
    const linkUrl = linkMatch[2];

    // anything before link
    if (linkStartIndex > 0) {
      parts.push({
        type: 'text',
        content: remainingText.slice(0, linkStartIndex)
      });
    }
    // actual link
    parts.push({
      type: 'mdLink',
      title: linkTitle,
      url: linkUrl
    });

    remainingText = remainingText.slice(linkStartIndex + linkFull.length);
  }

  return parts;
}

const NewChat = () => {
  // Wallet
  const { address, isConnected } = useAccount();
  const isAdmin = adminAddresses.includes(address?.toLowerCase());

  // Firebase Storage
  const storage = getStorage();

  // Messages & chat
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [editingMessage, setEditingMessage] = useState(null);
  const [replyingTo, setReplyingTo] = useState(null);

  // UI toggles
  const [showChatEmojiPicker, setShowChatEmojiPicker] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(null);
  const [showReactions, setShowReactions] = useState(null);

  // Giphy
  const [showGifPanel, setShowGifPanel] = useState(false);
  const [gifSearchTerm, setGifSearchTerm] = useState('');
  const [gifResults, setGifResults] = useState([]);

  // Link modal
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [linkText, setLinkText] = useState('');
  const [linkUrl, setLinkUrl] = useState('');

  // refs
  const chatEndRef = useRef(null);
  const fileInputRef = useRef(null);

  // Listen for last 100 messages
  useEffect(() => {
    const msgsQuery = query(dbRef(database, 'messages'), limitToLast(100));
    onValue(msgsQuery, (snapshot) => {
      if (!snapshot.exists()) {
        setMessages([]);
        return;
      }
      const dataObj = snapshot.val();
      const loaded = Object.values(dataObj);
      setMessages(loaded);
    });
  }, []);

  // auto-scroll
  useEffect(() => {
    if (!messages.length) return;
    setTimeout(() => {
      chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  }, [messages]);

  // handle Reaction
  const handleReaction = (msgId, reaction) => {
    if (!isConnected) return;
    const foundMsg = messages.find((m) => m.id === msgId);
    if (!foundMsg) return;

    const oldReactions = foundMsg.reactions || {};
    const users = oldReactions[reaction] || [];
    const hasUser = users.includes(address);

    const updated = { ...oldReactions };
    if (hasUser) {
      updated[reaction] = users.filter((u) => u !== address);
      if (!updated[reaction].length) delete updated[reaction];
    } else {
      updated[reaction] = [...users, address];
    }

    set(dbRef(database, `messages/${msgId}/reactions`), updated);
  };

  // Send or edit message
  const handleSendMessage = () => {
    if (!isConnected) {
      alert('Please connect your wallet first.');
      return;
    }
    if (!newMessage.trim() && !editingMessage) {
      return; // no content typed
    }

    if (editingMessage) {
      const confirmEdit = window.confirm('Edit this message?');
      if (!confirmEdit) return;
      const updatedMsgs = messages.map((m) =>
        m.id === editingMessage.id
          ? { ...m, text: newMessage.replace(/"/g, '') }
          : m
      );
      setMessages(updatedMsgs);
      set(dbRef(database, `messages/${editingMessage.id}`), {
        ...editingMessage,
        text: newMessage.replace(/"/g, '')
      });
      setEditingMessage(null);
    } else {
      // new message
      const newId = messages.length
        ? messages[messages.length - 1].id + 1
        : 1;
      const msgObj = {
        id: newId,
        text: newMessage.replace(/"/g, ''),
        sender: address,
        system: false,
        role: isAdmin ? 'Admin' : 'User',
        replyTo: replyingTo
          ? {
              id: replyingTo.id,
              text: replyingTo.text,
              sender: replyingTo.sender
            }
          : null,
        reactions: {}
      };
      set(dbRef(database, `messages/${newId}`), msgObj);
      setMessages([...messages, msgObj]);
    }

    setNewMessage('');
    setReplyingTo(null);
    setShowEmojiPicker(false);
  };

  // Press Enter => send
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  // select Emoji => newMessage
  const handleEmojiSelect = (emojiObject) => {
    setNewMessage((prev) => prev + emojiObject.emoji);
    // optionally collapse the emoji picker
    setShowChatEmojiPicker(false);
  };

  // edit message
  const handleEditMessage = (m) => {
    const c = window.confirm('Edit this message?');
    if (!c) return;
    setEditingMessage(m);
    setNewMessage(m.text);
  };

  // delete msg
  const handleDeleteMessage = (id) => {
    const c = window.confirm('Delete this message?');
    if (!c) return;
    remove(dbRef(database, `messages/${id}`));
    setMessages(messages.filter((m) => m.id !== id));
  };

  // clear chat
  const handleClearChat = () => {
    const c1 = window.confirm('WARNING: Clear ALL messages?');
    if (!c1) return;
    const c2 = window.confirm('FINAL WARNING: Press OK to wipe entire chat.');
    if (!c2) return;
    remove(dbRef(database, 'messages'));
    setMessages([]);
  };

  // mute user
  const handleMuteUser = (uid, duration) => {
    const c = window.confirm(`Mute user ${trimAddress(uid)} for ${duration}?`);
    if (!c) return;
    alert(`User ${trimAddress(uid)} is now muted for ${duration}. (No real logic)`);
  };

  // reply
  const handleReply = (m) => {
    setReplyingTo(m);
    const ta = document.querySelector('textarea');
    if (ta) ta.focus();
  };

  // Giphy => fetch
  const fetchGifs = async () => {
    if (!gifSearchTerm.trim()) return;
    try {
      const url = `https://api.giphy.com/v1/gifs/search?api_key=${GIPHY_API_KEY}&q=${encodeURIComponent(
        gifSearchTerm
      )}&limit=24`;
      const res = await fetch(url);
      const data = await res.json();
      if (data && data.data) {
        setGifResults(data.data);
      }
    } catch (err) {
      console.error('GIF fetch error:', err);
    }
  };

  // select gif
  const handleSelectGif = (gif) => {
    if (!gif) return;
    const gifUrl = gif?.images?.downsized_medium?.url;
    if (!gifUrl) return;
    setNewMessage((prev) => prev + ' ' + gifUrl);
    setShowGifPanel(false);
    setGifResults([]);
    setGifSearchTerm('');
  };

  // Image upload
  const handleImageUpload = async (e) => {
    e.preventDefault();
    if (!isConnected) {
      alert('Connect wallet first.');
      return;
    }
    const file = e.target.files[0];
    if (!file) return;

    try {
      const newId = messages.length ? messages[messages.length - 1].id + 1 : 1;
      // store path => chatImages/${address}/
      const storeLoc = `chatImages/${address}/${Date.now()}_${file.name}`;
      const sr = storageRef(storage, storeLoc);

      await uploadBytes(sr, file);
      const downloadURL = await getDownloadURL(sr);

      // Insert as new message
      const msgObj = {
        id: newId,
        text: downloadURL,
        sender: address,
        system: false,
        role: isAdmin ? 'Admin' : 'User',
        replyTo: replyingTo
          ? {
              id: replyingTo.id,
              text: replyingTo.text,
              sender: replyingTo.sender
            }
          : null,
        reactions: {}
      };
      set(dbRef(database, `messages/${newId}`), msgObj);
      setMessages([...messages, msgObj]);
      setReplyingTo(null);
      fileInputRef.current.value = null;
    } catch (err) {
      console.error('Image upload error:', err);
      alert('Failed to upload image. Check console logs or Firebase rules.');
    }
  };

  // Insert a [title](url)
  const handleAddLink = () => {
    if (!linkText.trim() || !linkUrl.trim()) {
      alert('Please provide link text AND link URL.');
      return;
    }
    const linkMarkdown = `[${linkText}](${linkUrl})`;
    setNewMessage((prev) => (prev ? prev + ' ' + linkMarkdown : linkMarkdown));
    setShowLinkModal(false);
    setLinkText('');
    setLinkUrl('');
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        backgroundColor: '#161A42',
        color: 'white'
      }}
    >
      {/* HEADER */}
      <div
        style={{
          backgroundColor: '#161A42',
          padding: '10px',
          borderBottom: '1px solid #2A2F50',
          position: 'sticky',
          top: 0,
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '5px'
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '15px' }}>
          <a
            href="https://t.me/defilaunchtoken"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#1FA2FF',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              transition: 'color 0.2s'
            }}
            onMouseOver={(e) => (e.currentTarget.style.color = '#A6FFCB')}
            onMouseOut={(e) => (e.currentTarget.style.color = '#1FA2FF')}
          >
            <FaTelegram size={24} />
          </a>
          <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Degen Chat</span>
          <a
            href="https://discord.gg/Ck9TUbYCgw"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#1FA2FF',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              transition: 'color 0.2s'
            }}
            onMouseOver={(e) => (e.currentTarget.style.color = '#A6FFCB')}
            onMouseOut={(e) => (e.currentTarget.style.color = '#1FA2FF')}
          >
            <FaDiscord size={24} />
          </a>
        </div>
        <div style={{ fontSize: '10px', color: '#888', textAlign: 'center', marginBottom: '8px' }}>
          Connect to chat. Support on Telegram &amp; Discord.
        </div>
      </div>

      {/* MESSAGES */}
      <div
        style={{
          flexGrow: 1,
          overflowY: 'auto',
          padding: '10px',
          paddingBottom: '60px',
          backgroundColor: '#161A42',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        {messages.map((msg) => {
          const isSystem = msg.system === true;
          return (
            <div
              key={msg.id}
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                marginBottom: '10px'
              }}
            >
              {!isSystem && (
                <div style={{ position: 'relative', marginRight: '10px', flexShrink: 0 }}>
                  <img
                    src={getAvatarForAddress(msg.sender)}
                    alt="avatar"
                    style={{
                      width: '40px',
                      height: '40px',
                      borderRadius: '50%',
                      border: '3px solid #F2C94C',
                      marginTop: '4px'
                    }}
                  />
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '-10px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      backgroundColor: getColorForAddress(msg.sender),
                      color: '#ffff',
                      padding: '1px 4px',
                      borderRadius: '10px',
                      fontSize: '8px',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {getDgnNumberForAddress(msg.sender)}
                  </div>
                </div>
              )}
              <div style={{ flexGrow: 1, maxWidth: 'calc(100% - 60px)' }}>
                {!isSystem && (
                  <div style={{ fontSize: '8px', color: '#888', marginBottom: '1px' }}>
                    {adminAddresses.includes(msg.sender?.toLowerCase())
                      ? 'Moderator'
                      : trimAddress(msg.sender)}
                  </div>
                )}
                <div
                  style={{
                    backgroundColor: isSystem ? '#ff9900' : '#212760',
                    borderRadius: '8px',
                    padding: '8px 10px',
                    position: 'relative',
                    width: '100%',
                    wordBreak: 'break-word'
                  }}
                  onMouseEnter={() => !showEmojiPicker && setShowReactions(msg.id)}
                  onMouseLeave={() => !showEmojiPicker && setShowReactions(null)}
                >
                  {/* If reply */}
                  {msg.replyTo && (
                    <div
                      style={{
                        fontSize: '10px',
                        color: '#888',
                        borderLeft: '2px solid #3461FF',
                        paddingLeft: '8px',
                        marginBottom: '4px',
                        maxHeight: '40px',
                        overflow: 'hidden'
                      }}
                    >
                      <span style={{ color: '#1FA2FF' }}>
                        {adminAddresses.includes(msg.replyTo.sender?.toLowerCase())
                          ? 'Moderator'
                          : trimAddress(msg.replyTo.sender)}
                      </span>
                      :{' '}
                      {msg.replyTo.text.length > 30
                        ? msg.replyTo.text.substring(0, 30) + '...'
                        : msg.replyTo.text}
                    </div>
                  )}

                  {/* parse text => normal text, images, markdown links */}
                  <div style={{ fontSize: '12px', whiteSpace: 'pre-wrap' }}>
                    {parseMessage(msg.text).map((part, idx) => {
                      if (part.type === 'text') {
                        // further split by spaces => check isImageUrl
                        return part.content
                          .split(' ')
                          .map((snip, i2) => {
                            if (isImageUrl(snip)) {
                              return (
                                <img
                                  key={i2}
                                  src={snip}
                                  alt="img"
                                  style={{
                                    maxWidth: '100%',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    display: 'block',
                                    marginBottom: '4px'
                                  }}
                                />
                              );
                            } else {
                              return snip + ' ';
                            }
                          });
                      }
                      if (part.type === 'mdLink') {
                        return (
                          <a
                            key={`link_${idx}`}
                            href={part.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: '#4ADE80', textDecoration: 'underline' }}
                          >
                            {part.title}
                          </a>
                        );
                      }
                      return null;
                    })}
                  </div>

                  {/* Admin Tools */}
                  {isAdmin && (
                    <div
                      style={{
                        position: 'absolute',
                        top: '-15px',
                        right: '10px',
                        display: 'flex',
                        gap: '5px'
                      }}
                    >
                      <FaEdit
                        title="Edit Message"
                        onClick={() => handleEditMessage(msg)}
                        style={{ cursor: 'pointer', color: '#ffcc00', fontSize: '12px' }}
                      />
                      <FaTrash
                        title="Delete Message"
                        onClick={() => handleDeleteMessage(msg.id)}
                        style={{ cursor: 'pointer', color: 'red', fontSize: '12px' }}
                      />
                      <FaBan
                        title="Mute User (1 day)"
                        onClick={() => handleMuteUser(msg.sender, '1 day')}
                        style={{ cursor: 'pointer', color: 'orange', fontSize: '12px' }}
                      />
                      <FaBan
                        title="Mute User (1 week)"
                        onClick={() => handleMuteUser(msg.sender, '1 week')}
                        style={{ cursor: 'pointer', color: 'darkred', fontSize: '12px' }}
                      />
                      <FaEraser
                        title="Clear Chat"
                        onClick={handleClearChat}
                        style={{ cursor: 'pointer', color: '#3461FF', fontSize: '12px' }}
                      />
                    </div>
                  )}

                  {/* Reactions hover */}
                  {showReactions === msg.id && !showEmojiPicker && (
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '2px',
                        right: '2px',
                        display: 'flex',
                        gap: '6px',
                        padding: '4px',
                        borderRadius: '4px',
                        background: 'rgba(33, 39, 96, 0.95)',
                        zIndex: 2
                      }}
                    >
                      <button
                        onClick={() => handleReply(msg)}
                        style={{
                          cursor: 'pointer',
                          background: 'none',
                          border: 'none',
                          padding: '2px',
                          fontSize: '14px'
                        }}
                        title="Reply"
                      >
                        ↩️
                      </button>
                      <button
                        onClick={() => setShowEmojiPicker(msg.id)}
                        style={{
                          cursor: 'pointer',
                          background: 'none',
                          border: 'none',
                          padding: '2px',
                          fontSize: '14px'
                        }}
                        title="React"
                      >
                        😊
                      </button>
                    </div>
                  )}

                  {/* Reactions display */}
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '4px', marginTop: '4px' }}>
                    {msg.reactions &&
                      Object.entries(msg.reactions).map(([emo, users]) => (
                        <button
                          key={emo}
                          onClick={() => handleReaction(msg.id, emo)}
                          style={{
                            cursor: 'pointer',
                            background: users.includes(address)
                              ? '#3461FF33'
                              : 'none',
                            border: '1px solid #3461FF33',
                            borderRadius: '12px',
                            padding: '2px 6px',
                            fontSize: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '4px',
                            color: 'grey'
                          }}
                        >
                          {emo} {users.length}
                        </button>
                      ))}
                  </div>

                  {/* Reaction emoji picker */}
                  {showEmojiPicker === msg.id && (
                    <div
                      style={{
                        position: 'absolute',
                        bottom: '100%',
                        right: '0',
                        backgroundColor: '#212760',
                        borderRadius: '8px',
                        padding: '8px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        zIndex: 1000,
                        marginBottom: '4px',
                        boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '4px'
                        }}
                      >
                        <span style={{ fontSize: '12px', color: '#888' }}>Select Reaction</span>
                        <button
                          onClick={() => setShowEmojiPicker(null)}
                          style={{
                            cursor: 'pointer',
                            background: 'none',
                            border: 'none',
                            color: '#888',
                            fontSize: '14px',
                            padding: '2px'
                          }}
                        >
                          ✕
                        </button>
                      </div>
                      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                        {Object.values(REACTIONS).map((emo) => (
                          <button
                            key={emo}
                            onClick={() => {
                              handleReaction(msg.id, emo);
                              setShowEmojiPicker(null);
                            }}
                            style={{
                              cursor: 'pointer',
                              background: 'none',
                              border: 'none',
                              padding: '4px',
                              fontSize: '16px'
                            }}
                          >
                            {emo}
                          </button>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
        <div ref={chatEndRef} />
      </div>

      {isAdmin && <AIAgents isTestMode={true} />}

      {/* INPUT AREA */}
      <div
        style={{
          padding: '17px',
          borderTop: '1px solid #2A2F50',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          position: 'sticky',
          bottom: '60px',
          zIndex: 1000,
          backgroundColor: '#161A42'
        }}
      >
        {/* If replying */}
        {replyingTo && (
          <div
            style={{
              padding: '5px 10px',
              backgroundColor: '#212760',
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              fontSize: '10px'
            }}
          >
            <div>
              Replying to{' '}
              <span style={{ color: '#1FA2FF' }}>
                {adminAddresses.includes(replyingTo.sender?.toLowerCase())
                  ? 'Moderator'
                  : trimAddress(replyingTo.sender)}
              </span>
              :{' '}
              {replyingTo.text.length > 30
                ? replyingTo.text.substring(0, 30) + '...'
                : replyingTo.text}
            </div>
            <button
              onClick={() => setReplyingTo(null)}
              style={{
                background: 'none',
                border: 'none',
                color: '#888',
                cursor: 'pointer',
                padding: '0 5px'
              }}
            >
              ✕
            </button>
          </div>
        )}

        {/* Giphy Search Popup */}
        {showGifPanel && (
          <div
            style={{
              position: 'absolute',
              bottom: '70px',
              left: '10px',
              backgroundColor: '#212760',
              padding: '8px',
              borderRadius: '8px',
              zIndex: 2000,
              width: '240px'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '6px'
              }}
            >
              <input
                style={{
                  flexGrow: 1,
                  marginRight: '5px',
                  borderRadius: '4px',
                  border: 'none',
                  padding: '4px',
                  outline: 'none',
                  fontSize: '12px'
                }}
                type="text"
                placeholder="Search GIFs..."
                value={gifSearchTerm}
                onChange={(e) => setGifSearchTerm(e.target.value)}
              />
              <button
                onClick={fetchGifs}
                style={{
                  backgroundColor: '#3461FF',
                  color: '#fff',
                  borderRadius: '5px',
                  border: 'none',
                  cursor: 'pointer',
                  padding: '2px 4px',
                  fontSize: '10px'
                }}
              >
                Search
              </button>
            </div>
            <div style={{ textAlign: 'right', marginBottom: '6px' }}>
              <button
                onClick={() => {
                  setShowGifPanel(false);
                  setGifResults([]);
                  setGifSearchTerm('');
                }}
                style={{
                  background: 'none',
                  border: 'none',
                  color: '#fff',
                  cursor: 'pointer',
                  fontSize: '14px'
                }}
              >
                ✕
              </button>
            </div>
            <div
              style={{
                maxHeight: '200px',
                overflowY: 'auto',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '6px'
              }}
            >
              {gifResults.map((gif) => (
                <img
                  key={gif.id}
                  src={gif.images?.preview_gif?.url}
                  alt="gif"
                  style={{
                    width: '70px',
                    height: '70px',
                    objectFit: 'cover',
                    cursor: 'pointer',
                    borderRadius: '6px'
                  }}
                  onClick={() => handleSelectGif(gif)}
                />
              ))}
            </div>
          </div>
        )}

        {/* Hidden file input for images */}
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleImageUpload}
        />

        {/* Textarea + Send */}
        <div style={{ display: 'flex', gap: '10px', position: 'relative' }}>
          <textarea
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyDown={handleKeyPress}
            placeholder={!isConnected ? 'Connect Wallet' : 'Type message..'}
            style={{
              flexGrow: 1,
              padding: '6px 10px',
              height: '28px',
              minHeight: '28px',
              maxHeight: '100px',
              borderRadius: '5px',
              border: 'none',
              outline: 'none',
              color: 'white',
              backgroundColor: '#212760',
              fontSize: '12px',
              resize: 'vertical',
              fontFamily: 'inherit'
            }}
            disabled={!isConnected}
          />
          <button
            onClick={handleSendMessage}
            style={{
              padding: '6px 10px',
              height: '28px',
              backgroundColor: '#3461FF',
              color: 'white',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
              fontSize: '12px'
            }}
            disabled={!isConnected}
          >
            {editingMessage ? 'Update' : 'Send'}
          </button>
        </div>

        {/* Action buttons: emoji, GIF, link, image, etc. */}
        <div style={{ display: 'flex', gap: '6px', marginTop: '8px', flexWrap: 'wrap' }}>
          {/* Emojis */}
          <button
            onClick={() => setShowChatEmojiPicker(!showChatEmojiPicker)}
            style={{
              padding: '6px 10px',
              backgroundColor: '#3461FF',
              color: 'white',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
              fontSize: '12px'
            }}
            disabled={!isConnected}
            title="Emoji Picker"
          >
            😊
          </button>

          {/* GIF */}
          <button
            onClick={() => {
              setShowGifPanel(!showGifPanel);
              setGifResults([]);
              setGifSearchTerm('');
            }}
            style={{
              padding: '6px 10px',
              backgroundColor: '#3461FF',
              color: 'white',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
              fontSize: '12px'
            }}
            disabled={!isConnected}
            title="GIF Search"
          >
            GIF
          </button>

          {/* Link (activated) */}
          <button
            onClick={() => setShowLinkModal(true)}
            style={{
              padding: '6px 10px',
              backgroundColor: '#3461FF',
              color: 'white',
              borderRadius: '5px',
              border: 'none',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              fontSize: '12px'
            }}
            disabled={!isConnected}
            title="Add Link"
          >
            <FaLink style={{ marginRight: '4px' }} /> Link
          </button>

          {/* Image upload */}
          <button
  onClick={() => {}} // do nothing
  style={{
    padding: '6px 10px',
    backgroundColor: '#666', 
    color: '#ccc',
    borderRadius: '5px',
    border: 'none',
    cursor: 'not-allowed',
    fontSize: '12px'
  }}
  disabled
  title="Coming Soon (Image Upload)"
>
  <FaRegImage />
</button>

          {/* Future (Video, Live) => disabled */}
          <button
            style={{
              padding: '6px 10px',
              backgroundColor: '#666',
              color: '#ccc',
              borderRadius: '5px',
              border: 'none',
              cursor: 'not-allowed',
              display: 'flex',
              alignItems: 'center',
              fontSize: '12px'
            }}
            disabled
            title="Coming Soon (Video)"
          >
            <FaVideo />
          </button>
          <button
            style={{
              padding: '6px 10px',
              backgroundColor: '#666',
              color: '#ccc',
              borderRadius: '5px',
              border: 'none',
              cursor: 'not-allowed',
              display: 'flex',
              alignItems: 'center',
              fontSize: '12px'
            }}
            disabled
            title="Coming Soon (Live Stream)"
          >
            <FaPlayCircle />
          </button>
        </div>

        {/* Chat-level emoji popup (for main input) */}
        {showChatEmojiPicker && (
          <div
            style={{
              position: 'absolute',
              bottom: '120px',
              left: '10px',
              zIndex: 1000
            }}
          >
            <EmojiPicker
              onEmojiClick={handleEmojiSelect}
              theme="dark"
              pickerStyle={{ fontSize: '10px' }}
            />
          </div>
        )}

        {/* Link Modal => create [title](url) */}
        {showLinkModal && (
          <div
            style={{
              position: 'absolute',
              bottom: '140px',
              left: '10px',
              backgroundColor: '#212760',
              padding: '8px',
              borderRadius: '8px',
              zIndex: 2000,
              width: '240px'
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: '6px',
                color: '#fff',
                fontSize: '12px'
              }}
            >
              <span>Add a Link</span>
              <button
                onClick={() => {
                  setShowLinkModal(false);
                  setLinkText('');
                  setLinkUrl('');
                }}
                style={{
                  background: 'none',
                  border: 'none',
                  color: '#fff',
                  cursor: 'pointer',
                  fontSize: '14px'
                }}
              >
                ✕
              </button>
            </div>
            <input
              style={{
                width: '100%',
                marginBottom: '6px',
                borderRadius: '4px',
                border: 'none',
                padding: '4px',
                outline: 'none',
                fontSize: '12px'
              }}
              type="text"
              placeholder="Link text (e.g. DEFI LAUNCH)"
              value={linkText}
              onChange={(e) => setLinkText(e.target.value)}
            />
            <input
              style={{
                width: '100%',
                marginBottom: '6px',
                borderRadius: '4px',
                border: 'none',
                padding: '4px',
                outline: 'none',
                fontSize: '12px'
              }}
              type="text"
              placeholder="Link URL (e.g. https://defilaunch.app/)"
              value={linkUrl}
              onChange={(e) => setLinkUrl(e.target.value)}
            />
            <button
              onClick={handleAddLink}
              style={{
                backgroundColor: '#3461FF',
                color: '#fff',
                borderRadius: '5px',
                border: 'none',
                cursor: 'pointer',
                padding: '4px 6px',
                fontSize: '12px',
                width: '100%'
              }}
            >
              Insert Link
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewChat;
