import React, { useState, useEffect, useRef } from 'react';
import { database } from '../firebaseConfig';
import { ref, set, query, limitToLast, onValue } from 'firebase/database';
import { useAccount } from 'wagmi';

// === 10 AI Agents
const AI_AGENTS = [
  {
    name: "DegenTrader",
    address: "0x82a8BD2ea2bc85B44e44c64B30c14C189F754a1e",
    personality: "Bullish, hype-driven, always calling for others to join in."
  },
  {
    name: "MemeExpert",
    address: "0x9008BD2ea2bc85B44e44c64B30c14C189F75531g",
    personality: `Constantly referencing memes, jokes, and internet culture.
Speaks with degen style often using abbreviations. Uses phrases like "chad", "dgn", "wen".`
  },
  {
    name: "AlphaHunter",
    address: "0x9308BD2ea2bc85B44e44c64B30c14C189F76583h",
    personality: "Analytical, shares alpha about new tokens and strategies but no emojis."
  },
  {
    name: "NFTCollector",
    address: "0x23a72D2ea2bc85B44e44c64B30c14189F05f2210",
    personality: `Speaks with broken English, often misspelling words.
Uses phrases like "ser", "plz", "wen" in every sentence.`
  },
  {
    name: "WhaleWatcher",
    address: "0x5a49dBD2ea2bc85B44e44c64B30c14C189F75G419",
    personality: `Obsessed with large trades, whale movements, big buy orders.
Speaks with an English twang, often misspelling words.
Uses phrases like "bruv", "innit", "sound" etc.`
  },
  {
    name: "ChartSeer",
    address: "0x3ABD2ea2bc85B44e44c64B30c14C189Fa5716v",
    personality: "Always reading charts, discussing lines, breakouts, fib levels."
  },
  {
    name: "BetaBuilder",
    address: "0x7308BD2ea2bc85B44e44c64B30c14C189F75514l",
    personality: "Builds and experiments with new dApps, technical dev focus."
  },
  {
    name: "ShillMaster",
    address: "0x8B28BD2ea2bc85B44e44c64B30c14C189F7b2j55",
    personality: "Relentlessly shills the latest token, uses big hype words."
  },
  {
    name: "MemeGuru",
    address: "0x22j08BD2ea2bc85B44e44c64B30c14C189F75921n",
    personality: "Combines deep meme knowledge with market insight, comedic tone."
  },
  {
    name: "DexDegen",
    address: "0x18aD2ea2bc85B44e44c64B30c14C189F73731o",
    personality: "Loves swapping on DEXs, discussing slippage, LP yields, etc."
  }
];

// === Delays: 2 minutes to 1 hour
const MIN_DELAY = 2 * 60 * 1000;     
const MAX_DELAY = 60 * 60 * 1000;

// Some random reactions for the AI to apply
const AI_REACTIONS = ["🔥","🚀","💎","💩","😉","😂","👍","👎"];

// === Full system prompt including your detailed project info
const SYSTEM_PROMPT = `
You are one of many AI agents in a chat about DeFi Launch App and the current Crypto market and up-to-date news.
NEVER prefix your messages with your address.
Never repeat the same Emoji sequence from a previous message that you are replying to! Do not use rockets constantly use all the other emojis available.
Do not use the same exact word for word used in the Detailed project info.
Use short, casual, meme-friendly statements referencing the personality described.
Try mentioning the DeFi Launch App or $DEFI token context or meme coin hype if relevant.
Make conversation from previous messages that are commented before you.
Don't use emojis on every message. You can occasionally use Gifs from Giphy relating to meme coins and Defi.
You're free to banter but keep it under ~150 characters and try also small responses under 50 characters.
Do not make it known that you are an AI agent, bot or chatgpt.
Try learn what defilaunch.app is, ask questions that are positive to the community and respond to the community with correct knowledge of the project.
The $DEFI aka DEFI token is the main token to talk about. It's on Base network with address 0x473995e934C5E871aF24D6A1daBcBb0c7e89a896.
Social media links and info:
Official links for defilaunch.app

Launchpad: https://defilaunch.app/
Swap:  https://defiswap.app/
Bridge: https://defibridge.app/
Github: https://github.com/DefiLaunchApp
Gitbook Docs: https://defilaunchapp.gitbook.io/
Twitter:  https://x.com/defilaunchapp
Warpcast: https://warpcast.com/defilaunchapp.eth
Telegram: https://t.me/defilaunchapp
Discord:  https://discord.com/invite/Ck9TUbYCgw
News Portal: https://definews.app/

DETAILED PROJECT INFO:

Defi Launch is revolutionizing decentralized finance (DeFi) by offering an innovative, cost-effective platform where users and projects can effortlessly create tokens, manage portfolios, and participate in launches. Our suite of tools includes token creation, fair launches, pump sales, staking, locks, airdrops, and more. Powered by LayerZero for seamless cross-chain interoperability, Defi Launch is designed to empower creators and investors with advanced tools to enhance project success and portfolio management.

Introduction
In the fast-evolving DeFi landscape, accessibility, interoperability, and security are critical. Defi Launch combines these elements to deliver a comprehensive platform that bridges blockchain networks, ensures user engagement, and secures investments—all while maintaining the most affordable cost structure for users.

The Technology
At the core of Defi Launch lies LayerZero, an omnichain interoperability protocol that enables seamless interactions and transactions across multiple blockchain networks. This cutting-edge technology ensures a fully interconnected DeFi ecosystem, supporting token creation, staking, and governance across various chains.

Platform Features
Token Creation
• Fast and easy token creation with zero coding knowledge.
• Auto-verification of deployed contracts ensures security and efficiency.

Launchpad Services
• Fair launches and pump sales for equitable token distribution.
• Advanced tools for liquidity locking and token vesting.

Portfolio Tracker
• Manage assets across networks with real-time updates and analytics.

Web3 Wallet and Chat App
• Secure wallet integration and seamless communication for project teams.

Chart DEX
• Comprehensive trading insights and analytics for users.

Token Audits and NFT Marketplace
• Access to third-party audits and a dedicated marketplace for NFTs.

Cross-Chain Support
• Seamless integration with Base, Linea, zkSync, and more, with ongoing expansion to Scroll, OP, ARB, Metis, and additional networks.

Revenue Model
Defi Launch generates revenue through multiple streams:
• Fees from token creation, fair launches, pump sales, staking, locks, airdrops, and portfolio management.
• Transaction fees from the Defi Swap app, which facilitates buy and sell transactions.
• A 5% fee on successful project launches, with 100% of these fees reinvested into $DEFI token buybacks and burns.

The $DEFI Token
The $DEFI token is central to the Defi Launch ecosystem, offering governance and cross-chain staking via LayerZero. Key features include:
• ETH Rewards: Holders earn Ethereum rewards for supporting the ecosystem.
• Deflationary Burn Mechanism: Regular token burns reduce supply over time.
• 80% Initial Burn: A significant portion of the token supply was burned at launch.
• Liquidity Pool Burn: Ensures security and trust.
• No Team Allocation: All tokens were distributed fairly, with a locked developer wallet holding only 0.02%.
• Revenue Buybacks: All platform revenue is used to buy back $DEFI tokens, supporting price stability and rewarding holders.

Roadmap
Defi Launch is committed to ongoing innovation, with a roadmap that includes:
• Expanding network support to Scroll, OP, ARB, Metis, and more.
• Enhancing $DEFI token cross-chain utility.
• Building advanced features like the Chart DEX, portfolio tracker, Web3 wallet, and chat app.
• Integrating token audits and launching an NFT marketplace.

Conclusion
Defi Launch is redefining decentralized finance by delivering an accessible, secure, and innovative platform. With our integration of LayerZero, robust revenue model, and a unique value proposition, we provide users and projects the tools they need to succeed in the DeFi space while creating long-term value for $DEFI token holders.
`;

const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const CHATGPT_ENDPOINT = process.env.REACT_APP_CHATGPT_ENDPOINT || "https://api.openai.com/v1/chat/completions";

const AIAgents = ({ isTestMode = false }) => {
  const [isRunning, setIsRunning] = useState(false);
  const [lastMessageId, setLastMessageId] = useState(0);
  const [agents, setAgents] = useState([]);
  const timeoutRef = useRef(null);

  // Setup agents on mount if in test mode
  useEffect(() => {
    if (isTestMode) {
      setAgents(AI_AGENTS.map(a => ({
        ...a,
        lastMessageTime: Date.now()
      })));
    }
  }, [isTestMode]);

  // Finds next message ID by checking DB
  const getNextMessageId = async () => {
    return new Promise((resolve) => {
      const msgsRef = query(ref(database, 'messages'), limitToLast(1000));
      onValue(msgsRef, (snapshot) => {
        let maxID = 0;
        if (snapshot.exists()) {
          const dataObj = snapshot.val();
          Object.values(dataObj).forEach(m => {
            if (m.id > maxID) maxID = m.id;
          });
        }
        const nextID = Math.max(lastMessageId, maxID) + 1;
        setLastMessageId(nextID);
        resolve(nextID);
      }, { onlyOnce: true });
    });
  };

  // Summon GPT
  const generateAiText = async (agent, recent) => {
    try {
      // Build short history from the last 5 messages
      const shortHistory = recent.slice(-5).map(msg => ({
        role: "user",
        content: `[${msg.sender}] says: ${msg.text}`
      }));

      // Combine agent personality + system instructions
      const systemIntro = `
You are ${agent.name}, with personality: ${agent.personality}.
${SYSTEM_PROMPT}
`;

      const response = await fetch(CHATGPT_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${OPENAI_API_KEY}`
        },
        body: JSON.stringify({
          model: "gpt-3.5-turbo",
          messages: [
            { role: "system", content: systemIntro.trim() },
            ...shortHistory
          ]
        })
      });
      const data = await response.json();
      if (data.choices && data.choices[0]?.message?.content) {
        return data.choices[0].message.content;
      }
      return null;
    } catch(err) {
      console.error("generateAiText error:", err);
      return null;
    }
  };

  // A few random reactions for the AI to choose from
  const AI_REACTIONS = ["🔥","🚀","💎","💩","😉","😂","👍","👎"];

  // The AI can add a random reaction to a random user message
  const reactToRandomMessage = async (agentAddress, messages) => {
    // Filter out AI messages so we only react to user ones
    const userMsgs = messages.filter(m => m.role !== 'AI' && m.sender !== agentAddress);
    if (!userMsgs.length) return;

    const randomMsg = userMsgs[Math.floor(Math.random()*userMsgs.length)];
    const randomReaction = AI_REACTIONS[Math.floor(Math.random()*AI_REACTIONS.length)];

    // get existing reactions or create new
    const oldReactions = randomMsg.reactions || {};
    const currentUsers = oldReactions[randomReaction] || [];

    if (!currentUsers.includes(agentAddress)) {
      const updatedUsers = [...currentUsers, agentAddress];
      const updatedReactions = { ...oldReactions, [randomReaction]: updatedUsers };
      // set to DB
      set(ref(database, `messages/${randomMsg.id}/reactions`), updatedReactions);
    }
  };

  // Send an AI message (with optional replyTo)
  const sendAiMessage = async (agent, text, replyTo = null) => {
    const newId = await getNextMessageId();
    let finalText = text;
    // remove "0xstuff: " if present
    const colonIndex = finalText.indexOf(':');
    if (colonIndex>=0 && colonIndex<15 && finalText.startsWith('0x')) {
      finalText = finalText.slice(colonIndex+1).trim();
    }

    const msgObj = {
      id: newId,
      text: finalText,
      sender: agent.address,
      system: false,
      role: 'AI',
      reactions:{},
      // optional replyTo
      replyTo
    };
    await set(ref(database, `messages/${newId}`), msgObj);

    // update agent's last speak time
    setAgents(prev =>
      prev.map(a=>
        a.address===agent.address
          ? { ...a, lastMessageTime: Date.now() }
          : a
      )
    );
  };

  // pick earliest agent
  const pickNextSpeaker = () => {
    if (!agents.length) return null;
    let earliest = agents[0];
    for (let i=1; i<agents.length; i++){
      if (agents[i].lastMessageTime < earliest.lastMessageTime) {
        earliest = agents[i];
      }
    }
    return earliest;
  };

  const runOneCycle = async () => {
    if (!isRunning || !agents.length) return;
    const speaker = pickNextSpeaker();
    if(!speaker) return;

    // read last 5 messages
    const msgsRef = query(ref(database, 'messages'), limitToLast(5));
    onValue(msgsRef, async (snapshot) => {
      const recent = [];
      snapshot.forEach(child => recent.push(child.val()));

      // React to a random user message
      await reactToRandomMessage(speaker.address, recent);

      // Generate the text
      const aiText = await generateAiText(speaker, recent);
      if (!aiText) return;

      // pick a random user message to "reply" to
      const userMsgs = recent.filter(m => m.role !== 'AI');
      let randomReply = null;
      if (userMsgs.length) {
        const randomMsg = userMsgs[Math.floor(Math.random()*userMsgs.length)];
        randomReply = {
          id: randomMsg.id,
          text: randomMsg.text,
          sender: randomMsg.sender
        };
      }

      // send AI message with a replyTo
      await sendAiMessage(speaker, aiText, randomReply);
    }, { onlyOnce:true });
  };

  const scheduleNextCycle = () => {
    const randomDelay = Math.floor(Math.random()*(MAX_DELAY - MIN_DELAY)) + MIN_DELAY;
    timeoutRef.current = setTimeout(async()=>{
      await runOneCycle();
      scheduleNextCycle();
    }, randomDelay);
  };

  useEffect(()=>{
    if(isRunning && isTestMode){
      // one immediate cycle
      runOneCycle();
      // schedule next
      scheduleNextCycle();
    }
    return ()=>{
      if(timeoutRef.current) clearTimeout(timeoutRef.current);
    };
  }, [isRunning, isTestMode]);

  if(!isTestMode) return null;

  return (
    <div style={{
      position:'fixed',
      bottom:'80px',
      right:'20px',
      zIndex:1000,
      display:'flex',
      gap:'10px'
    }}>
      <button
        onClick={()=> setIsRunning(true)}
        style={{
            padding: '2px 6px',      // smaller padding
    fontSize: '10px',        // smaller font

          backgroundColor:'#3461FF',
          color:'white',
          borderRadius:'5px',
          border:'none',
          cursor:'pointer'
        }}
        disabled={isRunning}
      >
        Start AI
      </button>
      <button
        onClick={()=>{
          setIsRunning(false);
          if(timeoutRef.current) clearTimeout(timeoutRef.current);
        }}
        style={{
            padding: '2px 6px',      // smaller padding
            fontSize: '10px',        // smaller font
        
          backgroundColor:'#ff4444',
          color:'white',
          borderRadius:'5px',
          border:'none',
          cursor:'pointer'
        }}
        disabled={!isRunning}
      >
        Stop AI
      </button>
    </div>
  );
};

export default AIAgents;
