import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import { Copy, ExternalLink } from 'lucide-react';
import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip } from 'recharts';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// Minimal ERC20 with symbol + balanceOf + approve
const erc20Abi = [
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [
      { internalType: "address", name: "spender", type: "address" },
      { internalType: "uint256", name: "amount", type: "uint256" }
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  }
];

// Minimal StakingRewards with stake(), withdraw(), earned(), getReward()
const stakingRewardsAbi = [
  {
    inputs: [],
    name: "stakingToken",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "address", name: "account", type: "address" }],
    name: "earned",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "stake",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [{ internalType: "uint256", name: "amount", type: "uint256" }],
    name: "withdraw",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  // The function that was missing in your front-end previously:
  {
    inputs: [],
    name: "getReward",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function"
  },
  {
    inputs: [],
    name: "rewardsToken",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function"
  }
];

function shortAddress(addr = "") {
  if (addr.length < 10) return addr;
  return addr.slice(0, 6) + '...' + addr.slice(-4);
}

const StakingPool = () => {
  const { poolAddress } = useParams();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:768px)');

  const [activeTab, setActiveTab] = useState('stakePool'); 
  const [inputAmount, setInputAmount] = useState('');
  const [loading, setLoading] = useState(false);

  // Info we display
  const [poolInfo, setPoolInfo] = useState({
    tokenSymbol: "???",
    tokenAddress: "",
    stakeAddress: poolAddress || "",
    totalStaked: "0",
    userStaked: "0",
    availableRewards: "0",
    userBalance: "0",
    apy: "0",            // optional if you want to show APY
    minStake: "0",       // if your UI wants to display these
    maxStake: "∞",
    lockPeriod: "0"
  });

  // Chart example data
  const chartData = [
    { timestamp: 1, value: 100 },
    { timestamp: 2, value: 110 },
    { timestamp: 3, value: 103 },
    { timestamp: 4, value: 115 },
    { timestamp: 5, value: 108 },
    { timestamp: 6, value: 120 }
  ];

  useEffect(() => {
    if (poolAddress) {
      loadPoolData(poolAddress).catch(e => console.error("loadPoolData error:", e));
    }
  }, [poolAddress]);

  async function loadPoolData(addr) {
    if (!window.ethereum) return;
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    const userAddr = await signer.getAddress();

    // Create contract instance
    const staking = new ethers.Contract(addr, stakingRewardsAbi, signer);

    // total staked
    const supplyBN = await staking.totalSupply();
    const totalStaked = ethers.utils.formatUnits(supplyBN, 18);

    // user staked
    let userStaked = "0";
    try {
      const stBN = await staking.balanceOf(userAddr);
      userStaked = ethers.utils.formatUnits(stBN, 18);
    } catch {}

    // user earned => availableRewards
    let userEarned = "0.00";
    try {
      const earnedBN = await staking.earned(userAddr);
      const rawEarned = parseFloat(ethers.utils.formatUnits(earnedBN, 18));
      userEarned = rawEarned.toFixed(2);
    } catch {}

    // stakingToken => symbol + userBalance
    const stakingTokenAddr = await staking.stakingToken();
    let tokenSymbol = "???";
    let userBalance = "0";
    try {
      const tokenContract = new ethers.Contract(stakingTokenAddr, erc20Abi, signer);
      tokenSymbol = await tokenContract.symbol();
      const balBN = await tokenContract.balanceOf(userAddr);
      userBalance = ethers.utils.formatUnits(balBN, 18);
    } catch {}

    // Optionally fetch APY or other data from your server or store:
    // For now, just store placeholders or zeros
    setPoolInfo({
      tokenSymbol,
      tokenAddress: stakingTokenAddr,
      stakeAddress: addr,
      totalStaked,
      userStaked,
      availableRewards: userEarned,
      userBalance,
      apy: "0",        // you can calculate or store externally
      minStake: "0",
      maxStake: "∞",
      lockPeriod: "0"
    });
  }

  // Approve -> Stake
  const handleStake = async () => {
    if (!inputAmount || Number(inputAmount) <= 0) {
      return alert("Enter a valid stake amount!");
    }
    setLoading(true);
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const stakeWei = ethers.utils.parseUnits(inputAmount, 18);

      // 1) Approve
      const tokenContract = new ethers.Contract(poolInfo.tokenAddress, erc20Abi, signer);
      const txApprove = await tokenContract.approve(poolInfo.stakeAddress, stakeWei);
      await txApprove.wait();
      toast.success("Approved staking token!");

      // 2) Stake
      const staking = new ethers.Contract(poolInfo.stakeAddress, stakingRewardsAbi, signer);
      const txStake = await staking.stake(stakeWei);
      await txStake.wait();
      toast.success("Staked successfully!");

      setInputAmount('');
      await loadPoolData(poolInfo.stakeAddress);
    } catch (err) {
      console.error("Stake error:", err);
      toast.error(err.reason || "Failed to stake");
    }
    setLoading(false);
  };

  // Withdraw
  const handleWithdraw = async () => {
    if (!inputAmount || Number(inputAmount) <= 0) {
      return alert("Enter a valid withdraw amount!");
    }
    setLoading(true);
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const withdrawWei = ethers.utils.parseUnits(inputAmount, 18);

      const staking = new ethers.Contract(poolInfo.stakeAddress, stakingRewardsAbi, signer);
      const tx = await staking.withdraw(withdrawWei);
      await tx.wait();
      toast.success("Withdraw successful!");

      setInputAmount('');
      await loadPoolData(poolInfo.stakeAddress);
    } catch (err) {
      console.error("Withdraw error:", err);
      toast.error(err.reason || "Failed to withdraw");
    }
    setLoading(false);
  };

  // Claim
  const handleClaimRewards = async () => {
    setLoading(true);
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const staking = new ethers.Contract(poolInfo.stakeAddress, stakingRewardsAbi, signer);

      const tx = await staking.getReward();
      await tx.wait();
      toast.success("Rewards claimed!");

      await loadPoolData(poolInfo.stakeAddress);
    } catch (err) {
      console.error("Claim error:", err);
      toast.error(err.reason || "Failed to claim");
    }
    setLoading(false);
  };

  // Render
  const chartHeight = isMobile ? 200 : 300;

  return (
    <div
      style={{
        width: '100%',
        minHeight: '100vh',
        fontFamily: "'Quicksand', sans-serif",
        backgroundColor: '#0A0B1E',
        color: '#FFF',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px'
      }}
    >
      {/* Top bar with back arrow */}
      <div style={{ 
        width:'100%', 
        maxWidth:'1200px', 
        marginBottom:'10px', 
        display:'flex', 
        alignItems:'center',
        justifyContent:'flex-start',
      }}>
        <button
          onClick={() => navigate('/staking')}
          style={{
            background:'none',
            border:'none',
            color:'#7B91B0',
            cursor:'pointer',
            fontSize:'0.9rem',
            marginRight:'10px'
          }}
        >
          ← Back
        </button>
      </div>

      <div
        style={{
          width: '100%',
          maxWidth: '1200px',
          backgroundColor: '#161A42',
          borderRadius: '12px',
          overflow: 'hidden'
        }}
      >
        {/* Tabs row */}
        <div style={{
          display:'flex', 
          justifyContent:'center',
          borderBottom:'1px solid rgba(255,255,255,0.2)',
          padding:'10px',
          gap:'10px'
        }}>
          <button
            onClick={() => setActiveTab('about')}
            style={{
              backgroundColor: activeTab==='about' ? '#3461FF':'transparent',
              color: activeTab==='about' ? '#FFF':'#7B91B0',
              padding:'8px 16px', borderRadius:'8px',
              cursor:'pointer', border:'none', fontSize:'1rem'
            }}
          >
            About
          </button>
          <button
            onClick={() => setActiveTab('stakePool')}
            style={{
              backgroundColor: activeTab==='stakePool' ? '#3461FF':'transparent',
              color: activeTab==='stakePool' ? '#FFF':'#7B91B0',
              padding:'8px 16px', borderRadius:'8px',
              cursor:'pointer', border:'none', fontSize:'1rem'
            }}
          >
            Stake Pool
          </button>
        </div>

        {/* Tab content */}
        <div style={{ padding:'16px' }}>
          {activeTab === 'about' ? (
            // ABOUT TAB
            <div style={{ display:'flex', flexDirection:'column', gap:'12px' }}>
              {/* You can display info about the pool, APY, addresses, etc. */}
              <div
                style={{
                  display:'flex', 
                  justifyContent:'space-between',
                  padding:'8px 0', 
                  borderBottom:'1px solid rgba(255,255,255,0.1)'
                }}
              >
                <span style={{ color:'#7B91B0' }}>Pool Address</span>
                <div style={{ display:'flex', alignItems:'center' }}>
                  <span
                    style={{
                      background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      marginRight:'6px'
                    }}
                  >
                    {isMobile ? shortAddress(poolInfo.stakeAddress) : poolInfo.stakeAddress}
                  </span>
                  <button
                    style={{ background:'none', border:'none', color:'#7B91B0', cursor:'pointer' }}
                    onClick={() => navigator.clipboard.writeText(poolInfo.stakeAddress)}
                  >
                    <Copy size={16} />
                  </button>
                  <button
                    style={{ background:'none', border:'none', color:'#7B91B0', cursor:'pointer', marginLeft:'5px' }}
                    onClick={() => window.open(`https://testnet.bscscan.com/address/${poolInfo.stakeAddress}`, '_blank')}
                  >
                    <ExternalLink size={16} />
                  </button>
                </div>
              </div>

              <div
                style={{
                  display:'flex', 
                  justifyContent:'space-between',
                  padding:'8px 0', 
                  borderBottom:'1px solid rgba(255,255,255,0.1)'
                }}
              >
                <span style={{ color:'#7B91B0' }}>Token Address</span>
                <div style={{ display:'flex', alignItems:'center' }}>
                  <span
                    style={{
                      background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      marginRight:'6px'
                    }}
                  >
                    {isMobile ? shortAddress(poolInfo.tokenAddress) : poolInfo.tokenAddress}
                  </span>
                  <button
                    style={{ background:'none', border:'none', color:'#7B91B0', cursor:'pointer' }}
                    onClick={() => navigator.clipboard.writeText(poolInfo.tokenAddress)}
                  >
                    <Copy size={16} />
                  </button>
                  <button
                    style={{ background:'none', border:'none', color:'#7B91B0', cursor:'pointer', marginLeft:'5px' }}
                    onClick={() => window.open(`https://testnet.bscscan.com/address/${poolInfo.tokenAddress}`, '_blank')}
                  >
                    <ExternalLink size={16} />
                  </button>
                </div>
              </div>

              {/* etc... Show APY, totalStaked, userStaked, etc. */}
              <div
                style={{
                  display:'flex', 
                  justifyContent:'space-between',
                  padding:'8px 0', 
                  borderBottom:'1px solid rgba(255,255,255,0.1)'
                }}
              >
                <span style={{ color:'#7B91B0' }}>Total Staked</span>
                <span>{poolInfo.totalStaked}</span>
              </div>
              <div
                style={{
                  display:'flex', 
                  justifyContent:'space-between',
                  padding:'8px 0', 
                  borderBottom:'1px solid rgba(255,255,255,0.1)'
                }}
              >
                <span style={{ color:'#7B91B0' }}>My Staked</span>
                <span>{poolInfo.userStaked}</span>
              </div>
              <div
                style={{
                  display:'flex', 
                  justifyContent:'space-between',
                  padding:'8px 0', 
                  borderBottom:'1px solid rgba(255,255,255,0.1)'
                }}
              >
                <span style={{ color:'#7B91B0' }}>My Rewards</span>
                <span>{poolInfo.availableRewards} {poolInfo.tokenSymbol}</span>
              </div>
            </div>
          ) : (
            // STAKE TAB
            <div style={{ display:'grid', gap:'20px', gridTemplateColumns:'1fr' }}>
              {/* Chart area */}
              <div 
                style={{ 
                  backgroundColor:'#1A1B26', 
                  borderRadius:'12px', 
                  padding:'16px',
                  height: chartHeight 
                }}
              >
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={chartData}>
                    <defs>
                      <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor="#3461FF" stopOpacity={0.3} />
                        <stop offset="95%" stopColor="#3461FF" stopOpacity={0} />
                      </linearGradient>
                    </defs>
                    <XAxis dataKey="timestamp" hide={true} />
                    <YAxis hide={true} />
                    <Tooltip
                      contentStyle={{
                        background:'#161A42',
                        border:'1px solid #3461FF',
                        borderRadius:'8px',
                        color:'#FFF'
                      }}
                    />
                    <Area
                      type="monotone"
                      dataKey="value"
                      stroke="#3461FF"
                      fillOpacity={1}
                      fill="url(#colorValue)"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </div>

              {/* Staking input */}
              <div style={{ backgroundColor:'#1A1B26', borderRadius:'12px', padding:'16px' }}>
                <div 
                  style={{ 
                    display:'flex', 
                    justifyContent:'space-between', 
                    marginBottom:'10px' 
                  }}
                >
                  <span style={{ color:'#7B91B0' }}>Enter Amount</span>
                  <button
                    onClick={() => setInputAmount(poolInfo.userBalance)}
                    style={{
                      color:'#22C55E', background:'none', border:'none',
                      cursor:'pointer', fontSize:'1rem'
                    }}
                  >
                    MAX
                  </button>
                </div>
                <input
                  type="text"
                  value={inputAmount}
                  onChange={(e) => setInputAmount(e.target.value)}
                  placeholder="0.0"
                  style={{
                    width:'100%', backgroundColor:'transparent', color:'#FFF',
                    fontSize:'1.2rem', border:'none', outline:'none'
                  }}
                />
                <div style={{ color:'#7B91B0', marginTop:'10px' }}>
                  Balance: {poolInfo.userBalance} {poolInfo.tokenSymbol}
                </div>
              </div>

              {/* Action Buttons */}
              <div style={{ 
                display:'flex',
                gap:'10px',
                flexDirection: isMobile ? 'column' : 'row',
              }}>
                <button
                  onClick={handleStake}
                  disabled={loading}
                  style={{
                    flex:1, padding:'12px', backgroundColor:'#3461FF', color:'#FFF',
                    fontSize:'1rem', fontWeight:'500', borderRadius:'8px',
                    cursor:'pointer', opacity:loading ? 0.5:1
                  }}
                >
                  {loading ? "Processing..." : "Stake"}
                </button>
                <button
                  onClick={handleWithdraw}
                  disabled={loading}
                  style={{
                    flex:1, padding:'12px', backgroundColor:'#1A1B26', color:'#FFF',
                    fontSize:'1rem', fontWeight:'500', border:'1px solid #3461FF',
                    borderRadius:'8px', cursor:'pointer', opacity:loading ? 0.5:1
                  }}
                >
                  {loading ? "Processing..." : "Withdraw"}
                </button>
              </div>

              {/* Rewards section */}
              <div style={{ backgroundColor:'#1A1B26', borderRadius:'12px', padding:'16px' }}>
                <div style={{ display:'flex', justifyContent:'space-between' }}>
                  <span style={{ color:'#7B91B0' }}>My Staked Amount</span>
                  <span style={{ color:'#FFF' }}>
                    {poolInfo.userStaked} {poolInfo.tokenSymbol}
                  </span>
                </div>
                <div style={{ display:'flex', justifyContent:'space-between', marginTop:'10px' }}>
                  <span style={{ color:'#7B91B0' }}>My Rewards</span>
                  <span style={{ color:'#FFF' }}>
                    {poolInfo.availableRewards} {poolInfo.tokenSymbol}
                  </span>
                </div>

                {/* Token address row */}
                <div style={{ display:'flex', justifyContent:'space-between', marginTop:'10px' }}>
                  <span style={{ color:'#7B91B0' }}>
                    {isMobile ? 'Token' : 'Token Address'}
                  </span>
                  <div style={{ display:'flex', alignItems:'center' }}>
                    <span style={{
                      background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                      marginRight:'5px'
                    }}>
                      {isMobile 
                        ? shortAddress(poolInfo.tokenAddress) 
                        : poolInfo.tokenAddress}
                    </span>
                    <button
                      style={{ background:'none', border:'none', color:'#7B91B0', cursor:'pointer' }}
                      onClick={() => navigator.clipboard.writeText(poolInfo.tokenAddress)}
                    >
                      <Copy size={16} />
                    </button>
                    <button
                      style={{ background:'none', border:'none', color:'#7B91B0', cursor:'pointer', marginLeft:'5px' }}
                      onClick={() => window.open(`https://testnet.bscscan.com/address/${poolInfo.tokenAddress}`, '_blank')}
                    >
                      <ExternalLink size={16} />
                    </button>
                  </div>
                </div>

                {Number(poolInfo.availableRewards) > 0 && (
                  <button
                    onClick={handleClaimRewards}
                    disabled={loading}
                    style={{
                      width:'100%',
                      padding:'12px',
                      background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
                      border:'none',
                      borderRadius:'8px',
                      color:'#FFF',
                      fontSize:'1rem',
                      fontWeight:'500',
                      cursor:'pointer',
                      marginTop:'20px',
                      opacity:loading ? 0.5:1
                    }}
                  >
                    {loading ? "Processing..." : "Claim Rewards"}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default StakingPool;
