// EthWidget.js
import React, { useEffect, useState } from "react";
import EthLogo from "../images/etherum1.png"; // adjust path

function EthWidget() {
  const [price, setPrice] = useState(null);       // current displayed price
  const [isLive, setIsLive] = useState(false);    // true if fetch is fresh
  const [error, setError] = useState(null);

  useEffect(() => {
    // 1) On mount, read last known price from localStorage if any
    const savedPrice = localStorage.getItem("ethPriceCache");
    if (savedPrice) {
      setPrice(Number(savedPrice));
      setIsLive(false); // not fresh until we fetch
    }

    // 2) Attempt fresh fetch
    const fetchEthData = async () => {
      try {
        const url = "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd";
        const resp = await fetch(url);
        if (!resp.ok) throw new Error(`ETH fetch error: ${resp.status}`);

        const data = await resp.json();
        if (!data.ethereum) throw new Error("No ETH data from CoinGecko");

        const { usd } = data.ethereum;
        setPrice(usd);
        setIsLive(true);
        setError(null);

        localStorage.setItem("ethPriceCache", String(usd));
      } catch (err) {
        // Keep old cached price, mark isLive false
        setIsLive(false);
        setError(err.message || "ETH fetch error");
      }
    };

    fetchEthData();
  }, []);

  // 3) If truly no price (no cache, no fetch), show minimal
  if (!price) {
    return (
      <div style={styles.widget}>
        <span style={{ fontSize: 12, color: "#ff4d4f" }}>Loading...</span>
      </div>
    );
  }

  // 4) Round to no decimals
  const displayPrice = Math.round(price);

  return (
    <div style={styles.container}>
      {/* Use dotGreen if isLive, else dotRed */}
      <div style={isLive ? styles.dotGreen : styles.dotRed} />

      <div style={styles.widget}>
        <img
          src={EthLogo}
          alt="ETH"
          style={{ width: 16, height: 16, marginRight: 5, marginLeft: -3 }}
        />
        <span style={{ fontSize: 12, color: "#cccccc" }}>
          ${displayPrice}
        </span>
      </div>
    </div>
  );
}

export default EthWidget;

const styles = {
  container: {
    position: "relative", // so we can position the dot
  },
  widget: {
    width: 80,
    height: 32,
    borderRadius: 8,
    border: "1px solid #4DFFFFFF",
    background: "linear-gradient(180deg, #161A42 0%, #161A42 0%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Quicksand, sans-serif",
    color: "#FFFFFF",
    marginRight: 5,
  },
  dotGreen: {
    position: "absolute",
    top: 5,
    right: 10,
    width: 4,
    height: 4,
    borderRadius: "50%",
    backgroundColor: "#57d873",         // calmer green
    boxShadow: "0 0 6px #57d873",       // subtle green glow
    zIndex: 2,
  },
  dotRed: {
    position: "absolute",
    top: 5,
    right: 10,
    width: 4,
    height: 4,
    borderRadius: "50%",
    backgroundColor: "#ff7676",         // calmer red
    boxShadow: "0 0 6px #ff7676",       // subtle red glow
    zIndex: 2,
  },
};
