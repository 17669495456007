// ChartButton.jsx
import React from 'react';

const ChartButton = () => {
  const handleClick = () => {
    window.open('https://dexscreener.com/base/0x3faba4adb18a363ce356c0fd32919e642570a42d', '_blank');
  };

  return (
    <div className="chart-container" style={{ display: 'inline-block' }}>
      <button 
        onClick={handleClick}
        className="chart-button"
      >
        CHART
      </button>
      <style jsx>{`
        @keyframes  {
          0% { box-shadow: 0 0 5px #6366f1, 0 0 10px #6366f1, 0 0 12px #8b5cf6; }
          50% { box-shadow: 0 0 7px #6366f1, 0 0 15px #6366f1, 0 0 15px #8b5cf6; }
          100% { box-shadow: 0 0 5px #6366f1, 0 0 10px #6366f1, 0 0 12px #8b5cf6; }
        }

        .chart-button {
          background: #3461FF;
          color: #FFFFFF;
          padding: 6px 12px;
          font-size: 12px;
          font-weight: normal;
          border-radius: 8px;
          height: 32px;
          border: 0px solid #4DFFFFFF;
          cursor: pointer;
          margin-left: 10px;
          animation: glowingChart 2s infinite;
          transition: all 0.3s ease;
          font-family: 'Quicksand', sans-serif; 

        }

        .chart-button:hover {
          animation: none;
          box-shadow: 0 0 15px #6366f1, 0 0 20px #8b5cf6, 0 0 25px #8b5cf6;
        }


        }
      `}</style>
    </div>
  );
};

export default ChartButton;
