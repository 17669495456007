import React, { useState } from "react";
import { useWeb3React } from "@web3-react/core"; 
import { toast } from "react-toastify";
import { ethers } from "ethers";

// Put your factory's full ABI in a separate .json if you prefer
const TOKEN_FACTORY_ABI = [
  {
    "inputs": [
      { "internalType": "address", "name": "_uniswapV2Router", "type": "address" },
      { "internalType": "address", "name": "_WETH", "type": "address" },
      { "internalType": "address", "name": "_feeWallet", "type": "address" }
    ],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "inputs": [
      { "internalType": "string",  "name": "_name",             "type": "string"  },
      { "internalType": "string",  "name": "_symbol",           "type": "string"  },
      { "internalType": "uint256", "name": "_totalSupply",      "type": "uint256" },
      { "internalType": "uint256", "name": "_tokenAmountForLP", "type": "uint256" }
    ],
    "name": "createTokenAndAddLiquidity",
    "outputs": [
      { "internalType": "address", "name": "tokenAddress", "type": "address" },
      { "internalType": "address", "name": "pairAddress",  "type": "address" }
    ],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "FEE_FIXED",
    "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "WETH",
    "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "feeWallet",
    "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "uniswapV2Router",
    "outputs": [{ "internalType": "address", "name": "", "type": "address" }],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "anonymous": false,
    "inputs": [
      { "indexed": true,  "internalType": "address", "name": "token", "type": "address" },
      { "indexed": true,  "internalType": "address", "name": "owner", "type": "address" }
    ],
    "name": "TokenCreated",
    "type": "event"
  },
  {
    "anonymous": false,
    "inputs": [
      { "indexed": true,  "internalType": "address", "name": "token",       "type": "address" },
      { "indexed": false, "internalType": "uint256", "name": "tokenAmount", "type": "uint256" },
      { "indexed": false, "internalType": "uint256", "name": "ethAmount",   "type": "uint256" },
      { "indexed": false, "internalType": "address", "name": "pair",        "type": "address" }
    ],
    "name": "LiquidityAdded",
    "type": "event"
  }
];

// Your factory address on Base:
const FACTORY_ADDRESS = "0x82Afb4ec685952F1baFdF0FBF9D4bbe1C5AC2713";

/**
 * QuickLaunch:
 * A single-page React component to let users:
 * 1) Enter a token name, symbol, total supply, portion for LP, and ETH for LP
 * 2) Calls `createTokenAndAddLiquidity(...)` on the factory 
 * 3) Relies on your existing @web3-react wallet connection (Metamask, etc.)
 */
export default function QuickLaunch() {
  const { account, library } = useWeb3React();  
  const [tokenName,   setTokenName]   = useState("");
  const [tokenSymbol, setTokenSymbol] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [lpTokens,    setLpTokens]    = useState("");
  const [ethForLP,    setEthForLP]    = useState("");
  const [isLoading,   setIsLoading]   = useState(false);

  // for convenience
  const { parseEther, parseUnits } = ethers.utils;

  function isFormValid() {
    if (!tokenName.trim() || !tokenSymbol.trim()) return false;
    if (!totalSupply.trim() || isNaN(Number(totalSupply))) return false;
    if (!lpTokens.trim()    || isNaN(Number(lpTokens)))   return false;
    if (!ethForLP.trim()    || isNaN(Number(ethForLP)))   return false;
    return true;
  }

  async function handleCreateToken() {
    if (!account) {
      toast.error("Please connect your wallet first!");
      return;
    }
    if (!isFormValid()) {
      toast.error("Fill out all fields with valid numbers!");
      return;
    }

    try {
      setIsLoading(true);

      // leftoverEth from user input plus 0.01 ETH fee
      const leftover   = parseEther(ethForLP);
      const feeFixed   = parseEther("0.01");
      const totalValue = leftover.add(feeFixed);

      // parse user-typed supply & LP tokens to big decimals
      const supplyBig = parseUnits(totalSupply, 18);
      const lpBig     = parseUnits(lpTokens, 18);

      // create ethers contract w/ signer from library
      const signer = library.getSigner(account);
      const factory = new ethers.Contract(FACTORY_ADDRESS, TOKEN_FACTORY_ABI, signer);

      // submit transaction
      const tx = await factory.createTokenAndAddLiquidity(
        tokenName,
        tokenSymbol,
        supplyBig, 
        lpBig,
        { value: totalValue }
      );

      toast.info("Transaction submitted. Waiting for confirmation...");
      const receipt = await tx.wait();
      setIsLoading(false);

      if (receipt && receipt.status === 1) {
        toast.success("Token Created & Liquidity Added!");
        console.log("Receipt logs:", receipt.logs);
      } else {
        toast.error("Transaction failed or was reverted!");
      }

    } catch (err) {
      console.error(err);
      toast.error(err.reason || err.message || "Error deploying token!");
      setIsLoading(false);
    }
  }

  // inline styles
  const containerStyle = {
    maxWidth: "600px",
    margin: "40px auto",
    padding: "20px",
    background: "#111432",
    borderRadius: "12px",
    boxShadow: "0 0 20px rgba(0,0,0,0.5)",
    color: "#fff",
    fontFamily: "Quicksand, sans-serif"
  };
  const titleStyle = {
    textAlign: "center",
    color: "#6EE7B7",
    marginBottom: "20px"
  };
  const labelStyle = { marginBottom: "5px", color: "#98A7B5" };
  const inputStyle = {
    height: "40px",
    width: "100%",
    background: "linear-gradient(162deg, #161A42 0%, rgba(22,26,66,0) 100%)",
    borderRadius: "8px",
    border: "2px solid #212554",
    padding: "5px 10px",
    color: "#fff",
    marginBottom: "15px"
  };
  const buttonStyle = {
    width: "100%",
    height: "45px",
    borderRadius: "8px",
    border: "none",
    background: "linear-gradient(90deg, #3461FF 0%, #8454EB 100%)",
    color: "#fff",
    fontWeight: "600",
    cursor: "pointer",
    marginTop: "10px"
  };
  const noteStyle = {
    marginTop: "15px",
    textAlign: "center",
    color: "#7B91B0",
    fontSize: "13px"
  };

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>Quick Launch</h2>

      <div>
        <label style={labelStyle}>Token Name</label>
        <input
          style={inputStyle}
          placeholder="e.g. MyMemeCoin"
          value={tokenName}
          onChange={(e) => setTokenName(e.target.value)}
        />
      </div>
      <div>
        <label style={labelStyle}>Token Symbol</label>
        <input
          style={inputStyle}
          placeholder="e.g. MEME"
          value={tokenSymbol}
          onChange={(e) => setTokenSymbol(e.target.value)}
        />
      </div>
      <div>
        <label style={labelStyle}>Total Supply</label>
        <input
          style={inputStyle}
          placeholder="e.g. 1000000000"
          value={totalSupply}
          onChange={(e) => setTotalSupply(e.target.value)}
        />
      </div>
      <div>
        <label style={labelStyle}>How many tokens go to LP?</label>
        <input
          style={inputStyle}
          placeholder="e.g. 500000000"
          value={lpTokens}
          onChange={(e) => setLpTokens(e.target.value)}
        />
      </div>
      <div>
        <label style={labelStyle}>ETH for LP (besides 0.01 fee)</label>
        <input
          style={inputStyle}
          placeholder="e.g. 0.1"
          value={ethForLP}
          onChange={(e) => setEthForLP(e.target.value)}
        />
      </div>

      <button 
        style={buttonStyle} 
        disabled={isLoading}
        onClick={handleCreateToken}
      >
        {isLoading ? "Creating..." : "Create & Launch"}
      </button>

      <p style={noteStyle}>
        You’ll pay <b>0.01 ETH</b> fee plus whatever ETH you specify for LP.
      </p>
    </div>
  );
}
