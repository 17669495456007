import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import templateImg from "../images/template.jpg";

/* Adjust paths if needed */
import PriceWidget from './PriceWidget';
import EthWidget from './EthWidget';
import BnbWidget from './BnbWidget';

const TopPumpSellers = ({ bondingStats }) => {
  const isMobile = useMediaQuery('(max-width:768px)');
  console.log("TopPumpSellers - isMobile?", isMobile);

  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();

  // Filter & sort top sellers
  const getTopSellers = () => {
    if (!bondingStats?.poolList) return [];
    const MINIMUM_MARKET_CAP = 1000;
    const filtered = bondingStats.poolList
      .filter(pool => {
        const marketCap = Number(pool.currentMK);
        return !isNaN(marketCap) && marketCap >= MINIMUM_MARKET_CAP;
      })
      .sort((a, b) => Number(b.currentMK) - Number(a.currentMK))
      .slice(0, 10);

    // Repeats your data multiple times
    return [
      ...filtered, ...filtered, ...filtered, ...filtered, ...filtered,
      ...filtered, ...filtered, ...filtered, ...filtered, ...filtered,
      ...filtered, ...filtered, ...filtered, ...filtered, ...filtered,
      ...filtered, ...filtered, ...filtered, ...filtered
    ];
  };

  const topSellers = getTopSellers();

  // Infinite scroll effect
  useEffect(() => {
    if (topSellers.length === 0) return;

    const scrollSpeed = 1;
    let animationFrameId;
    let lastTimestamp = 0;

    const animate = (timestamp) => {
      if (!lastTimestamp) lastTimestamp = timestamp;
      const delta = timestamp - lastTimestamp;

      setScrollPosition(prev => {
        const newPosition = prev - (scrollSpeed * delta) / 16;
        const resetPoint = -(topSellers.length * 150) / 2;
        return newPosition <= resetPoint ? 0 : newPosition;
      });

      lastTimestamp = timestamp;
      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrameId);
  }, [topSellers.length]);

  const handleProjectClick = (poolAddress) => {
    if (poolAddress) {
      navigate(`/bonding-details/${poolAddress}`);
    }
  };

  // If empty
  if (topSellers.length === 0) return null;

  return (
    <div>
      {/* MOBILE-ONLY: 3 widgets (DEFI, ETH, BNB), spaced evenly, no Buy button */}
      {isMobile && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-between', // space items across width
            alignItems: 'center',
            margin: 0,
            padding: 0,
            marginBottom: 15, 
          }}
        >
          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <PriceWidget />
          </div>

          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <EthWidget />
          </div>

          <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
            <BnbWidget />
          </div>
        </div>
      )}

      {/* EXISTING Pump Sellers */}
      <div style={{ padding: '0 10px', marginBottom: '0px' }}>
        <div style={{ 
          display: 'flex', 
          justifyContent: 'flex-start', 
          alignItems: 'center',
          marginBottom: isMobile ? '4px' : '8px',
          gap: isMobile ? '75px' : '20px',
          marginTop: '4px',
          paddingBottom: isMobile ? '0' : '4px'
        }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
            <span style={{ 
              color: '#6EE7B7', 
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: '500'
            }}>
              🔥 {isMobile ? 'Pump' : 'Pump Sale'}
            </span>
            <span style={{ 
              color: '#7B91B0', 
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: '500'
            }}>
              &gt;
            </span>
            <span style={{ 
              color: '#6EE7B7', 
              fontSize: isMobile ? '14px' : '16px',
              fontWeight: '500'
            }}>
              Hot Sellers
            </span>
          </div>
          <Link 
            to="/bonding-token-sale"
            style={{
              backgroundColor: '#3461FF',
              color: 'white',
              padding: isMobile ? '7px 12px' : '6px 20px',
              borderRadius: '8px',
              fontSize: '12px',
              textDecoration: 'none',
              fontWeight: '500',
              marginBottom: '4px'
            }}
          >
            {isMobile ? 'CREATE PUMP' : 'CREATE PUMP'}
          </Link>
        </div>

        <div style={{ 
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
          height: isMobile ? '45px' : '55px',
          marginBottom: '1px'
        }}>
          <div style={{ 
            display: 'flex',
            alignItems: 'center',
            position: 'absolute',
            transform: `translateX(${scrollPosition}px)`,
            transition: 'transform 0.1s linear',
            gap: '24px',
            padding: 0
          }}>
            {topSellers.map((seller, index) => (
              <div 
                key={`${seller.poolAddress}-${index}`}
                onClick={() => handleProjectClick(seller.poolAddress)}
                style={{ 
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  minWidth: 'max-content',
                  transition: 'transform 0.2s ease',
                }}
              >
                {/* If the user didn’t upload a valid image, fallback to template.jpg */}
                <img
                  src={seller.logourl || '/placeholder.png'}
                  alt={seller.name}
                  onError={(e) => {
                    e.target.src = templateImg;
                  }}
                  style={{
                    width: isMobile ? '34px' : '42px',
                    height: isMobile ? '34px' : '42px',
                    borderRadius: '6px',
                    objectFit: 'cover'
                  }}
                />
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '2px'
                }}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '6px'
                  }}>
                    <span style={{ 
                      color: 'white', 
                      fontSize: isMobile ? '13px' : '15px',
                      fontWeight: '500'
                    }}>
                      {seller.name}
                    </span>
                    <div style={{
                      width: isMobile ? '10px' : '12px',
                      height: isMobile ? '10px' : '12px',
                      backgroundColor: '#00FF00',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: isMobile ? '7px' : '8px',
                      color: 'white'
                    }}>
                      ✓
                    </div>
                  </div>
                  <span style={{ 
                    color: '#6EE7B7', 
                    fontSize: isMobile ? '13px' : '15px',
                    fontWeight: '500'
                  }}>
                    ${Number(seller.currentMK).toLocaleString(undefined, {
                      maximumFractionDigits: 0,
                    })}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopPumpSellers;
