import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainLayout from "./layouts/main";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/home/Home";
import Bridge from "./pages/home/Bridge";
import PreSale from "./pages/launchpadApply/Presale";
import PrivateSale from "./pages/launchpadApply/PrivateSale";
import DetailsComp from "./pages/launchpadApply/presaleview/DetailsComp";
import DetailsCompPrivatesale from "./pages/launchpadApply/privatesaleview/DetailsCompPrivatesale";
import ProjectList from "./pages/launchpadApply/SaleList/ProjectList";
import PrvProjectList from "./pages/launchpadApply/PrvSaleList/PrvProjectList";
import PrvContributions from "./pages/launchpadApply/PrvSaleList/component/PrvContributions";
import MyContributions from "./pages/launchpadApply/SaleList/component/MyContributions";
import Fairsale from "./pages/launchpadApply/Fairsale";
import DetailsBondingComp from "./pages/launchpadApply/bondingsaleview/DetailsBondingComp";
import DetailsFairComp from "./pages/launchpadApply/fairsaleview/DetailsFairComp";
import MainLock from "./pages/lock/MainLock";
import MainToken from "./pages/token/MainToken";
import AirdropEth from "./pages/airdrop/AirdropEth";
import AirdropTokens from "./pages/airdrop/AirdropTokens";
import TokenDetails from "./pages/token/TokenDetails";
import TokenLockList from "./pages/locklist/TokenLockList";
import LockView from "./pages/locklist/LockView";
import LockRecord from "./pages/locklist/LockRecord";
import MyTokenLock from "./pages/locklist/MyTokenLock";
import MyLpLock from "./pages/locklist/MyLpLock";
import KycAudit from "./component/KycAudit";
import LpLockList from "./pages/locklist/LpLockList";
import BondingToken from "./pages/token/BondingToken";
import Favorites from "./pages/launchpadApply/SaleList/Favorites";
import { config } from "./config";
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createWeb3Modal } from '@web3modal/wagmi/react';
import ProjectDetails from "./pages/launchpadApply/fairsaleview/ProjectDetails";
import ProjectBondingDetails from "./pages/launchpadApply/bondingsaleview/ProjectBondingDetails";
import Chat from './component/Chat';
import NewChat from './component/NewChat';
import WalletPage from './pages/wallet';
import WalletAnalytics from './component/WalletAnalytics';
import StakingPool from './pages/staking/StakingPool';
import StakingPoolsList from './pages/staking/StakingPoolsList';
import BottomNavigation from "./component/BottomNavigation";
import CreatePool from './pages/staking/CreatePool'; 
import BaseTokenTracker from './pages/tokenTracker/BaseTokenTracker';
import QuickLaunch from './pages/token/QuickLaunch';
import DefiHolders from "./component/DefiHolders";






const globalChainID = 56;

const queryClient = new QueryClient();
const projectId = 'e163def497d4ac886a3b3423c66e9a66';

createWeb3Modal({
  autoConnect: false,
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
});

function App() {
  return (
    <Router>
      <div className="App">
        <WagmiProvider config={config}>
          <QueryClientProvider client={queryClient}>
            <ToastContainer pauseOnFocusLoss={false} />
            <MainLayout>
              <Routes>
                {/* Other Routes */}
                <Route path="/" element={<ProjectList />} />
                <Route path="/bridge" element={<Bridge />} />
                <Route path="/presale-details" element={<DetailsComp />} />
                <Route path="/private-details" element={<DetailsCompPrivatesale />} />
                <Route path="/bonding-details/:address" element={<ProjectBondingDetails />} />
                <Route path="/fairlaunch-details/:address" element={<ProjectDetails />} />
                <Route path="/presale" element={<PreSale />} />
                <Route path="/privatesale" element={<PrivateSale />} />
                <Route path="/fairlaunch" element={<Fairsale />} />
                <Route path="/bonding-token-sale" element={<BondingToken />} />
                <Route path="/sale-list" element={<ProjectList />} />
                <Route path="/prvsale-list" element={<PrvProjectList />} />
                <Route path="/my-contribution" element={<MyContributions />} />
                <Route path="/prv-contribution" element={<PrvContributions />} />
                <Route path="/lock" element={<MainLock />} />
                <Route path="/token/:tokenType" element={<MainToken />} />
                <Route path="/token-details" element={<TokenDetails />} />
                <Route path="/airdrop-eth" element={<AirdropEth />} />
                <Route path="/airdrop-tokens" element={<AirdropTokens />} />
                <Route path="/token-locked" element={<TokenLockList />} />
                <Route path="/liquidity-locked" element={<LpLockList />} />
                <Route path="/lock-details/:id" element={<LockView />} />
                <Route path="/lock-record/:id" element={<LockRecord />} />
                <Route path="/my-token-lock" element={<MyTokenLock />} />
                <Route path="/my-lp-lock" element={<MyLpLock />} />
                <Route path="/kycaudit" element={<KycAudit />} />
                <Route path="/favorites" element={<Favorites />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/new-chat" element={<NewChat />} />
                <Route path="/wallet" element={<WalletAnalytics />} />
                <Route path="/staking" element={<StakingPoolsList />} />   
                <Route path="/staking/:poolAddress" element={<StakingPool />} />
                <Route path="/create-pool" element={<CreatePool />} />
                <Route path="/base-token-tracker" element={<BaseTokenTracker />} />
                <Route path="/quick-launch" element={<QuickLaunch />} />
                <Route path="/defi-holders" element={<DefiHolders />} />





              </Routes>
            </MainLayout>

            {/* Mobile Bottom Navigation Bar */}
            <BottomNavigation />
            
          </QueryClientProvider>
        </WagmiProvider>
      </div>
    </Router>
  );
}

export default App;
