import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import StakingPoolCard from './StakingPoolCard';
import stakingFactoryArtifact from '../../json/StakingFactory.json';
import SteakandEarn from '../../images/SteakEarn.png';
import { FaSearch } from 'react-icons/fa';

// ---------- STYLES ----------
const outerContainerStyle = (isMobile) => ({
  width: '95%',
  margin: '40px auto',
  background: 'linear-gradient(90deg, #6604FD 0%, #01F4A4 100%)',
  borderRadius: '12px',
  padding: '2px',
});

const cardStyle = {
  backgroundColor: 'var(--bcgame-sk-woodsmoke, #111415)',
  borderRadius: '12px',
  padding: '20px',
  color: '#FFFFFF',
  fontFamily: 'Quicksand, Helvetica, sans-serif',
};

function gradientButton(isMobile) {
  return {
    display: 'inline-block',
    padding: isMobile ? '6px 12px' : '6px 12px',
    borderRadius: '8px',
    border: 'none',
    cursor: 'pointer',
    background: 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)',
    color: '#FFF',
    fontSize: '13px',
    fontWeight: '500',
    lineHeight: '1',
    height: '36px',
  };
}

const labelStyle = {
  display: 'block',
  color: '#98A7B5',
  marginBottom: '6px',
  fontSize: '14px',
  fontWeight: 400,
};

const inputStyle = {
  width: '100%',
  padding: '12px',
  borderRadius: '8px',
  border: '1px solid rgba(255,255,255,0.1)',
  backgroundColor: '#1A1B1E',
  color: '#FFFFFF',
  outline: 'none',
  fontSize: '14px',
  marginBottom: '10px',
};

const modalContainer = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0,0,0,0.5)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000,
};

const modalCard = {
  backgroundColor: '#161A42',
  borderRadius: '12px',
  padding: '32px',
  maxWidth: '600px',
  width: '90%',
  position: 'relative',
};

const toggleContainerStyle = {
  display: 'inline-flex',
  borderRadius: '8px',
  overflow: 'hidden',
  border: '1px solid rgba(255, 255, 255, 0.2)',
  whiteSpace: 'nowrap',
  flexShrink: 0,
  height: '36px',
};

function toggleButtonStyle(active) {
  return {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '60px',
    padding: '0 12px',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    fontSize: '13px',
    fontWeight: 500,
    transition: 'background 0.3s',
    background: active
      ? 'linear-gradient(90deg, #3461FF 0%, #8454EB 100%)'
      : '#1A1B1E',
    color: active ? '#FFF' : '#AAA',
    height: '100%',
  };
}

// Minimal ABIs
const erc20Abi = [
  {
    inputs: [],
    name: 'symbol',
    outputs: [{ internalType: 'string', name: '', type: 'string' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
];

// NOTE: includes getReward so we can properly read the contract
const stakingRewardsAbi = [
  {
    inputs: [],
    name: 'stakingToken',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'rewardsToken',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'periodFinish',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function',
  },
  // getReward added here
  {
    inputs: [],
    name: 'getReward',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

// Factory
const factoryAbi = stakingFactoryArtifact.abi;

// Multi-chain config
const BASE_CHAIN_ID = 8453;
const chainConfigs = {
  97: {
    factoryAddress: '0xa8CB8AcD0d5270F4F84552635792F0EAEb5Ed7e8',
    creationFee: '10000000000000000',
  },
  84531: {
    factoryAddress: '0x336F4e52df25fCCdCeF21ADd486Dba0187ee9a95',
    creationFee: '2000000000000000',
  },
  [BASE_CHAIN_ID]: {
    factoryAddress: '0x1A89142beD953DB1a05e5Ee7632488274377219c',
    creationFee: '2000000000000000',
  },
  59144: {
    factoryAddress: '0x3Cb523fB32a7e5Cc5a8f55765543E1b0C0b23C20',
    creationFee: '2000000000000000',
  },
};

// APY Calc
function computeApy(rewardBalance, secsLeft, totalStaked) {
  const r = parseFloat(rewardBalance);
  const s = parseFloat(totalStaked);
  const d = parseFloat(secsLeft);
  if (r <= 0 || s <= 0 || d <= 0) return '0.0';

  const rewardRateSec = r / d;
  const rewardPerYear = rewardRateSec * 365 * 24 * 3600;
  const apyValue = (rewardPerYear / s) * 100;
  return apyValue.toFixed(2);
}

function formatDurationNoSeconds(secs) {
  if (secs <= 0) return '0m';
  const d = Math.floor(secs / 86400);
  const h = Math.floor((secs % 86400) / 3600);
  const m = Math.floor((secs % 3600) / 60);
  let out = '';
  if (d > 0) out += `${d}d `;
  if (h > 0) out += `${h}h `;
  if (m > 0) out += `${m}m`;
  return out || '0m';
}

export default function StakingPoolsList() {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:768px)');

  const [poolsMeta, setPoolsMeta] = useState({});
  const [pools, setPools] = useState([]);

  // NEW: We'll track when we're actively fetching (and have no cache)
  const [loadingPools, setLoadingPools] = useState(true);

  // CREATE POOL
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [createForm, setCreateForm] = useState({
    tokenAddress: '',
    apy: '',
    durationDays: '30',
    rewardAmount: '',
    bannerUrl: '',
    logoUrl: '',
  });

  // Manage Rewards
  const [showRewardsModal, setShowRewardsModal] = useState(false);
  const [selectedPool, setSelectedPool] = useState(null);
  const [topUpAmount, setTopUpAmount] = useState('');

  // Edit
  const [showEditModal, setShowEditModal] = useState(false);
  const [editPool, setEditPool] = useState(null);
  const [editBanner, setEditBanner] = useState('');
  const [editLogo, setEditLogo] = useState('');
  const [editName, setEditName] = useState('');

  // Toggle (Live vs Ended)
  const [showEnded, setShowEnded] = useState(false);

  // Search
  const [searchTerm, setSearchTerm] = useState('');

  // ----------------------------------------------------------------
  // On Page Load
  // ----------------------------------------------------------------
  useEffect(() => {
    let hadCached = false;

    // 1) Immediately load any cached Base chain pools from localStorage
    const cached = localStorage.getItem('poolsCacheBase');
    if (cached) {
      try {
        const arr = JSON.parse(cached);
        if (Array.isArray(arr) && arr.length > 0) {
          setPools(arr);
          hadCached = true;
          // If we found something in cache, we can show it right away
          setLoadingPools(false);
        }
      } catch (e) {
        console.warn('Failed to parse poolsCacheBase from localStorage:', e);
      }
    }

    // 2) Fetch server meta
    fetch("https://defilaunch.app/api/poolsmeta")
      .then((res) => res.json())
      .then((data) => {
        setPoolsMeta(data);
        // 3) Always load the Base chain pools in read-only mode
        loadPoolsForChain(BASE_CHAIN_ID, data).then(() => {
          // If no cache was found, now we can safely hide the spinner
          if (!hadCached) {
            setLoadingPools(false);
          }
          // If user has a wallet, we check if they're on Base
          if (window.ethereum) {
            checkWalletAndLoad(data);
          }
        });
      })
      .catch((err) => {
        console.error("Error fetching meta:", err);
        // If meta fails, still do base read
        loadPoolsForChain(BASE_CHAIN_ID, {}).then(() => {
          if (!hadCached) {
            setLoadingPools(false);
          }
          if (window.ethereum) {
            checkWalletAndLoad({});
          }
        });
      });
    // eslint-disable-next-line
  }, []);

  // Helper: if the user is on Base, load with wallet for advanced features
  async function checkWalletAndLoad(serverMeta) {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const chainId = await provider.getNetwork().then((n) => n.chainId);
      if (chainId === BASE_CHAIN_ID) {
        await loadPoolsWithWallet(serverMeta);
      }
    } catch (e) {
      console.warn('Could not load wallet-based pools:', e);
    }
  }

  async function updateServerMeta(poolAddress, bannerUrl, logoUrl, name) {
    const body = { poolAddress, bannerUrl, logoUrl, name };
    await fetch("https://defilaunch.app/api/poolsmeta/update", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    });
    const res = await fetch("https://defilaunch.app/api/poolsmeta");
    const data = await res.json();
    setPoolsMeta(data);
  }

  // ----------------------------------------------------------------
  // LOAD POOLS (READ-ONLY, e.g. Base chain)
  // ----------------------------------------------------------------
  async function loadPoolsForChain(chainId, serverMeta) {
    const chainCfg = chainConfigs[chainId];
    if (!chainCfg) {
      setPools([
        {
          id: 1,
          name: 'Unsupported Chain',
          tokenSymbol: '???',
          totalStaked: '???',
          rewardBalance: '???',
          timeRemaining: '???',
          liveStatus: false,
          bannerUrl: 'https://i.postimg.cc/2554h2pG/template-banner.jpg',
          logoUrl: 'https://i.postimg.cc/63NmBk4q/template.jpg',
          apy: '0.0',
          isOwner: false,
        },
      ]);
      return;
    }

    // Example base mainnet RPC
    const provider = new ethers.providers.JsonRpcProvider('https://mainnet.base.org');
    const factory = new ethers.Contract(chainCfg.factoryAddress, factoryAbi, provider);
    const count = await factory.poolCount();
    let newPools = [];

    for (let i = 0; i < count; i++) {
      const poolAddr = await factory.allStakingContracts(i);
      const stakingContract = new ethers.Contract(poolAddr, stakingRewardsAbi, provider);

      const [stakingTokenAddr, periodFinishBN, totalSupplyBN] = await Promise.all([
        stakingContract.stakingToken(),
        stakingContract.periodFinish(),
        stakingContract.totalSupply(),
      ]);

      let rewardBalance = '0.0';
      try {
        const rTokenAddr = await stakingContract.rewardsToken();
        const rTokenContract = new ethers.Contract(rTokenAddr, erc20Abi, provider);
        const rBalBN = await rTokenContract.balanceOf(poolAddr);
        rewardBalance = ethers.utils.formatUnits(rBalBN, 18);
      } catch {}

      const nowSec = Math.floor(Date.now() / 1000);
      const finishSec = periodFinishBN.toNumber();
      const secsLeft = finishSec - nowSec;
      const liveStatus = secsLeft > 0;
      const timeRemaining = liveStatus ? formatDurationNoSeconds(secsLeft) : 'Finished';

      let tokenSymbol = '???';
      try {
        const st = new ethers.Contract(stakingTokenAddr, erc20Abi, provider);
        tokenSymbol = await st.symbol();
      } catch {}

      const totalStaked = ethers.utils.formatUnits(totalSupplyBN, 18);

      const lower = poolAddr.toLowerCase();
      const meta = serverMeta[lower] || {};
      const poolName = meta.name || `${tokenSymbol} Pool`;
      const bannerUrl = meta.bannerUrl || 'https://i.postimg.cc/2554h2pG/template-banner.jpg';
      const logoUrl = meta.logoUrl || 'https://i.postimg.cc/63NmBk4q/template.jpg';

      const dynamicApy = computeApy(rewardBalance, secsLeft, totalStaked);

      newPools.push({
        id: poolAddr,
        name: poolName,
        tokenSymbol,
        totalStaked,
        rewardBalance,
        timeRemaining,
        liveStatus,
        bannerUrl,
        logoUrl,
        apy: dynamicApy,
        isOwner: false,
      });
    }

    // Update the UI
    setPools(newPools);
    // Cache them to localStorage for instant load next time
    localStorage.setItem('poolsCacheBase', JSON.stringify(newPools));
  }

  // ----------------------------------------------------------------
  // LOAD POOLS (WITH WALLET, e.g. to see isOwner, personal call)
  // ----------------------------------------------------------------
  async function loadPoolsWithWallet(serverMeta) {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send('eth_requestAccounts', []);
      const signer = provider.getSigner();
      const userAddr = await signer.getAddress();

      const chainId = await signer.getChainId();
      const chainCfg = chainConfigs[chainId];
      if (!chainCfg) {
        // user not on Base => do nothing
        return;
      }

      // Because we only do this if chainId === BASE_CHAIN_ID,
      // we re-fetch pools so we get the correct isOwner field
      const factory = new ethers.Contract(chainCfg.factoryAddress, factoryAbi, signer);
      const count = await factory.poolCount();

      let newPools = [];
      for (let i = 0; i < count; i++) {
        const poolAddr = await factory.allStakingContracts(i);
        const stakingContract = new ethers.Contract(poolAddr, stakingRewardsAbi, signer);

        const [stakingTokenAddr, periodFinishBN, totalSupplyBN, theOwner] = await Promise.all([
          stakingContract.stakingToken(),
          stakingContract.periodFinish(),
          stakingContract.totalSupply(),
          stakingContract.owner(),
        ]);

        let rewardBalance = '0.0';
        try {
          const rTokenAddr = await stakingContract.rewardsToken();
          const rTokenContract = new ethers.Contract(rTokenAddr, erc20Abi, signer);
          const rBalBN = await rTokenContract.balanceOf(poolAddr);
          rewardBalance = ethers.utils.formatUnits(rBalBN, 18);
        } catch {}

        const nowSec = Math.floor(Date.now() / 1000);
        const finishSec = periodFinishBN.toNumber();
        const secsLeft = finishSec - nowSec;
        const liveStatus = secsLeft > 0;
        const timeRemaining = liveStatus ? formatDurationNoSeconds(secsLeft) : 'Finished';

        let tokenSymbol = '???';
        try {
          const st = new ethers.Contract(stakingTokenAddr, erc20Abi, signer);
          tokenSymbol = await st.symbol();
        } catch {}

        const totalStaked = ethers.utils.formatUnits(totalSupplyBN, 18);

        const lower = poolAddr.toLowerCase();
        const meta = serverMeta[lower] || {};
        const poolName = meta.name || `${tokenSymbol} Pool`;
        const bannerUrl = meta.bannerUrl || 'https://i.postimg.cc/2554h2pG/template-banner.jpg';
        const logoUrl = meta.logoUrl || 'https://i.postimg.cc/63NmBk4q/template.jpg';

        const dynamicApy = computeApy(rewardBalance, secsLeft, totalStaked);
        const isOwner = theOwner.toLowerCase() === userAddr.toLowerCase();

        newPools.push({
          id: poolAddr,
          name: poolName,
          tokenSymbol,
          totalStaked,
          rewardBalance,
          timeRemaining,
          liveStatus,
          bannerUrl,
          logoUrl,
          apy: dynamicApy,
          isOwner,
        });
      }

      // If user is on Base w/ wallet, we override the pools with these
      setPools(newPools);
    } catch (error) {
      console.error('loadPoolsWithWallet =>', error);
    }
  }

  // ----------------------------------------------------------------
  // CREATE POOL
  // ----------------------------------------------------------------
  function handleCreatePoolClick() {
    setCreateForm({
      tokenAddress: '',
      apy: '',
      durationDays: '30',
      rewardAmount: '',
      bannerUrl: '',
      logoUrl: '',
    });
    setShowCreateModal(true);
  }

  function handleFormChange(e) {
    setCreateForm((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  async function handleCreatePool() {
    try {
      if (!window.ethereum) return alert('No wallet found');
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send('eth_requestAccounts', []);
      const signer = provider.getSigner();

      const chainId = await signer.getChainId();
      const chainCfg = chainConfigs[chainId];
      if (!chainCfg) return alert('Unsupported chain.');

      const factory = new ethers.Contract(chainCfg.factoryAddress, factoryAbi, signer);
      const oldCount = await factory.poolCount();

      const { tokenAddress, apy, durationDays, rewardAmount, bannerUrl, logoUrl } = createForm;
      if (!tokenAddress) return alert('Enter a valid token address');
      if (!rewardAmount || Number(rewardAmount) <= 0) return alert('Reward must be > 0');
      if (Number(apy) < 1) return alert('APY must be at least 1%!');
      const daysNum = parseInt(durationDays, 10);
      if (daysNum <= 0) return alert('Duration must be > 0 days.');

      const stakingToken = tokenAddress;
      const rewardsToken = tokenAddress;
      const durationSec = (daysNum * 86400).toString();

      const tx = await factory.createStakingPool(stakingToken, rewardsToken, durationSec, {
        value: chainCfg.creationFee,
      });
      const receipt = await tx.wait();
      if (!receipt.status) {
        return alert('Tx reverted/failed.');
      }

      let newPoolAddr;
      const evt = receipt.events.find((e) => e.event === 'PoolCreated');
      if (evt) {
        newPoolAddr = evt.args.poolAddress;
      } else {
        const newCount = await factory.poolCount();
        if (newCount.gt(oldCount)) {
          newPoolAddr = await factory.allStakingContracts(newCount.sub(1));
        } else {
          return alert('No new pool found. Possibly reverted?');
        }
      }

      // deposit reward
      const rewardWei = ethers.utils.parseUnits(rewardAmount, 18);
      const rewardTokenContract = new ethers.Contract(
        rewardsToken,
        [
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' },
            ],
            name: 'approve',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
        ],
        signer
      );
      await (await rewardTokenContract.approve(newPoolAddr, rewardWei)).wait();

      const sr = new ethers.Contract(
        newPoolAddr,
        [
          {
            inputs: [{ internalType: 'uint256', name: 'reward', type: 'uint256' }],
            name: 'notifyRewardAmount',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
        ],
        signer
      );
      await (await sr.notifyRewardAmount(rewardWei)).wait();

      // store banner/logo in meta server
      await updateServerMeta(newPoolAddr, bannerUrl, logoUrl, 'New Pool');

      alert('Single-sided pool created & reward deposited!\nReloading...');
      setShowCreateModal(false);

      // Reload base chain read (so it appears in the list),
      // then reload with wallet if on base
      await loadPoolsForChain(BASE_CHAIN_ID, poolsMeta);
      const currentChain = await signer.getChainId();
      if (currentChain === BASE_CHAIN_ID) {
        await loadPoolsWithWallet(poolsMeta);
      }
    } catch (err) {
      console.error('Create pool error:', err);
      alert(err.message);
    }
  }

  // ----------------------------------------------------------------
  // MANAGE REWARDS
  // ----------------------------------------------------------------
  function openRewardsModal(poolAddr) {
    setSelectedPool(poolAddr);
    setTopUpAmount('');
    setShowRewardsModal(true);
  }

  async function handleAddRewards() {
    try {
      if (!selectedPool) return alert('No pool selected');
      if (!topUpAmount || topUpAmount === '0') return alert('Enter reward amount!');

      if (!window.ethereum) return alert('No wallet found');
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send('eth_requestAccounts', []);
      const signer = provider.getSigner();

      const rewardTokenAddr = prompt('Enter token address for top-up (0x..)?');
      if (!rewardTokenAddr) return alert('Reward token is required.');

      const rewardWei = ethers.utils.parseUnits(topUpAmount, 18);
      const rewardTokenContract = new ethers.Contract(
        rewardTokenAddr,
        [
          {
            inputs: [
              { internalType: 'address', name: 'spender', type: 'address' },
              { internalType: 'uint256', name: 'amount', type: 'uint256' },
            ],
            name: 'approve',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
        ],
        signer
      );
      await (await rewardTokenContract.approve(selectedPool, rewardWei)).wait();

      const sr = new ethers.Contract(
        selectedPool,
        [
          {
            inputs: [{ internalType: 'uint256', name: 'reward', type: 'uint256' }],
            name: 'notifyRewardAmount',
            outputs: [],
            stateMutability: 'nonpayable',
            type: 'function',
          },
        ],
        signer
      );
      await (await sr.notifyRewardAmount(rewardWei)).wait();

      alert('Rewards topped up!');
      setShowRewardsModal(false);

      // Reload base chain read, then reload w/wallet if user is on base
      await loadPoolsForChain(BASE_CHAIN_ID, poolsMeta);
      const currentChain = await signer.getChainId();
      if (currentChain === BASE_CHAIN_ID) {
        await loadPoolsWithWallet(poolsMeta);
      }
    } catch (err) {
      console.error('Add rewards error:', err);
      alert(err.message);
    }
  }

  // ----------------------------------------------------------------
  // EDIT METADATA
  // ----------------------------------------------------------------
  function openEditModal(pool) {
    setEditPool(pool);
    setEditBanner(pool.bannerUrl || '');
    setEditLogo(pool.logoUrl || '');
    setEditName(pool.name || '');
    setShowEditModal(true);
  }

  async function handleSaveEdit() {
    if (!editPool) return;
    await updateServerMeta(editPool.id, editBanner, editLogo, editName);

    alert('Metadata updated for all devices!');
    setShowEditModal(false);
    setEditPool(null);

    // Reload base chain read, then wallet if on base
    await loadPoolsForChain(BASE_CHAIN_ID, poolsMeta);
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const chainId = await provider.getNetwork().then(n => n.chainId);
      if (chainId === BASE_CHAIN_ID) {
        await loadPoolsWithWallet(poolsMeta);
      }
    }
  }

  // ----------------------------------------------------------------
  // FILTERING
  // ----------------------------------------------------------------
  const livePools = pools.filter((p) => p.liveStatus);
  const endedPools = pools.filter((p) => !p.liveStatus);
  const displayedPools = showEnded ? endedPools : livePools;

  const searchTermLower = searchTerm.toLowerCase();
  const filteredPools = displayedPools.filter((p) => {
    if (!searchTermLower) return true;
    const addressMatch = p.id.toLowerCase().includes(searchTermLower);
    const symbolMatch = p.tokenSymbol.toLowerCase().includes(searchTermLower);
    const nameMatch = p.name.toLowerCase().includes(searchTermLower);
    return addressMatch || symbolMatch || nameMatch;
  });

  // ----------------------------------------------------------------
  // LAYOUT
  // ----------------------------------------------------------------
  const topBarStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '10px',
    marginBottom: '20px',
    flexWrap: 'wrap',
  };

  function handleSearchIconClick() {
    const term = prompt('Enter search term:');
    if (term !== null) {
      setSearchTerm(term);
    }
  }

  // If we're still "loadingPools" and have no data, show a fallback
  const showLoadingFallback = (loadingPools && pools.length === 0);

  return (
    <div style={outerContainerStyle(isMobile)}>
      <div style={cardStyle}>
        {/* Top Banner */}
        <div style={{ textAlign: 'center', marginBottom: '20px', padding: 0 }}>
          <img
            src={SteakandEarn}
            alt="Steak and Earn"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '12px',
              display: 'block',
            }}
          />
        </div>

        {/* TOP BAR: Search or icon, Toggle, and Create */}
        <div style={topBarStyle}>
          {/* LEFT: search & toggle */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {isMobile ? (
              <button
                onClick={handleSearchIconClick}
                style={{
                  background: 'none',
                  border: '1px solid rgba(255,255,255,0.2)',
                  borderRadius: '8px',
                  color: '#FFF',
                  fontSize: '13px',
                  lineHeight: '1',
                  height: '36px',
                  padding: '0 12px',
                  cursor: 'pointer',
                  display: 'inline-flex',
                  alignItems: 'center',
                }}
              >
                <FaSearch />
              </button>
            ) : (
              <input
                type="text"
                placeholder="Search by pool"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{
                  border: '1px solid rgba(255,255,255,0.2)',
                  borderRadius: '8px',
                  backgroundColor: '#1A1B1E',
                  color: '#FFF',
                  outline: 'none',
                  fontSize: '13px',
                  lineHeight: '1',
                  height: '36px',
                  padding: '0 12px',
                }}
              />
            )}

            {/* LIVE/ENDED TOGGLE */}
            <div style={toggleContainerStyle}>
              <button
                onClick={() => setShowEnded(false)}
                style={toggleButtonStyle(!showEnded)}
              >
                Live
              </button>
              <button
                onClick={() => setShowEnded(true)}
                style={toggleButtonStyle(showEnded)}
              >
                Ended
              </button>
            </div>
          </div>

          {/* RIGHT: Create Pool */}
          <button style={gradientButton(isMobile)} onClick={handleCreatePoolClick}>
            Create Pool
          </button>
        </div>

        {showLoadingFallback ? (
          // Loading fallback
          <div style={{ textAlign: 'center', padding: '40px 0', color: '#7B91B0' }}>
            Loading pools...
          </div>
        ) : (
          // Pools Grid
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: isMobile ? '1fr' : 'repeat(4, 1fr)',
              gap: '20px',
            }}
          >
            {filteredPools.map((pool) => (
              <div key={pool.id} style={{ position: 'relative' }}>
                <StakingPoolCard
                  pool={{ ...pool, availableRewards: pool.rewardBalance }}
                  onJoinPool={(pid) => navigate(`/staking/${pid}`)}
                  onManageRewards={(pid) => {
                    setSelectedPool(pid);
                    setTopUpAmount('');
                    setShowRewardsModal(true);
                  }}
                  onEdit={(p) => {
                    setEditPool(p);
                    setEditBanner(p.bannerUrl || '');
                    setEditLogo(p.logoUrl || '');
                    setEditName(p.name || '');
                    setShowEditModal(true);
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>

      {/* CREATE POOL MODAL */}
      {showCreateModal && (
        <div style={modalContainer}>
          <div style={modalCard}>
            <button
              onClick={() => setShowCreateModal(false)}
              style={{
                position: 'absolute',
                top: '16px',
                right: '16px',
                background: 'none',
                border: 'none',
                color: '#7B91B0',
                cursor: 'pointer',
              }}
            >
              X
            </button>
            <h2 style={{ fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '16px' }}>
              Create Single-Sided Pool
            </h2>
            <p style={{ color: '#7B91B0' }}>
              You’ll pay a creation fee in the chain’s native token.
            </p>

            <div style={{ marginTop: '20px', maxHeight: '60vh', overflowY: 'auto' }}>
              <label style={labelStyle}>Token (address):</label>
              <input
                name="tokenAddress"
                type="text"
                placeholder="0x..."
                value={createForm.tokenAddress}
                onChange={handleFormChange}
                style={inputStyle}
              />

              <label style={labelStyle}>APY (%):</label>
              <input
                name="apy"
                type="number"
                placeholder="e.g. 50"
                value={createForm.apy}
                onChange={handleFormChange}
                style={inputStyle}
              />

              <label style={labelStyle}>Duration (days):</label>
              <input
                name="durationDays"
                type="number"
                placeholder="30"
                value={createForm.durationDays}
                onChange={handleFormChange}
                style={inputStyle}
              />

              <label style={labelStyle}>Reward Amount (tokens):</label>
              <input
                name="rewardAmount"
                type="number"
                placeholder="100000"
                value={createForm.rewardAmount}
                onChange={handleFormChange}
                style={inputStyle}
              />

              <hr style={{ margin: '20px 0', borderColor: '#333' }} />

              <label style={labelStyle}>Banner URL:</label>
              <input
                name="bannerUrl"
                type="text"
                placeholder="https://example.com/banner.jpg"
                value={createForm.bannerUrl}
                onChange={handleFormChange}
                style={inputStyle}
              />

              <label style={labelStyle}>Logo URL:</label>
              <input
                name="logoUrl"
                type="text"
                placeholder="https://example.com/logo.png"
                value={createForm.logoUrl}
                onChange={handleFormChange}
                style={inputStyle}
              />
            </div>

            <button
              onClick={handleCreatePool}
              style={{
                ...gradientButton(isMobile),
                width: '100%',
                marginTop: '20px',
              }}
            >
              Create Pool &amp; Deposit Rewards
            </button>
          </div>
        </div>
      )}

      {/* MANAGE REWARDS MODAL */}
      {showRewardsModal && selectedPool && (
        <div style={modalContainer}>
          <div style={modalCard}>
            <button
              onClick={() => setShowRewardsModal(false)}
              style={{
                position: 'absolute',
                top: '16px',
                right: '16px',
                background: 'none',
                border: 'none',
                color: '#7B91B0',
                cursor: 'pointer',
              }}
            >
              X
            </button>
            <h2 style={{ fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '16px' }}>
              Top-Up Rewards
            </h2>
            <p style={{ color: '#7B91B0', marginBottom: '20px' }}>
              Pool: <b>{selectedPool}</b>
            </p>

            <label style={labelStyle}>Additional Reward Amount:</label>
            <input
              type="text"
              placeholder="10000"
              value={topUpAmount}
              onChange={(e) => setTopUpAmount(e.target.value)}
              style={inputStyle}
            />

            <button
              onClick={handleAddRewards}
              style={{
                ...gradientButton(isMobile),
                width: '100%',
                marginTop: '20px',
                background: 'linear-gradient(90deg, #22C55E 0%, #1cbc23 100%)',
              }}
            >
              Deposit Rewards
            </button>
          </div>
        </div>
      )}

      {/* EDIT METADATA MODAL */}
      {showEditModal && editPool && (
        <div style={modalContainer}>
          <div style={modalCard}>
            <button
              onClick={() => setShowEditModal(false)}
              style={{
                position: 'absolute',
                top: '16px',
                right: '16px',
                background: 'none',
                border: 'none',
                color: '#7B91B0',
                cursor: 'pointer',
              }}
            >
              X
            </button>
            <h2 style={{ fontSize: '1.3rem', fontWeight: 'bold', marginBottom: '16px' }}>
              Edit Metadata
            </h2>
            <p style={{ color: '#7B91B0', marginBottom: '20px' }}>
              Pool: <b>{editPool.id}</b>
            </p>

            <label style={labelStyle}>Pool Name:</label>
            <input
              type="text"
              placeholder="Enter custom pool name"
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
              style={inputStyle}
            />

            <label style={labelStyle}>Banner URL:</label>
            <input
              type="text"
              placeholder="https://..."
              value={editBanner}
              onChange={(e) => setEditBanner(e.target.value)}
              style={inputStyle}
            />

            <label style={labelStyle}>Logo URL:</label>
            <input
              type="text"
              placeholder="https://..."
              value={editLogo}
              onChange={(e) => setEditLogo(e.target.value)}
              style={inputStyle}
            />

            <button
              onClick={handleSaveEdit}
              style={{
                ...gradientButton(isMobile),
                width: '100%',
                marginTop: '20px',
                background: 'linear-gradient(90deg, #8454EB 0%, #3461FF 100%)',
              }}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
