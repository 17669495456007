import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import HashLoader from "react-spinners/HashLoader";
import { usePoolListStats } from "./helper/useStats";
import ProjectCard from "../../../component/ProjectCard";
import AnimatedBanner from "./AnimatedBanner";
import NewBanner from "./NewBanner";
import BondingCard from "../../../component/BondingCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NoticeModal from "../../../component/NoticeModal";
import Chat from '../../../component/Chat';
import NewChat from '../../../component/NewChat';
import { useLocation } from "react-router-dom";

import styled from 'styled-components';

// Additional imports
import useMediaQuery from '@material-ui/core/useMediaQuery';

import AirdropImage from '../../../images/AirdropBanner.png';
import FairLaunchImage from '../../../images/FairLaunch.png';
import AdvancedTokenImage from '../../../images/AdvancedToken.png';
import StakingImage from '../../../images/Staking.png';
import NowLiveImage from '../../../images/NowLive.png';
import NetworkImage from '../../../images/Network.png';
import DevImage from '../../../images/Developer.png';
import AdvertiseImage from '../../../images/Advertise.png';
import HoldDefi from '../../../images/HoldDefi.png';
import fees from '../../../images/fees.png';

//import DefiTrackerLogo from '../../../images/DefiTracker.png'; 








import UniswapImage from '../../../images/Banner1.png';
import BetasImage from '../../../images/Banner3.png';
import PumpImage from '../../../images/Pump.png';
import CreateTokenIcon from '../../../images/CreateCoinIcon.svg';
import BondingCurveIcon from '../../../images/PumpSaleIcon.svg';
import HomeIcon from "../../../images/HomeIconBottom.svg";
import FairLaunchIcon from "../../../images/FairLaunchIconBottom.svg";
import DiamondIcon from "../../../images/DiamondIconBottom.svg";
import CenterShadow from "../../../images/CenterShadowBehindHomeIcon.svg";

import TopPumpSellers from '../../../component/TopPumpSellers';


// Define formatEndDate function here
const formatEndDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString('en-GB', {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });
};

const ChatSidebarWrapper = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  color: white;
  position: fixed;
  top: 70px;
  right: 0;
  width: 21%;
  height: calc(100% - 20px);
  background: #161A42;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding-bottom: 0px;
  border-radius: 15px 15px 15px 15px;
  margin-right: 20px;
  overflow: hidden;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  @media (max-width: 1024px) { /* Hide chat on mobile */
    display: none;
  }
`;

const BottomNavigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0px;
  width: 100%;
  background: #161A42;
  padding: 0;
  z-index: 10000;  // Ensure this is above other elements
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 60px; /* Match the height with the shadow behind the home icon */
  margin-top: 10px; // Add margin above the navigation bar
  @media (min-width: 1025px) { /* Hide bottom navigation on desktop */
    display: none;
  }
`;



const CenterIconContainer = styled.div`
  position: relative;
  width: 60px;
  height: 60px;
  background: #232966;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const MobileContainer = styled.div`
  width: 100%;
  overflow-x: hidden; /* Ensure no horizontal scrolling */
  padding: 0;
  margin: 0;
  padding-bottom: 60px; /* Add padding to ensure content doesn't overlap with the navigation bar */
`;

const ProjectList = () => {
  const [showNotice, setShowNotice] = useState(true);
  const [updater, setUpdater] = useState({
    page: 0,
    pageSize: 30,
    loading: true,
  });
  const [degenMode, setDegenMode] = useState(false);
  const [isBonding, setIsBonding] = useState(false); // Changed to false to Fair Launch View for now
  const [fullView, setFullView] = useState(false);
  const statsAll = usePoolListStats(updater);
  const stats = statsAll[0];
  const bondingStats = statsAll[1];
  const [shuffledList, setShuffledList] = useState([]);
  const [sortedList, setSortedList] = useState([]);
  const [bondingList, setBondingList] = useState(bondingStats.poolList);
  const [liveSales, setLiveSales] = useState([]);
  const [upcomingSales, setUpcomingSales] = useState([]);
  const [zeroRaisedSales, setZeroRaisedSales] = useState([]);
  const [endedSales, setEndedSales] = useState([]);
  const navigate = useNavigate();

  const [showBanners, setShowBanners] = useState(true); 


  const isMobile = useMediaQuery('(max-width:768px)');

  const handleNoticeClose = () => {
    setShowNotice(false);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.state?.selectType === 'bonding') {
      setIsBonding(false);
      setDegenMode(false);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchLiveData = () => {
      if (!stats.loading && stats.poolList.length > 0) {
        const filtered = stats.poolList.filter(
          (rowdata) => rowdata.poolType !== "1" && rowdata.name !== "JuJu"
        );
        const sorted = sortSales(filtered);
        setSortedList(sorted);
        const activeSales = sorted.filter(isSaleActiveAndMinSoftcap);
        setLiveSales(activeSales);

        const upcoming = sorted.filter(isUpcomingSale);
        setUpcomingSales(upcoming);

        const zeroRaised = sorted.filter(isZeroRaised);
        setZeroRaisedSales(zeroRaised);

        const ended = sorted.filter(isEndedSale);
        setEndedSales(ended);

        if (degenMode) {
          const degenList = activeSales
            .filter(hasRaisedAmount)
            .filter(isNotCanceled)
            .filter(isNotCompleted);
          setShuffledList(degenList);

          const interval = setInterval(() => {
            setShuffledList((prevList) => shuffleArray(degenList));
          }, 20000);

          return () => clearInterval(interval);
        }
      } else {
        setSortedList([]);
      }
      if (!bondingStats.loading && bondingStats.poolList.length > 0) {
        // Sort the bonding list by market cap before setting it
        const sortedBondingList = sortByMarketCap(bondingStats.poolList);
        setBondingList(sortedBondingList);
      } else {
        setBondingList([]);
      }
      
    };

    fetchLiveData();
    const intervalId = setInterval(fetchLiveData, 300000);

    return () => clearInterval(intervalId);
  }, [stats.loading, stats.poolList, degenMode, isBonding]);

  const isSaleActiveAndMinSoftcap = (rowdata) => {
    const currentTime = Math.floor(new Date().getTime() / 1000.0);
    const isActive = parseInt(rowdata.startTime) < currentTime && parseInt(rowdata.endTime) > currentTime;
    return isActive;
  };

  const hasRaisedAmount = (rowdata) => parseFloat(rowdata.totalRaised) > 0;

  const isNotCanceled = (rowdata) => rowdata.poolState !== "2";

  const isNotCompleted = (rowdata) => rowdata.poolState !== "1";

  const isUpcomingSale = (rowdata) => {
    const currentTime = Math.floor(new Date().getTime() / 1000.0);
    return parseInt(rowdata.startTime) > currentTime && rowdata.poolState !== "2" && rowdata.poolState !== "1";
  };

  const isZeroRaised = (rowdata) => parseFloat(rowdata.totalRaised) === 0 && rowdata.poolState === "0";

  const isEndedSale = (rowdata) => {
    const currentTime = Math.floor(new Date().getTime() / 1000.0);
    return parseInt(rowdata.endTime) < currentTime && rowdata.poolState === "1";
  };

  const sortByMarketCap = (list) => {
    return [...list].sort((a, b) => {
      // Sort by currentMK in descending order (highest first)
      return parseFloat(b.currentMK) - parseFloat(a.currentMK);
    });
  };

  const sortSales = (sales) => {
    return sales.sort((a, b) => {
      const statusOrder = ["active", "upcoming", "completed", "ended", "canceled"];
      const getStatusOrder = (sale) => {
        if (sale.poolState === "1") return "completed";
        if (sale.poolState === "2") return "canceled";
        if (parseInt(sale.endTime) < Math.floor(new Date().getTime() / 1000.0)) return "ended";
        if (parseInt(sale.startTime) > Math.floor(new Date().getTime() / 1000.0)) return "upcoming";
        return "active";
      };
      const orderA = statusOrder.indexOf(getStatusOrder(a));
      const orderB = statusOrder.indexOf(getStatusOrder(b));
      if (orderA !== orderB) return orderA - orderB;
      return parseFloat(b.totalRaised) - parseFloat(a.totalRaised);
    });
  };

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const handleButtonClick = (group, mode) => {
    if (group === "mode") {
      setDegenMode(mode === "degen");
    } else if (group === "type") {
      setIsBonding(mode === "bonding");
      if (mode === "bonding") {
        setDegenMode(false);
      }
    } else if (group === "view") {
      setFullView(mode === "fullView");
    }
  };

  const handleHomeClick = () => {
    setDegenMode(true); // Reset to Degen Mode
    setIsBonding(false); // Reset to Fair Launch (not Bonding)
    setFullView(false); // Reset to Home View (not Full View)
  
    navigate("/"); // Navigate to the homepage
  };

  const handleFavoritesClick = () => {
    navigate("/favorites");
  };

  const handleCreateTokenClick = () => {
    navigate("/token/Standard");
  };

  const handleBondingCurveClick = () => {
    navigate("/bonding-token-sale");
  };

  const handleFairLaunchClick = () => {
    navigate("/fairlaunch");
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };

  return (
    <MobileContainer style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%', height: '100vh' }}>
      <div className="container project-list-container" style={isMobile ? containerStyleMobile : fullView ? containerStyleFull : containerStyle}>
        {showNotice && <NoticeModal onClose={handleNoticeClose} />}
        
        {/* Main Content */}
        {!fullView && (
          <>

                {/* Added the TopPumpSellers component here */}
                <div style={{ ...sectionSpacing, paddingBottom: '10px' }}>
      <TopPumpSellers bondingStats={bondingStats} />
    </div>


{/* Carousel */}
<div className="custom-carousel-container" style={{ ...sectionSpacing, paddingTop: '0px', paddingBottom: '0px' }}>
  <Slider {...settings} className="carousel-banner">
    <Link to="/token/Standard">
      <div style={bannerStyles}>
        <img src={UniswapImage} alt="Uniswap" style={imageStyles} />
      </div>
    </Link>
    <Link to="/token/Advanced">
      <div style={bannerStyles}>
        <img src={AdvancedTokenImage} alt="Advanced" style={imageStyles} />
      </div>
    </Link>
    <Link
      to="https://www.geckoterminal.com/base/pools/0x3FABa4AdB18a363CE356C0fD32919E642570A42D"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div style={bannerStyles}>
        <img src={NowLiveImage} alt="Launch" style={imageStyles} />
      </div>
    </Link>
    <Link to="/staking">
      <div style={bannerStyles}>
        <img src={StakingImage} alt="Staking" style={imageStyles} />
      </div>
    </Link>
    <Link
      to="https://app.1inch.io/#/8453/simple/swap/8453:WETH/8453:DEFI"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div style={bannerStyles}>
        <img src={HoldDefi} alt="Hold-Defi" style={imageStyles} />
      </div>
    </Link>
    <Link to="/fairlaunch">
      <div style={bannerStyles}>
        <img src={FairLaunchImage} alt="FairLaunch" style={imageStyles} />
      </div>
    </Link>
    <Link to="/bonding-token-sale">
      <div style={bannerStyles}>
        <img src={PumpImage} alt="Pump" style={imageStyles} />
      </div>
    </Link>
    <Link
      to="https://app.1inch.io/#/8453/simple/swap/8453:WETH/8453:DEFI"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div style={bannerStyles}>
        <img src={fees} alt="FeeGenerator" style={imageStyles} />
      </div>
    </Link>
    <Link to="/airdrop-tokens">
      <div style={bannerStyles}>
        <img src={AirdropImage} alt="Airdrop" style={imageStyles} />
      </div>
    </Link>

    <a href="mailto:info@defilaunch.app">
      <div style={bannerStyles}>
        <img src={NetworkImage} alt="Network" style={imageStyles} />
      </div>
    </a>
    <Link to="/">
      <div style={bannerStyles}>
        <img src={DevImage} alt="Developer" style={imageStyles} />
      </div>
    </Link>
    <a href="mailto:info@defilaunch.app">
      <div style={bannerStyles}>
        <img src={AdvertiseImage} alt="Advertise" style={imageStyles} />
      </div>
    </a>
  </Slider>
</div>
            {showBanners && (
  <div className="banners-container" style={{ ...sectionSpacing, paddingBottom: '10px' }}>
    <AnimatedBanner liveSales={liveSales} />
    <NewBanner 
      upcomingSales={upcomingSales} 
      zeroRaisedSales={zeroRaisedSales} 
      endedSales={endedSales} 
    />
  </div>
)}


            {degenMode && !fullView && (
              <div className="banners-container" style={{ ...sectionSpacing, paddingBottom: '10px' }}>
                <AnimatedBanner liveSales={liveSales} />
                <NewBanner upcomingSales={upcomingSales} zeroRaisedSales={zeroRaisedSales} endedSales={endedSales} />
              </div>
            )}
          </>
        )}
        {!fullView && (
         <>
            <div className="mobile-header-wrapper">
              <div className="header-content mobile-header">
                <span style={mobileHeaderTextStyles} className="header-text">
                  <span style={mobileFairLaunchTextStyles}>
                    {isBonding ? "⭐️ Pump Sales " : "🔥 Fair Launch "}
                  </span>
                  <span style={mobileDividerTextStyles}> &nbsp;&gt;&nbsp; </span>
                  <span style={mobileLiveSalesTextStyles} className="live-sales-text">
                    Live
                  </span>
                </span>
                {!isBonding && (
                  <Link to="/token/Standard" style={mobileCreateButtonStyles} className="create-button">
                    CREATE MEME
                  </Link>
                )}
                {isBonding && (
                  <Link to="/bonding-token-sale" style={mobileCreateButtonStyles} className="create-button">
                    CREATE SALE
                  </Link>
                )}
              </div>
            </div>

{/* Mobile Header Buttons */}
<div className="header-buttons-container mobile-header" style={mobileButtonWrapperStyles}>
  <button
    className={`explore-btn degen-btn ${degenMode ? "active" : ""}`}
    style={{
      ...mobileButtonStyles,
      borderTopRightRadius: '0px', // Adjust the border radius
      borderBottomRightRadius: '0px', // Adjust the border radius
      borderTopLeftRadius: '6px', // Adjust the border radius
      borderBottomLeftRadius: '6px', // Adjust the border radius
      marginRight: '-6px', // Adjust the gap
      background: degenMode ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
      color: degenMode ? '#fff' : '#7B91B0',
    }}
    onClick={() => handleButtonClick("mode", "degen")}
  >
    Degen
  </button>
  <button
    className={`explore-btn basic-btn ${!degenMode ? "active" : ""}`}
    style={{
      ...mobileButtonStyles,
      borderTopRightRadius: '6px', // Adjust the border radius
      borderBottomRightRadius: '6px', // Adjust the border radius
      borderTopLeftRadius: '0px', // Adjust the border radius
      borderBottomLeftRadius: '0px', // Adjust the border radius
      marginRight: '4px', // Adjust the gap
      background: !degenMode ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
      color: !degenMode ? '#fff' : '#7B91B0',
    }}
    onClick={() => handleButtonClick("mode", "basic")}
  >
    Basic
  </button>
  <button
    className={`explore-btn fairlaunch-btn ${!isBonding ? "active" : ""}`}
    style={{
      ...mobileButtonStyles,
      borderTopRightRadius: '0px', // Adjust the border radius
      borderBottomRightRadius: '0px', // Adjust the border radius
      borderTopLeftRadius: '6px', // Adjust the border radius
      borderBottomLeftRadius: '6px', // Adjust the border radius
      marginRight: '-6px', // Adjust the gap
      background: !isBonding ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
      color: !isBonding ? '#fff' : '#7B91B0',
    }}
    onClick={() => handleButtonClick("type", "fairLaunch")}
  >
    Fair
  </button>
  <button
    className={`explore-btn bonding-btn ${isBonding ? "active" : ""}`}
    style={{
      ...mobileButtonStyles,
      borderTopRightRadius: '6px', // Adjust the border radius
      borderBottomRightRadius: '6px', // Adjust the border radius
      borderTopLeftRadius: '0px', // Adjust the border radius
      borderBottomLeftRadius: '0px', // Adjust the border radius
      marginRight: '4px', // Adjust the gap
      background: isBonding ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
      color: isBonding ? '#fff' : '#7B91B0',
    }}
    onClick={() => handleButtonClick("type", "bonding")}
  >
    Pump
  </button>
  <button
    className={`explore-btn home-btn ${!fullView ? "active" : ""}`}
    style={{
      ...mobileButtonStyles,
      borderTopRightRadius: '0px', // Adjust the border radius
      borderBottomRightRadius: '0px', // Adjust the border radius
      borderTopLeftRadius: '6px', // Adjust the border radius
      borderBottomLeftRadius: '6px', // Adjust the border radius
      marginRight: '-6px', // Adjust the gap
      background: !fullView ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
      color: !fullView ? '#fff' : '#7B91B0',
    }}
    onClick={() => handleButtonClick("view", "home")}
  >
    Home
  </button>
  <button
    className={ `explore-btn fullview-btn ${fullView ? "active" : ""}`}
    style={{
      ...mobileButtonStyles,
      borderTopRightRadius: '6px', // Adjust the border radius
      borderBottomRightRadius: '6px', // Adjust the border radius
      borderTopLeftRadius: '0px', // Adjust the border radius
      borderBottomLeftRadius: '0px', // Adjust the border radius
      background: fullView ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
      color: fullView ? '#fff' : '#7B91B0',
    }}
    onClick={() => handleButtonClick("view", "fullView")}
  >
    Full
  </button>
</div>
            
          </>
        )}

        <div className="header-buttons-container desktop-header" style={headerButtonsContainerStyles}>
          <div className="header-content desktop-header">
            <span style={headerTextStyles} className="header-text">
              <span style={fairLaunchTextStyles}>{isBonding ? "⭐️ Pump Sales" : "🔥 Fair Launch"}</span>
              <span style={dividerTextStyles}> &nbsp;&gt;&nbsp; </span>
              <span style={liveSalesTextStyles} className="live-sales-text"> Live</span>
            </span>
            {isBonding && (
              <>
                <Link to="/bonding-token-sale" style={{ ...createButtonStyles, background: '#3461FF' }} className="create-button">CREATE SALE</Link>
              </>
            )}
            {!isBonding && (
              <>
                <Link to="/token/Standard" style={{ ...createButtonStyles, background: '#3461FF' }} className="create-button">CREATE MEME</Link>
                <Link to="/fairlaunch" style={{ ...createButtonStyles, background: '#3461FF' }} className="create-launch-button">LAUNCH IT</Link>
              </>
            )}
          </div>
          <div className="buttons-wrapper" style={buttonsWrapperStyles}>
            <div style={buttonContainerStyles}>
              <button
                className={`explore-btn degen-btn ${degenMode ? "active" : ""}`}
                style={{
                  ...buttonStyles,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  background: degenMode ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
                  color: degenMode ? '#fff' : '#7B91B0',
                }}
                onClick={() => handleButtonClick("mode", "degen")}
              >
                Degen Mode
              </button>
              <button
                className={`explore-btn basic-btn ${!degenMode ? "active" : ""}`}
                style={{
                  ...buttonStyles,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  background: !degenMode ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
                  color: !degenMode ? '#fff' : '#7B91B0',
                }}
                onClick={() => handleButtonClick("mode", "basic")}
              >
                Basic Mode
              </button>
            </div>
            <div style={buttonContainerStyles}>
              <button
                className={`explore-btn fairlaunch-btn ${!isBonding ? "active" : ""}`}
                style={{
                  ...buttonStyles,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  background: !isBonding ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
                  color: !isBonding ? '#fff' : '#7B91B0',
                }}
                onClick={() => handleButtonClick("type", "fairLaunch")}
              >
                Fair Launch
              </button>
              <button
                className={`explore-btn bonding-btn ${isBonding ? "active" : ""}`}
                style={{
                  ...buttonStyles,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  background: isBonding ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
                  color: isBonding ? '#fff' : '#7B91B0',
                }}
                onClick={() => handleButtonClick("type", "bonding")}
              >
                Pump Sales
              </button>
            </div>
            <div style={buttonContainerStyles}>
              <button
                className={`explore-btn home-btn ${!fullView ? "active" : ""}`}
                style={{
                  ...buttonStyles,
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  paddingLeft: '0px',
                  paddingRight: '0px',
                  background: !fullView ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
                  color: !fullView ? '#fff' : '#7B91B0',
                }}
                onClick={() => handleButtonClick("view", "home")}
              >
                Home
              </button>
              <button
                className={`explore-btn fullview-btn ${fullView ? "active" : ""}`}
                style={{
                  ...buttonStyles,
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  paddingLeft: '0px',
                  paddingRight: '0px',
                  background: fullView ? 'linear-gradient(180deg, #3461FF 0%, #8454EB 100%)' : '#161A42',
                  color: fullView ? '#fff' : '#7B91B0',
                }}
                onClick={() => handleButtonClick("view", "fullView")}
              >
                Full View
              </button>
            </div>
          </div>
        </div>

        <div className="content-wrapper" style={contentWrapperStyle}>
          <div className="row project-cards-container" style={{ ...projectCardsContainerStyle, flex: 1 }}>
            {stats.loading ? (
              <div className="col-md-12">
                <HashLoader
                  size="100"
                  color="#fff"
                  cssOverride={{
                    left: "50%",
                    textAlign: "center",
                    top: "50%",
                  }}
                />
              </div>
            ) : degenMode ? (
              !isBonding ? (
                shuffledList.length > 0 ? (
                  shuffledList.map((rowdata, index) => (
                    <ProjectCard
                      chainId={stats.chainId}
                      rowdata={{ ...rowdata, formattedEndDate: formatEndDate(rowdata.endTime) }}
                      index={index}
                      key={rowdata.poolAddress + rowdata.token}
                      hideTotalRaised={true}
                      cardStyle={projectCardStyle}
                      className={
                        isMobile
                          ? "col-12 item explore-item project-card-item" // Remove mb-2 mt-2 if you want no margin on mobile
                          : "col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 item explore-item mb-0 mt-0 project-card-item"
                      }
                    />
                  ))
                ) : (
                  <div className="col-12 item explore-item mt-5">
                    <p className="text-center">Empty No Records Found</p>
                  </div>
                )
              ) : (
                bondingList.length > 0 ? (
                  bondingList.map((rowdata, index) => (
                    <BondingCard
                      chainId={stats.chainId}
                      rowdata={rowdata}
                      index={index}
                      key={rowdata.poolAddress + rowdata.token}
                      hideTotalRaised={true}
                      cardStyle={projectCardStyle}
                      className={
                        isMobile
                          ? "col-12 item explore-item mb-2 mt-2 project-card-item"
                          : "col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 item explore-item mb-2 mt-2 project-card-item"
                      }
                    />
                  ))
                ) : (
                  <div className="col-12 item explore-item mt-5">
                    <p className="text-center">Empty No Records Found</p>
                  </div>
                )
              )
            ) : isBonding ? (
              bondingList.length > 0 ? (
                bondingList.map((rowdata, index) => (
                  <BondingCard
                    chainId={stats.chainId}
                    rowdata={rowdata}
                    index={index}
                    key={rowdata.poolAddress + rowdata.token}
                    hideTotalRaised={true}
                    cardStyle={projectCardStyle}
                    className={
                      isMobile
                        ? "col-12 item explore-item mb-2 mt-2 project-card-item"
                        : "col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 item explore-item mb-2 mt-2 project-card-item"
                    }
                  />
                ))
              ) : (
                <div className="col-12 item explore-item mt-5">
                  <p className="text-center">Empty No Records Found</p>
                </div>
              )
            ) : sortedList.length > 0 ? (
              sortedList.map((rowdata, index) => (
                <ProjectCard
                  chainId={stats.chainId}
                  rowdata={{ ...rowdata, formattedEndDate: formatEndDate(rowdata.endTime) }}
                  index={index}
                  key={rowdata.poolAddress + rowdata.token}
                  hideTotalRaised={true}
                  cardStyle={projectCardStyle}
                  className={
                    isMobile
                    ? "col-12 item explore-item project-card-item" // Remove mb-2 mt-2 if you want no margin on mobile
                    : "col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 item explore-item mb-0 mt-0 project-card-item"
                  }
                />
              ))
            ) : (
              <div className="col-12 item explore-item mt-5">
                <p className="text-center">Empty No Records Found</p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Chat Sidebar */}
      {!fullView && !isMobile && (
        <div className="desktop-header" style={{ width: '25%' }}>
          <ChatSidebarWrapper>
            <NewChat />
          </ChatSidebarWrapper>
        </div>
      )}

      {/* Mobile Bottom Navigation Bar */}
      <BottomNavigation>
        <img src={CreateTokenIcon} alt="Create Token" className="bottom-navigation-icon" onClick={handleCreateTokenClick} />
        <img src={BondingCurveIcon} alt="Bonding Curve" className="bottom-navigation-icon" onClick={handleBondingCurveClick} />
        <div className="center-icon-container" onClick={handleHomeClick}>
        <img src={CenterShadow} alt="Center Shadow" className="center-shadow" style={{ position: 'absolute', top: 0, left: '50%', transform: 'translateX(-50%)', height: '150px', width: '80px', objectFit: 'cover' }} />
          <img src={HomeIcon} alt="Home" className="bottom-navigation-icon center-icon" style={{ position: 'relative', zIndex: 1, left: '3.5px', top: '2px' }} />
        </div>
        <img src={FairLaunchIcon} alt="Fair Launch" className="bottom-navigation-icon" onClick={handleFairLaunchClick} />
        <img src={DiamondIcon} alt="Diamond" className="bottom-navigation-icon" onClick={handleFavoritesClick} />
      </BottomNavigation>
    </MobileContainer>
  );
};

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '75%',
  padding: '0',
  margin: '0',
  marginLeft: '0',
  transform: 'scale(1)',
  transformOrigin: 'top left',
  overflowY: 'auto',  // Add this
  height: '100vh',    // Add this
  '@media (max-width: 768px)': {
    width: '100%',
  },
};

const containerStyleFull = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '0',
  margin: '0',
  marginLeft: '0',
  transform: 'scale(1)',
  transformOrigin: 'top left',
};

const containerStyleMobile = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '0',
  margin: '0',
  marginLeft: '0',
  transform: 'scale(1)',
  transformOrigin: 'top left',
};

const sectionSpacing = {
  padding: '9px 0',
};

const contentWrapperStyle = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  height: 'auto',     // Change from 100vh to auto
  padding: '0 10px',  // Optional padding
};

const projectCardsContainerStyle = {
  marginTop: '0px',
  paddingTop: '0px',
  width: '100%',
  '@media (max-width: 768px)': {
    width: '100%',
    padding: '0',
  }
};

const bannerStyles = {
  padding: '0 5px',
};

const imageStyles = {
  width: '100%',
  height: 'auto',
  borderRadius: '10px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
};

const headerButtonsContainerStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 0',
  marginBottom: '0px',
};

const headerTextStyles = {
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Quicksand, sans-serif',
  lineHeight: '20px',
};

const fairLaunchTextStyles = {
  color: '#6EE7B7',
};

const dividerTextStyles = {
  color: '#7B91B0',
};

const liveSalesTextStyles = {
  color: '#6EE7B7',
};

const createButtonStyles = {
  marginLeft: '15px',
  padding: '6px 20px',
  fontSize: '12px',
  fontWeight: '500',
  color: '#fff',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  height: '30px',
  background: '#3461FF !important',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  backgroundImage: 'none !important',
  WebkitBackgroundImage: 'none !important', // For Safari
  MozBackgroundImage: 'none !important', // For Firefox
  msBackgroundImage: 'none !important', // For IE
  boxShadow: 'none',
  transition: 'opacity 0.2s',
};

const buttonsWrapperStyles = {
  display: 'flex',
  gap: '8px',
};

const buttonContainerStyles = {
  display: 'flex',
  gap: '0',
};

const buttonStyles = {
  padding: '4px 4px',
  fontSize: '10px',
  fontWeight: 'normal',
  color: '#7B91B0',
  border: '1.42px solid #3461FF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '35px',
  borderRadius: '8px',
  margin: 0,
  minWidth: '80px',
  textDecoration: 'none',
};

const projectCardStyle = {
  margin: '10px',
  padding: '0',
  width: '100%', // Ensure full width for each card on mobile
  '@media (max-width: 768px)': {
    margin: '0', // Remove margins that could prevent full width
    padding: '0', // Remove padding that could affect the width
    width: '100%', // Ensure it fills the width
  },
};

const mobileHeaderTextStyles = {
  display: 'flex',
  alignItems: 'center',
  fontFamily: 'Quicksand, sans-serif',
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '16px',
  margin: '0',
};

const mobileFairLaunchTextStyles = {
  color: '#6EE7B7',
  fontSize: '15px',
  marginTop: '5px',
};

const mobileDividerTextStyles = {
  color: '#7B91B0',
  fontSize: '14px',
  marginTop: '3px',

};

const mobileLiveSalesTextStyles = {
  color: '#6EE7B7',
  fontSize: '15px',
  marginTop: '5px',

};

const mobileCreateButtonStyles = {
  marginLeft: '5px',
  marginRight: '0px',
  padding: '6px 20px',
  fontSize: '12px',
  fontWeight: '500',
  color: '#fff',
  border: 'none',
  borderRadius: '8px',
  cursor: 'pointer',
  height: '30px',
  background: '#3461FF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none'
};

const mobileButtonWrapperStyles = {
  display: 'flex',
  justifyContent: 'space-between', // Ensures buttons are spaced evenly
  alignItems: 'center',
  width: '100%', // Make sure the wrapper takes up the full width
  padding: '0 10px', // Adding slight padding to the left and right for alignment
  boxSizing: 'border-box', // Ensure padding doesn't cause overflow
  gap: '6px', // Adjust this value to increase or decrease the gap between buttons
  marginTop: '12px',
  marginBottom: '10px',
};

const mobileButtonStyles = {
  padding: '4px 6px',
  fontSize: '9px',
  flex: 1,
  whiteSpace: 'nowrap',
  textAlign: 'center',
  boxSizing: 'border-box',
  border: '1.42px solid #3461FF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '25px',
  margin: 0,
  minWidth: '40px',
};

export default ProjectList;